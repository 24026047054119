import { useEffect, useState, useContext, useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Alert from '../../components/Alert';
import { OrderContext, STEPS } from '../../store/OrderProvider';
import { RepositoryContext } from '../../store/RepositoryProvider';
import { DynamicPriceType } from '../../models/types';
import { UserContext } from '../../store/UserProvider';
import API from '../../server/api';
import ReportParams from '../../models/report/ReportParams';
import User from '../../models/user/User';
import Field from '../../components/Field';
import log from '../../utils/logger';
import Input from '../../components/Input';
import SelectDate, { DAY_PICKER_MODE } from '../../components/SelectDate';
import Select from '../../components/Select';
import AddIcon from '../../components/icons/Add';
import DeleteIcon from '../../components/icons/Delete';
import DateFormat from '../../components/DateFormat';
import ErrorHint from '../../components/ErrorHint';
import Price, { CURRENCY } from '../../components/Price';
import Phone from '../../components/Phone';

const phoneRegex = /\+\d{11}/;

const StepClientInfo = () => {
  const {t} = useTranslation(['common', 'order', 'excursion']);

  const orderInfo = useContext(OrderContext);
  const userInfo = useContext(UserContext);
  const repository = useContext(RepositoryContext);

  const [discounts, setDiscounts] = useState([]);
  const [errors, setErrors] = useState([]);
  const [phones, setPhones] = useState([]);

  const [clientPhone, setClientPhone] = orderInfo.useSession('phone');
  const [clientContactPhones, setClientContactPhones] = orderInfo.useSession('contact_phones');
  const [clientUser, setClientUser] = orderInfo.useSession('user');
  const [clientName, setClientName] = orderInfo.useSession('name');
  const [clientFirstName, setClientFirstName] = orderInfo.useSession('first_name');
  const [clientLastName, setClientLastName] = orderInfo.useSession('last_name');
  const [clientMiddleName, setClientMiddleName] = orderInfo.useSession('middle_name');
  const [clientBirthday, setClientBirthday] = orderInfo.useSession('birthday');
  const [clientDiscount, setClientDiscount] = orderInfo.useSession('discount');
  const [clientDiscountValue, setClientDiscountValue] = orderInfo.useSession('discount_value');
  const [clientDiscountType, setClientDiscountType] = orderInfo.useSession('discount_type');
  const [clientDiscountAppliesTo, setClientDiscountAppliesTo] = orderInfo.useSession('discount_appliesTo');
  const [clientDiscountForGroups, setClientDiscountForGroups] = orderInfo.useSession('discount_forGroups');

  const [commonExcursion] = orderInfo.useSession('excursion');
  const [commonSubdivision] = orderInfo.useSession('subdivision');
  const [commonSchedule] = orderInfo.useSession('schedule');
  const [excursion] = orderInfo.useState('excursion');
  const [schedule] = orderInfo.useState('schedule');


  const handleAddPhone = useCallback(() => {
    setClientContactPhones([ ...clientContactPhones, '' ]);
    setPhones([ ...phones, { value: '', error: null } ]);
  }, [clientContactPhones]);


  const handleDeletePhone = useCallback((idx) => {
    clientContactPhones.splice(idx, 1);
    setClientContactPhones([ ...clientContactPhones ]);
  }, [clientContactPhones]);


  const loadDiscounts = () => {
    if (schedule && excursion && !excursion.noDiscount) {
      repository.list(API.discount.autocompleteList, {}).then((rows) => {
        let now = new Date();
        setDiscounts(rows.filter((discount) => {
          return discount.meta.isApplyTo({ date: now, user: userInfo.getUserInfo().user, excursion, schedule });
        }).map((discount) => discount.meta));
      }).catch((e) => {
        console.error(e);
        setDiscounts([]);
      });
    } else {
      setDiscounts([]);
    }
  };


  const loadUserByPhone = async (phone) => {
    setClientPhone(phone);

    if (phoneRegex.test(phone)) {
      repository.report(API.user.users, new ReportParams({ filters: { 'phone': phone } }), User).then((report) => {
        if (report.rows.length === 1) {
          setClientUser(report.rows[0].id);
          setClientFirstName(report.rows[0].profile.firstName);
          setClientLastName(report.rows[0].profile.lastName);
          setClientMiddleName(report.rows[0].profile.middleName);
          setClientName(report.rows[0].profile.fullName);
        } else {
          setClientUser(null);
          setClientFirstName('');
          setClientLastName('');
          setClientMiddleName('');
          setClientName('');
        }
        setClientPhone(phone);
      }).catch((e) => {
        log.error('loadUserByPhone', e);
      });
    }
  };


  const handlePhoneInput = useCallback((val) => {
    loadUserByPhone(val);
  }, []);


  const handleContactPhoneInput = useCallback((val, i) => {
    // let phones = [...clientContactPhones];
    phones[i] = { value: val, error: null };
    // setClientContactPhones([ ...phones ]);
    let addPhoneValue = phones.filter((phone) => phone && !phone.error).map((phone) => phone.value);
    setClientContactPhones(addPhoneValue);
    setPhones([...phones]);
  }, [clientContactPhones]);


  const handleFirstNameInput = useCallback((val) => {
    setClientFirstName(val);
  }, [clientFirstName]);


  const handleLastNameInput = useCallback((val) => {
    setClientLastName(val);
  }, [clientLastName]);


  const handleMiddleNameInput = useCallback((val) => {
    setClientMiddleName(val);
  }, [clientMiddleName]);


  const handleBirthdayInput = useCallback((val) => {
    setClientBirthday(DateFormat.Request({ date: val }));
  }, [clientBirthday]);


  const handleDiscountSelect = useCallback((val) => {
    let discount = discounts.find((discount) => discount.id === val);

    if (discount) {
      setClientDiscount(discount.id);
      setClientDiscountType(discount.discount.type);
      setClientDiscountValue(discount.discount.value);
      setClientDiscountAppliesTo(discount.appliesTo);
      setClientDiscountForGroups(discount.forGroups);
    } else {
      setClientDiscount('');
      setClientDiscountType('');
      setClientDiscountValue('');
      setClientDiscountAppliesTo('');
      setClientDiscountForGroups('');
    }
  }, [clientDiscount, discounts]);


  const handleCloseError = (uid) => {
    let _errors = errors;
    orderInfo.clearError('client_info', uid);

    if (uid) {
      let idx = _errors.findIndex(error => error.uid === uid);
      if (idx >= 0) {
        _errors.splice(idx, 1);
      }
    } else {
      _errors = [];
    }

    setErrors(_errors);
  };


  const validate = async (showError = false) => {
    let errors = await orderInfo.validate(STEPS.client_info);

    if (showError) {
      setErrors(errors);
    }
  };


  useEffect(() => {
    let orderSession = userInfo.getSession();

    setPhones((orderSession.contact_phones || []).map((phone) => ({ value: phone, error: null })));

    loadUserByPhone(orderSession.phone);
  }, []);


  useEffect(() => {
    setClientName(`${clientFirstName} ${clientLastName} ${clientMiddleName}`.trim());
  }, [clientFirstName, clientLastName, clientMiddleName, clientName]);


  useEffect(() => {
    if (clientUser && !clientPhone) {
      repository.request(API.user.user, {id: clientUser}).then((user) => {
        setClientPhone(user.phone);
        setClientFirstName(user.profile.firstName);
        setClientLastName(user.profile.lastName);
        setClientMiddleName(user.profile.middleName);
        setClientName(user.profile.fullName);
    }).catch((e) => {
        console.error(e);
        setDiscounts([]);
      });
    } else {
      setClientName(`${clientFirstName} ${clientLastName} ${clientMiddleName}`.trim());
    }
  }, [clientPhone, clientFirstName, clientLastName, clientMiddleName, clientName, clientUser, clientBirthday]);


  useEffect(() => {
    setClientName(`${clientFirstName} ${clientLastName} ${clientMiddleName}`.trim());

    validate();
  }, [clientPhone, clientContactPhones, clientName, clientFirstName, clientLastName, clientMiddleName, clientBirthday, clientDiscount, clientUser]);

  useEffect(() => {
    if (commonExcursion && commonSchedule && commonSubdivision) {
      loadDiscounts();
    }
  }, [commonExcursion, commonSchedule, commonSubdivision, excursion, schedule]);

  return (
    <>
      <div>
        {errors.map((error, i) => (
          <Alert type={error.type} key={error.uid} title={error.title} dismissible={true} onClose={() => handleCloseError(error.uid)}>{error.message}</Alert>
        ))}
        <Field title={t('order:client_info.phone')}>
          <div className="input-group">
            <Phone.Input className="form-control phone-primary" value={clientPhone} onChange={handlePhoneInput}/>
            {clientPhone && (
              <span className="input-group-text">
                <AddIcon onClick={handleAddPhone} />
              </span>
            )}
          </div>
        </Field>
        {phones.length > 0 && (
          <Field title={t('order:client_info.phone_additional')}>
            {phones.map((phone, i) => (
              <Fragment key={i}>
                <div className="input-group">
                  <Phone.Input className="form-control phone-additional" data-idx={i} value={phone.value} name="add-phone" international onChange={(val) => {handleContactPhoneInput(val, i);}}/>
                  <span className="input-group-text">
                    <DeleteIcon onClick={() => handleDeletePhone(i)} />
                  </span>
                </div>
                {phone.error && (
                  <ErrorHint error={phone.error} />
                )}
              </Fragment>
            ))}
          </Field>
        )}
        {clientPhone && (
          <>
            {clientUser ? (
              <Field title={t('order:client_info.person_name')}>
                <Input value={clientName} readOnly name="person_name"/>
              </Field>
            ) : (
              <>
                <Field title={t('order:client_info.first_name')}>
                  <Input value={clientFirstName} name="first_name" onChange={handleFirstNameInput}/>
                </Field>
                <Field title={t('order:client_info.last_name')}>
                  <Input value={clientLastName} name="last_name" onChange={handleLastNameInput}/>
                </Field>
                <Field title={t('order:client_info.middle_name')}>
                  <Input value={clientMiddleName} name="middlw_name" onChange={handleMiddleNameInput}/>
                </Field>
                <Field title={t('order:client_info.birthday')}>
                  <SelectDate mode={DAY_PICKER_MODE.SINGLE} selected={clientBirthday} onChange={handleBirthdayInput} readOnly={!!clientUser} fromYear={1900} toYear={new Date().getFullYear()} />
                </Field>
              </>
            )}
          </>
        )}
        <Field title={t('order:client_info.discounts')}>
          <Select
            value={clientDiscount ? clientDiscount : ''}
            empty={{ value: '', text: t('order:client_info.no_discount')}}
            options={discounts.map((discount) => (
              {
                value: discount.id,
                text: `${discount.title}: ${t(`excursion:price.${discount.forGroups}`)} - ${discount.discount.type === DynamicPriceType.PERCENT ? `${discount.discount.value}%` : Price.Text({ currency: CURRENCY.RUB, value: discount.discount.value })}`
              }
            ))}
            onSelect={handleDiscountSelect}
          />
        </Field>
      </div>
    </>
  )
}

StepClientInfo.propTypes = {
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  onError: PropTypes.func,
};

export default StepClientInfo;
import PropTypes from 'prop-types';

const DropDown = ({ className = '', onClick, ...otherProps }) => {
  return (
  <svg width="24" height="24" viewBox="0 0 24 24" className={`icon ${className}`} fill="none" xmlns="http://www.w3.org/2000/svg"  onClick={onClick} {...otherProps}>
    <path d="M17.0002 9.17019C16.8128 8.98394 16.5594 8.87939 16.2952 8.87939C16.031 8.87939 15.7776 8.98394 15.5902 9.17019L12.0002 12.7102L8.46019 9.17019C8.27283 8.98394 8.01938 8.87939 7.75519 8.87939C7.49101 8.87939 7.23756 8.98394 7.05019 9.17019C6.95646 9.26315 6.88207 9.37375 6.8313 9.49561C6.78053 9.61747 6.75439 9.74818 6.75439 9.88019C6.75439 10.0122 6.78053 10.1429 6.8313 10.2648C6.88207 10.3866 6.95646 10.4972 7.05019 10.5902L11.2902 14.8302C11.3832 14.9239 11.4938 14.9983 11.6156 15.0491C11.7375 15.0998 11.8682 15.126 12.0002 15.126C12.1322 15.126 12.2629 15.0998 12.3848 15.0491C12.5066 14.9983 12.6172 14.9239 12.7102 14.8302L17.0002 10.5902C17.0939 10.4972 17.1683 10.3866 17.2191 10.2648C17.2699 10.1429 17.296 10.0122 17.296 9.88019C17.296 9.74818 17.2699 9.61747 17.2191 9.49561C17.1683 9.37375 17.0939 9.26315 17.0002 9.17019Z" fill="#09101D"/>
  </svg>

);
}

DropDown.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default DropDown;
import PropTypes from 'prop-types';
import FailIcon from '../../components/icons/Fail';

const PaymentFail = ({message}) => {
  return (
    <>
      <div className="payment-status">
        <FailIcon className="payment-status-fail"/>
      </div>

      <div className="payment-status">
        <p className="payment-status-message">{message}</p>
      </div>
    </>
  );
};

PaymentFail.propTypes = {
  message: PropTypes.string,
};

export default PaymentFail;
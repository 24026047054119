import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import DateFormat from './DateFormat';

dayjs.extend(utc);
dayjs.extend(timezone);

const DatePeriod = ({ className = '', start, end, onClick, ...otherProps }) => {
  const {t} = useTranslation(['common']);

  const [period, setPeriod] = useState('');

  useEffect(() => {
    let periodLine = '';
    if (start || end) {
      let startDate = dayjs(start);
      let endDate = dayjs(end || start);
      let daysInEnd = endDate.daysInMonth();
      let yearInStart = startDate.year();
      let yearInEnd = endDate.year();
      let yearInNow = dayjs().year();

      let startYear = yearInNow === yearInStart ? '' : ` ${yearInStart}`;
      let endYear = yearInNow === yearInEnd ? '' : ` ${yearInEnd}`;

      if (startDate.month() === endDate.month()) {
        if (startDate.date() === endDate.date()) {
          periodLine = `${startDate.date()} ${t(`common:date.months.${startDate.month()}`)}${startYear}`;
        } else if (startDate.date() === 1 && endDate.date() === daysInEnd) {
          periodLine = `${t(`common:date.month.${endDate.month()}`)}${startYear}`;
        } else {
          periodLine = `${startDate.date()} ${t(`common:date.months.${startDate.month()}`)}${startYear} - ${endDate.date()} ${t(`common:date.months.${endDate.month()}`)}${endYear}`;
        }
      } else if (startDate.date() === 1 && endDate.date() === daysInEnd) {
        periodLine = `${t(`common:date.month.${startDate.month()}`)}${startYear} - ${t(`common:date.month.${endDate.month()}`)}${endYear}`;
      } else if (startDate.date() === 1) {
        periodLine = `${t(`common:date.month.${startDate.month()}`)}${startYear} - ${endDate.date()} ${t(`common:date.months.${endDate.month()}`)}${endYear}`;
      } else if (endDate.date() === daysInEnd) {
        periodLine = `${startDate.date()} ${t(`common:date.months.${startDate.month()}`)}${startYear} - ${t(`common:date.month.${endDate.month()}`)}${endYear}`;
      } else {
        periodLine = `${startDate.date()} ${t(`common:date.months.${startDate.month()}`)}${startYear} - ${endDate.date()} ${t(`common:date.months.${endDate.month()}`)}${endYear}`;
      }
    }

    setPeriod(periodLine);
  }, [start, end]);

  return (
    <span className={`date-period ${className}`} onClick={() => onClick && onClick(period)} {...otherProps}>{period || (<>&nbsp;</>)}</span>
  );
}

DatePeriod.propTypes = {
  className: PropTypes.string,
  start: PropTypes.any,
  end: PropTypes.any,
  onClick: PropTypes.func
};

export default DatePeriod;

export const getDatePeriodArray = (startDay, count) => {
  let days = [];
  let day = dayjs(startDay);

  for (let i = 0; i < count; ++i) {
    let date = day.add(i, 'day');
    days.push({ id: i, date, str: DateFormat.Request({ date }) });
  }

  return days;
};
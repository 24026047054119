import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SuccessIcon from '../../components/icons/Success';
import Price from '../../components/Price';

const PaymentSuccess = ({amount}) => {
  const {t} = useTranslation(['common', 'order']);

  return (
    <>
      <div className="payment-status">
        <SuccessIcon className="payment-status-success"/>
      </div>

      <div className="payment-status">
        <p className="payment-status-message">{t('order:payment.success', {amount: Price.Text({value: amount})})}</p>
      </div>
    </>
  );
};

PaymentSuccess.propTypes = {
  amount: PropTypes.number,
};

export default PaymentSuccess;
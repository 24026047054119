import { getStorageValue } from './useLocalStorage';
import { STORAGE } from '../defs/storage';
import User from '../models/user/User';
import Fixture from '../fixtures/Fixture';
import { callFixture } from '../fixtures/Fixture';
import { APICode } from '../models/types';
import API from '../server/api';

const useFixtures = (setIsLoading = () => {}) => {

  const callApi = async (api, params) => {
    if (!api || typeof api !== 'object') {
      return { error: APICode.UNKNOWN_REQUEST };
    }

    setIsLoading(true);

    let response = null;
    if (api.auth) {
      let authInfo = getStorageValue(STORAGE.AUTH, null, User);
      if (!authInfo || !authInfo.profile || !authInfo.profile.id) {
        setIsLoading(false);
        return { error: APICode.UNAUTHORIZED }
      }
    }

    let url = api.url;
    for (const param in api.uriVars) {
      let key = param.slice(1);
      const re = new RegExp(`${param}\\b`, 'gi');
      url = url.replace(re, params[key] || '');
    }

    const [moduleName, methodName] = getAPI(url);
    if (moduleName && methodName) {
      response = callFixture(Fixture[moduleName][methodName]['success'], params, Fixture.get);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      return { error: APICode.UNKNOWN_RESPONSE };
    }

    if (response) {
      return response;
    } else {
      return { error: APICode.UNKNOWN_RESPONSE };
    }
  };

  return {
    callApi,
  };
}

export const getAPI = (url) => {
  for (const moduleName in API) {
    for (const methodName in API[moduleName]) {
      if (API[moduleName][methodName].url === url) {
        return [moduleName, methodName, API[moduleName][methodName]];
      }
    }
  }
  return [null, null, null];
};

export default useFixtures;
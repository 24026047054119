import PropTypes from 'prop-types';

const Add = ({ className = '', onClick, ...otherProps }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={`icon ${className}`} viewBox="0 0 24 24" onClick={onClick} {...otherProps}>
      <path d="M19 11H13V5a1 1 0 0 0-2 0v6H5a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0V13h6a1 1 0 0 0 0-2Z"/>
    </svg>
  );
}

Add.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default Add;
import { useState, createContext, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../server/api';
import Logger from '../utils/logger';
import { STORAGE } from '../defs/storage';
import { getStorageValue, setStorageValue } from '../hooks/useLocalStorage';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Point from '../models/point/Point';
import Schedule from '../models/schedule/Schedule';
import User from '../models/user/User';
import UserSession from '../models/session/UserSession';
import Place from '../models/place/Place';
import { RepositoryContext } from './RepositoryProvider';
import ClientDetails from '../models/user/ClientDetails';
import Order from '../models/order/Order';
import Account from '../models/account/Account';
import { AutocompleteItemTemplate } from '../models/AutocompleteItem';

export const UserContext = createContext();

dayjs.extend(utc);
dayjs.extend(timezone);

const initialState = {
  user: null,
  account: null,
  session: null,
  scheduleDates: [],
  scheduleTimes: [],
  schedules: [],
  points: [],
  places: [],
  prices: [],
};

export const UserProvider = (props) => {
  const logger = new Logger({ name: 'UserProvider', showDate: false, showFile: false });
  const {t} = useTranslation(['common', 'account']);

  const [userSession, setUserSession] = useState(getStorageValue(STORAGE.SESSION));
  const [userInfo, setUserInfo] = useState(initialState);
  const [login, setLogin] = useState(false);


  const repository = useContext(RepositoryContext);


  const _setSession = (field, value) => {
    setUserSession((prevState) => ({ ...prevState, [field]: value }));

    if (userInfo.session === null || userInfo.session[field] !== value) {
      let session = new UserSession({ ...getStorageValue(STORAGE.SESSION), [field]: value });
      setStorageValue(STORAGE.SESSION, session);
      _setState('session', session);
    }
  };

  const _useSession = (...args) => {
    const [field] = args;

    useEffect(() => {
      if (args.length > 1) {
        _setSession(field, args[1]);
      } else {
        _setSession(field, userSession[field]);
      }
    }, []);

    return [userSession[field], (value) => _setSession(field, value)];
  };


  const _setState = (field, value) => {
    setUserInfo((prevState) => ({ ...prevState, [field]: value }));
  };

  const _useState = (...args) => {
    const [field] = args;

    useEffect(() => {
      if (args.length > 1) {
        _setState(field, args[1]);
      } else {
        _setState(field, userInfo[field]);
      }
    }, []);

    return [userInfo[field], (value) => _setState(field, value)];
  };


  const fetch = {
    user: () => {
      if (userInfo.user) {
        return Promise.resolve(userInfo.user);
      } else {
        return repository.action(API.user.whoami).then((response) => {
          let user = null;
          let accountId = '';
          if (response.error) {
            //TODO:: handle error
            setUser(null);
          } else {
            user = setUser(response);

            if (user) {
              _setSession('logged_in', true);

              let account = null;

              if (userSession.account) {
                account = user.accounts.find((account) => account.account.id === userSession.account);
              }

              if (!account && user.accounts.length > 0) {
                account = user.accounts[0];
              }

              accountId = account ? account.account.id : '';

              if (accountId && userSession.account !== accountId && getSession().isCashier()) {
                loadPoints({accountId}).catch(() => {});
              }

              if (!account) {
                _setSession('point', '');
                _setSession('subdivision', '');
              }


              _setSession('account', accountId);
              _setState('account', account);

              let role = userSession.role;
              if (account && (!role || account.roles.indexOf(role) === -1)) {
                role = account.roles[0];
              } else if (!account) {
                role = '';
              }

              _setSession('role', role);

              let point = userSession.point;
              if (account && (point || account.roles.indexOf(role) === -1)) {
                role = account.roles[0];
              }
            } else {
              _setSession('point', '');
              _setSession('subdivision', '');
              _setSession('role', '');
              _setSession('account', '');
              _setState('account', null);
            }
          }
          return { user, accountId };
        }).catch((e) => {
          logger.error(e);
          return Promise.reject(e);
        });
      }
    },

    points: ({ accountId }) => {
      return repository.list(API.point.autocompleteList, { accountId: accountId || userSession.account }, AutocompleteItemTemplate(Point))
        .then((rows) => rows)
        .catch((e) => []);
    },

    schedules: (day) => {
      return new Promise((resolve, reject) => {
        let date = dayjs().format('YYYY-MM-DD');
        if (day) {
          date = dayjs(day).format('YYYY-MM-DD');
        }

        let subdivision = userSession.subdivision;

        if (subdivision) {
          repository.action(API.excursion.excursions, {date: date, timezone: dayjs.tz.guess(), subdivision}).then((response) => {
            resolve(setSchedules(response));
          }).catch(reject);
        } else {
          resolve([]);
        }
      });
    },

    scheduleDates: (excursionId, subdivisionId) => {
      return repository.action(API.schedule.datesSeats, { accountId: userSession.account, id: excursionId, subdivision: subdivisionId }, String).then((dates) => {
        setScheduleDates(dates);
        return dates;
      });
    },

    scheduleTimes: (excursionId, day) => {
      return repository.list(API.schedule.times, { id: excursionId, date: dayjs(day || undefined).format('YYYY-MM-DD'), accountId: userSession.account }, Object).then((times) => {
        setScheduleTimes(times);
        return times;
      });
    },

    place: (id) => {
      return repository.action(API.place.place, {id}).then((response) => {
        let place = new Place(response);
        setPlaces([ ...userInfo.places, place ]);
        return place;
      });
    }
  };

  // useEffect(() => {
  //   getPoints();
  // }, [userSession.account]);

  const reset = () => {
    setUserSession(initialState);
  };

  const setLoggedIn = (logged_in) => {
    userSession.logged_in = logged_in;
    setLogin(logged_in);
    _setSession('logged_in', logged_in);
  };

  const getSession = () => {
    return userInfo.session || getStorageValue(STORAGE.SESSION, userSession, UserSession);
  };

  const isLoggedIn = () => {
    return getSession().logged_in;// && getSession().account;
  };


  const setUser = (prop = null) => {
    let user = prop === null ? null : prop instanceof User ? prop : new User({ ...userInfo.user, ...prop });
    _setState('user', user);
    _setSession('user', user ? user.id : null);
    return user;
  };

  const getUser = () => {
    return new Promise((resolve, reject) => {
      let user = userInfo.user;

      let logged_in = userSession ? userSession.logged_in : (userInfo.session ? userInfo.session.logged_in : getSession().logged_in);
      if ((user === null || !user.isValid()) && logged_in) {
        loadUser().then((user) => {
          resolve(user);
          return user;
        }).catch((e) => {
          console.error('USER', e);
          reject(e);
          return e;
        });
      } else if (logged_in) {
        resolve(user);
      } else {
        reject('FAIL');
      }
    });
  };

  const loadUser = () => {
    return fetch.user().then(({user, accountId}) => {
      if (getSession().isCashier()) {
        return getSession().isCashier() ? loadPoints({ accountId }).then(() => user).catch((e) => user) : user;
      } else {
        return user;
      };
    });
  };

  const setAccount = (prop = null) => {
    let account = prop === null ? null : prop instanceof Account ? prop : new Account({ ...userSession.account, ...prop });
    setUserSession((prevState) => { prevState.account = account; return prevState; });
    return account;
  };

  const loadPoints = ({accountId}) => {
    return new Promise((resolve, reject) => {
      if (userSession.account || accountId) {
        fetch.points({ accountId: userSession.account || accountId }).then((points) => {
          if (points) {
            setPoints(points);
            let _point = points.find((p) => p.meta.id === userSession.point);
            if (!userSession.point || !_point) {
              _setSession('point', points.length ? points[0].meta.id : '');
              _setSession('subdivision', points.length ? points[0].meta.subdivision.id : '');
            }
            resolve(points);
          } else {
            resolve([]);
          }

          return points || [];
        }).catch((e) => {
          logger.error('Load Points:', e);
          resolve([]);
        });
      } else {
        reject([]);
      }
    });
  };


  const setSchedules = (prop = []) => {
    let schedules = prop === null ? [] : prop.map((schedule) => new Schedule(schedule));
    setUserSession((prevState) => { prevState.schedules = schedules; return prevState; });
    return schedules;
  };

  const getSchedules = () => {
    return userInfo.schedules;
  };


  const setScheduleDates = (prop = []) => {
    let scheduleDates = prop === null ? [] : prop.map((scheduleDates) => new Schedule(scheduleDates));
    setUserSession((prevState) => { prevState.scheduleDates = scheduleDates; return prevState; });
    return scheduleDates;
  };

  const getScheduleDates = async (day) => {
    return userInfo.scheduleDates;
  };


  const setScheduleTimes = (prop = []) => {
    let scheduleTimes = prop === null ? [] : prop.map((schedulTimee) => new Schedule(schedulTimee));
    setUserSession((prevState) => { prevState.scheduleTimes = scheduleTimes; return prevState; });
    return scheduleTimes;
  };

  const getScheduleTimes = async (day) => {
    return userInfo.scheduleTimes;
  };


  const setPoints = (prop = []) => {
    let points = prop === null ? [] : prop.map((point) => new Point(point))
    setUserSession((prevState) => { prevState.points = points; return prevState; });
    return points;
  };

  const getPoints = () => {
    return userInfo.points;
  };


  const setPlaces = (prop = []) => {
    let places = prop === null ? [] : prop.map((place) => new Place(place))
    setUserSession((prevState) => { prevState.places = places; return prevState; });
    return places;
  };


  const getMyOrders = async (params) => {
    return repository.list(API.cashier.orders, params, Order).then((rows) => {
      return rows || [];
    });
  };

  const autocompleteOrderClients = async (query) => {
    return repository.list(API.order.autocomplete, { query }, ClientDetails).then((clients) => {
      return clients || [];
    });
  };

  const signIn = (phone, password) => {
    return new Promise((resolve, reject) => {
      repository.action(API.auth.signIn, { phone, password }).then((response) => {
        if (response) {
          if (response.error) {
            reject({ value: phone, error: t(`common:error.${response.error.toLowerCase()}`) });
          } else {
            setLoggedIn(true);
            
            getUser().then((user) => {
              if (user && user.isValid()) {
                resolve({ value: phone, error: false });
              } else {
                setLoggedIn(false);
                reject({ value: phone, error: t('common:error.user_not_found')});
              }
            }).catch((e) => {
              setLoggedIn(false);
              reject({ value: phone, error: t('common:error.user_not_found')});
            });
          }
        } else {
          reject({ value: phone, error: t('common:error.unknown_response')});
        }
      }).catch((e) => {
        reject({ value: phone, error: t(`common:error.${e.message.toLowerCase()}`) });
      });
    });
  };


  const signOut = () => {
    setLoggedIn(false);
    setStorageValue(STORAGE.SESSION, new UserSession());
    _setState('session', null);
    setUser(null);
    setAccount(null);
    return repository.action(API.auth.signOut);
  };

  useEffect(() => {
    if (login) {
      loadUser();
    }
  }, [login]);

  useEffect(() => {
    const session = getStorageValue(STORAGE.SESSION, UserSession);

    _setSession('account', session.account);
    _setSession('role', session.role);
    _setSession('subdivision', session.subdivision);
    _setSession('point', session.point);
    _setSession('logged_in', session.logged_in);

    if (props.logged_in !== undefined) {
      setLoggedIn(props.logged_in);
    } else {
      setLogin(session.logged_in);
    }
  }, []);


  return (
    <UserContext.Provider
      value={{
        getUserInfo: () => userSession,
        reset,

        useSession: _useSession,
        useState: _useState,

        setLoggedIn,
        getSession,
        isLoggedIn,

        getUser,
        setSchedules,
        getSchedules,
        setScheduleDates,
        getScheduleDates,
        setScheduleTimes,
        getScheduleTimes,
        setPoints,
        getPoints,
        getMyOrders,
        autocompleteOrderClients,

        loadUser,
        loadPoints,

        signIn,
        signOut,
        fetch
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

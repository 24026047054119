import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Price, { CURRENCY } from '../../components/Price';
import DateFormat from '../../components/DateFormat';
import Phone from '../../components/Phone';
import Button, { BUTTON_TYPE } from '../../components/Button';
import { PriceType } from '../../models/types';
import MinusIcon from '../../components/icons/Minus';
import AddIcon from '../../components/icons/Add';
import { useEffect, useState } from 'react';
import Info from '../../components/Info';

const TicketView = ({ ticket, schedule, place, onChange }) => {
  const {t} = useTranslation(['common', 'route']);

  const [prices, setPrices] = useState([]);
  const [leftToPay, setLeftToPay] = useState(0);
  const [totalSeats, setTotalSeats] = useState([]);

  const handleIncrement = (price) => {
    price.count = Math.min(price.count + 1, (ticket.prices.find((p) => p.price.id === price.price.id) || {count: 0}).count);
    setPrices([...prices]);

    let currentLeftToPay = prices.reduce((amount, price) => amount + price.count * price.actualPrice.leftToPay, 0);
    setLeftToPay(Math.max(currentLeftToPay), 0);

    let seats = prices.reduce((seat, price) => {
      seat[price.price.id] = price.count;
      return seat;
    }, {});
    onChange && onChange(ticket.seats[0].transport.id, seats);
  };

  const handleDecrement = (price) => {
    price.count = Math.max(price.count - 1, 0);
    setPrices([...prices]);

    let currentLeftToPay = prices.reduce((amount, price) => amount + price.count * price.actualPrice.leftToPay, 0);
    setLeftToPay(Math.max(currentLeftToPay), 0);

    let seats = prices.reduce((seat, price) => {
      seat[price.price.id] = price.count;
      return seat;
    }, {});
    onChange && onChange(ticket.seats[0].transport.id, seats);
  };

  useEffect(() => {
    setPrices(ticket.prices.map((p) => ({
      id: p,
      priceType: p.priceType,
      noSeat: p.noSeat,
      count: p.count,
      price: p.price,
      actualPrice: p.actualPrice,
    })));
    setLeftToPay(ticket.leftToPay);
    let totalSeats = ticket.seats.reduce((ts, transport) => ts.concat(transport.seats.reduce((s, seat) => { s.push({ transport: transport.transport.id, seat, price: '' }); return s; }, [])), []);
    setTotalSeats(totalSeats);

    let seats = ticket.prices.reduce((seat, price) => {
      seat[price.price.id] = price.count;
      return seat;
    }, {});
    onChange && onChange(ticket.seats[0].transport.id, seats);
  }, []);

  return (
    <div>
      <Info title={t('route:ticket_input.total_amount')}>
        <Price currency={CURRENCY.RUB} value={leftToPay} />
      </Info>
      <div className="info-field">
        {t('route:ticket_input.excursion')}
        <div className="info-field-data">
          {ticket.excursion.title}
        </div>
      </div>
      <div className="info-field">
        {t('route:ticket_input.date_departure')}
        <div className="info-field-data">
          <DateFormat.Day date={schedule.date} /> <DateFormat.Time date={place.time ? place.time : schedule.date} />
        </div>
      </div>
      <div className="info-field">
        {t('route:ticket_input.client')}
        <div className="info-field-data">
          {ticket.user.profile.fullName}
        </div>
        <div className="info-field-data">
          <Phone.Call value={ticket.user.phone} />
        </div>
      </div>
      {ticket.seller && (
        <div className="info-field">
          {t('route:ticket_input.seller')}
          <div className="info-field-data">
            {ticket.seller.user.profile.fullName}
          </div>
          <div className="info-field-data">
          <Phone.Call value={ticket.seller.phone} />
          </div>
        </div>
      )}
      <div className="info-field">
        {t('route:ticket_input.seats')}
        <div className="info-field-data">
          {totalSeats.map((s) => s.seat).join(', ')}
        </div>
      </div>
      <div className="info-field">
        {t('route:ticket_input.transport')}
        <div className="info-field-data">
          {ticket.seats.map((s) => s.transport.title).join(', ')}
        </div>
      </div>
      {prices.map((price, i) => (
        <div key={price.price.id} className="info-field">
          <div className="info-field-data">
            <div className="price">
              <div className="price-title" title={price.priceType === PriceType.CHILD ? (price.noSeat ? t('order:price_type.no_seat') : t('order:price_type.with_seat')) : ''}>{price.price.title}</div>
              <span className="price-count flex-shrink-1">
                <Button className="price-button" variant={BUTTON_TYPE.SECONDARY} onClick={() => handleDecrement(price)}><MinusIcon /></Button>
              </span>
              <span className="price-count_content">{price.count}</span>
              <span className="price-count flex-shrink-1">
                <Button className="price-button" variant={BUTTON_TYPE.SECONDARY} onClick={() => price.noSeat ? () => {} : handleIncrement(price)}><AddIcon className={price.noSeat ? '' : 'icon-disabled'} /></Button>
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

TicketView.propTypes = {
  ticket: PropTypes.any.isRequired,
  schedule: PropTypes.any.isRequired,
  place: PropTypes.any.isRequired,
  onChange: PropTypes.func,
};

export default TicketView;
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RepositoryContext } from '../../store/RepositoryProvider';
import { UserContext } from '../../store/UserProvider';
import NavBar from '../../components/NavBar';
import ReportFilter, { Filter, SHOW_MODE } from '../../components/ReportFilter';
import API from '../../server/api';
import Table, { ALIGN_TABLE } from '../../components/Table';
import InfoDescription from '../../components/InfoDescription';
import Badge, { BADGE_TYPE } from '../../components/Badge';
import DateFormat from '../../components/DateFormat';
import Phone from '../../components/Phone';
import log from '../../utils/logger';
import Modal from '../../components/Modal';
import OrderInfo from '../order/order_info';
import Info from '../../components/Info';
import DatesIcon from '../../components/icons/Dates';
import Dropdown, { ALIGN_DROPDOWN } from '../../components/Dropdown';
import { STORAGE } from '../../defs/storage';
import SalesSession from '../../models/session/SalesSession';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import ChangeOrderState from '../order/change_order_state';
import Button, { BUTTON_SIZE, BUTTON_TYPE } from '../../components/Button';

const ORDER_STATUS = {
  paid: BADGE_TYPE.SUCCESS,
  awaiting_payment: BADGE_TYPE.WARN,
  cancelled: BADGE_TYPE.ERROR,
  completed: BADGE_TYPE.INFO,
  awaiting_assignment: BADGE_TYPE.INFO
};

const MySales = () => {
  const {t} = useTranslation(['common', 'sale', 'order']);

  const userInfo = useContext(UserContext);
  const repository = useContext(RepositoryContext);

  const [showOrderInfo, setShowOrderInfo] = useState(false);
  const [showTicket, setShowTicket] = useState(false);
  const [showEditOrder, setShowEditOrder] = useState(false);
  const [showChangeStatus, setShowChangeStatus] = useState(false);
  const [order, setOrder] = useState(null);

  const [salesSession, setSalesSession] = useLocalStorage(STORAGE.SALES_SESSION, null, SalesSession);

  const handleCloseOrderInfo = useCallback(() => {
    setShowOrderInfo(false);
    setOrder(null);
  }, []);

  const handleOpenOrderInfo = useCallback((order) => {
    setOrder(order);
    setShowOrderInfo(true);
  }, []);

  const handleCloseTicket = useCallback(() => {
    setShowTicket(false);
    setOrder(null);
  }, []);

  const handleOpenTicket = useCallback((order) => {
    setOrder(order);
    setShowTicket(true);
  }, []);

  const handleCloseEditOrder = useCallback(() => {
    setShowEditOrder(false);
    setOrder(null);
  }, []);

  const handleOpenEditOrder = useCallback((order) => {
    setOrder(order);
    setShowEditOrder(true);
  }, []);

  const handleCloseChangeStatus = useCallback(() => {
    setShowChangeStatus(false);
    setOrder(null);
  }, []);

  const handleHideChangeStatus = useCallback(() => {
    setShowChangeStatus(false);
  }, []);

  const handleOpenChangeStatus = useCallback((order) => {
    setOrder(order);
    setShowChangeStatus(true);
  }, []);

  useEffect(() => {
  }, []);

  const autocompleteTemplate = (item) => {
    let params = item.split(',');
    return `${params[0]}<div class="small">${params[1]}</div>`
  };

  const autocompleteHandler = async (query) => {
    return repository.list(API.order.autocomplete, { query }, Object).then((rows) => {
      let list = [];
      if (rows) {
        for (let i = 0; i < rows.length; ++i) {
          list.push({
            value: rows[i].value,
            title: `${rows[i].label}, ${t('sale:my_sales.phone', { phone: Phone.Text({ phone: rows[i].meta.phone }) })}`,
          });
        }
      }
      return list;
    }).catch((e) => {
      log.error(e);
      return Promise.reject(e);
    });
  };

  return (
    <div className="trip-app">
      <NavBar title={t('sale:my_sales.title')} />
      <div className="trip-app-body">
        <ReportFilter
          apiHandler={(reportParams) => {
            setSalesSession({
              client: reportParams.filters.client,
              date: reportParams.filters.date
            });
            return userInfo.getMyOrders(reportParams.filters);
          }}
          show={SHOW_MODE.ALWAYS}
          autosuggest={{
            key: 'client',
            template: autocompleteTemplate,
            apiHandler: autocompleteHandler
          }}
          placeholder={t('sale:my_sales.filter')}
          output={(list) => (
            <Table cols={2}>
              {list.filter((order) => !salesSession.date || DateFormat.Request({date: order.date}) === DateFormat.Request({date: salesSession.date})).map((order) => (
                <Fragment key={order.id}>
                  <Table.Row>
                    <Table.Cell onClick={() => { handleOpenOrderInfo(order); }}>
                      <InfoDescription description={<>{t('sale:my_sales.from_date')}<DateFormat.DateTime date={order.date}/></>}>
                        {t('sale:my_sales.order', { order: order.number })}
                      </InfoDescription>
                    </Table.Cell>
                    <Table.Cell align={ALIGN_TABLE.RIGHT}>
                      <Badge type={ORDER_STATUS[order.status]}>{t(`sale:status.${order.status}`)}</Badge>
                      {false && (
                        <Dropdown align={ALIGN_DROPDOWN.RIGHT} title={<DatesIcon/>}>
                          <Dropdown.Item onClick={() => handleOpenTicket(order)}>{t('order:context_menu.ticket')}</Dropdown.Item>
                          {/* <Dropdown.Item onClick={() => handleOpenEditOrder(order)}>{t('order:context_menu.edit')}</Dropdown.Item> */}
                          <Dropdown.Item onClick={() => handleOpenChangeStatus(order)}>{t('order:context_menu.status')}</Dropdown.Item>
                        </Dropdown>
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row className="pb-0">
                    <Table.Cell className="small">
                      {t('sale:my_sales.excursion')}
                    </Table.Cell>
                    <Table.Cell className="small" align={ALIGN_TABLE.RIGHT}>
                      <Info>{order.excursion.title}</Info>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row className="pt-0 pb-0">
                    <Table.Cell className="small">
                      {t('sale:my_sales.departure')}
                    </Table.Cell>
                    <Table.Cell className="small" align={ALIGN_TABLE.RIGHT}>
                      <Info><DateFormat.DateTime date={order.schedule.date}/></Info>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row delim={true} className="mb-1" />
                </Fragment>
              ))}
            </Table>
          )}
          filters={[
            // { key: 'number', title: t('sale:filter.order'), type: 'value.text' },
            new Filter({ key: 'date', title: t('sale:filter.schedule.date'), type: 'value.date', value: salesSession.date }),
            // { key: 'status', title: t('sale:filter.status'), type: 'badge', list: [
            //   { id: OrderStatus.AWAITING_PAYMENT, title: t('sale:status.awaiting_payment') },
            //   { id: OrderStatus.PAID, title: t('sale:status.paid') },
            //   { id: OrderStatus.CANCELLED, title: t('sale:status.cancelled') },
            //   { id: OrderStatus.COMPLETED, title: t('sale:status.completed') }
            // ] },
          ]}
        />
      </div>

      <Modal show={order && showOrderInfo} onHide={handleCloseOrderInfo}>
        <Modal.Header closeButton>
          <InfoDescription
            description={(
              <>
                {t('order:order_info.create')}
                {order && (<DateFormat.DateTime date={order.date}/>)}
              </>
            )}
          >
            {t('order:order_info.title', { order: order && order.number })}
          </InfoDescription>
        </Modal.Header>
        <Modal.Body>
          {showOrderInfo && ( /* re-create body*/
            <OrderInfo order={order} onNext={handleCloseOrderInfo} onClose={handleCloseOrderInfo} />
          )}
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal show={order && showTicket} onHide={handleCloseTicket}>
        <Modal.Header closeButton>
          {t('order:ticket.title')}
        </Modal.Header>
        <Modal.Body>
          {showTicket && ( /* re-create body*/
            <OrderInfo order={order} onNext={handleCloseEditOrder} onClose={handleCloseTicket} />
          )}
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal show={order && showEditOrder} onHide={handleCloseEditOrder}>
        <Modal.Header closeButton>
          {t('order:edit_order.title')}
        </Modal.Header>
        <Modal.Body>
          {showEditOrder && ( /* re-create body*/
            <OrderInfo order={order} onNext={handleCloseEditOrder} onClose={handleCloseEditOrder} />
          )}
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal show={order && showChangeStatus} onHide={handleCloseChangeStatus}>
        <Modal.Header closeButton>
          {t('order:change_status.title', {order: order && order.number})}
          {order && (
            <Badge type={ORDER_STATUS.awaiting_assignment}>{order.schedule ? t(``) : t('order:state.awaiting_assignment')}</Badge>
          )}
        </Modal.Header>
        <Modal.Body>
          <ChangeOrderState order={order} onClose={handleCloseChangeStatus} onHide={handleHideChangeStatus} onOpen={handleOpenChangeStatus} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant={BUTTON_TYPE.SECONDARY} size={BUTTON_SIZE.AUTO} onClick={handleCloseChangeStatus}>{t('common:button.close')}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default MySales;
import PropTypes from 'prop-types';

const Flash = ({ className = '', onClick, ...otherProps }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={`icon ${className}`} viewBox="0 0 24 24" onClick={onClick} {...otherProps}>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.4222 10.1287H18.4L14.6667 15.1579L10.5778 20.421V13.8713H5.60001L9.71429 8.72519L13.4222 3.57904V10.1287ZM8.43345 7.77812L12.1183 2.66408C12.5217 2.10417 13.2457 1.86596 13.9089 2.07492C14.5721 2.28387 15.0222 2.89203 15.0222 3.57904V8.54975H18.4C19.0028 8.54975 19.5544 8.88408 19.827 9.41462C20.0996 9.94517 20.0469 10.5819 19.6907 11.0618L15.9574 16.0909C15.9505 16.1002 15.9435 16.1094 15.9364 16.1185L11.8476 21.3816C11.4304 21.9186 10.713 22.1336 10.0638 21.9162C9.41456 21.6989 8.97779 21.0975 8.97779 20.421V15.4503H5.60001C4.98692 15.4503 4.42774 15.1045 4.16028 14.5601C3.89283 14.0157 3.96414 13.3683 4.34389 12.8933L8.43345 7.77812Z"/>
    </svg>
  );
}

Flash.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default Flash;
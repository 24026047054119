import { useContext } from 'react';
import { UserContext } from '../store/UserProvider';
import PropTypes from 'prop-types';
import Login from '../screen/auth/login';
import Index from '../screen';

const PrivatePage = ({ roles = [], children }) => {
  const userInfo = useContext(UserContext);
  const userSession = userInfo.getSession();

  const checkAuth = () => {
    if (!userSession || !userInfo.isLoggedIn()) {
      return <Login />;
    } else if (roles.length === 0 || userSession.isAdmin() || userSession.isOwner() || userSession.hasRole(roles)) {
      return children;
    } else {
      return <Index />;
    }
  };

  return (
    <>
      {checkAuth()}
    </>
  );
};

PrivatePage.propTypes = {
  roles: PropTypes.array
};

export default PrivatePage;
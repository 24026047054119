import React from 'react';
import PropTypes from 'prop-types';
import BootstrapModal from 'react-bootstrap/Modal';

export const MODAL_TYPE = {
  MAIN: 'main',
  FULLSCREEN: 'fullscreen',
  INPUT: 'input'
};

const Modal = ({ show, onHide, type = MODAL_TYPE.MAIN, children, className = '', ...otherProps }) => {
  return (
    <>
      {show && (
        <BootstrapModal show={show} onHide={onHide} onExit={() => {
          document.body.removeAttribute('data-rr-ui-modal-open');
          document.body.style.removeProperty('overflow');
        }} className={`${type}-modal ${className}`} {...otherProps}>
          {children}
        </BootstrapModal>
      )}
    </>
  );
};

Modal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

const Header = ({ children, className = '', closeButton = false, ...otherProps }) => {
  return (
    <>
      {children && (
        <BootstrapModal.Header className={className} closeButton={closeButton} {...otherProps}>
          <div className={`trip-app-header`}>
            {children}
          </div>
        </BootstrapModal.Header>
      )}
    </>
  );
};

Modal.Header = Header;

Modal.Header.propTypes = {
  closeButton: PropTypes.bool,
};

const Body = ({ children, className = '', ...otherProps }) => {
  return (
    <>
      {children && (
        <BootstrapModal.Body className={className} {...otherProps}>
          <div className={`trip-app-body`}>
            {children}
          </div>
        </BootstrapModal.Body>
      )}
    </>
  );
};

Modal.Body = Body;

const Footer = ({ children, className = '', ...otherProps }) => {
  return (
    <>
      {children && (
        <BootstrapModal.Footer className={className} {...otherProps}>
          <div className={`trip-app-footer`}>
            {children}
          </div>
        </BootstrapModal.Footer>
      )}
    </>
  );
};

Modal.Footer = Footer;

export default Modal;
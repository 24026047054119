import ClassHelper from './ClassHelper';

class AutocompleteItem extends ClassHelper {
  constructor({ value, label, meta = {} } = {}, clazz = Object) {
    super(...arguments);
    this.value = value;
    this.label = label;
    this.meta = this.obj(meta, clazz);
    this.clazz = clazz;
  }

  instanceOf(clazz) {
    return this.meta instanceof clazz;
  }
}

export function AutocompleteItemTemplate(clazz) {
  return function assignClazz(data) {
    return new AutocompleteItem(data, clazz);
  };
}

export default AutocompleteItem;
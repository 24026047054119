import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../defs/routes';
import { UserContext } from '../store/UserProvider';


const Index = () => {
  const navigate = useNavigate();

  const userInfo = useContext(UserContext);
  const userSession = userInfo.getSession();

  useEffect(() => {
    if (userSession.account && userInfo.isLoggedIn()) {
      if (!userSession.role) {
        navigate(ROUTES.ACCOUNT_SELECT, { replace: true });
      } else {
        if (userSession.isCashier()) {
          navigate(ROUTES.EXCURSIONS, { replace: true });
        } else if (userSession.isGuide()) {
          navigate(ROUTES.MY_ROUTES, { replace: true });
        } else if (userSession.isCollector()) {
          navigate(ROUTES.COLLECTION_HISTORY, { replace: true });
        } else {
          navigate(ROUTES.TICKETS, { replace: true });
        }
      }
    } else if (userInfo.isLoggedIn()) {
      navigate(ROUTES.TICKETS, { replace: true });
    } else {
      navigate(ROUTES.AUTH, { replace: true });
    }
  }, [userSession, navigate]);

  return (
    <div>&nbsp;</div>
  );
};

export default Index;
import PropTypes from 'prop-types';

const Success = ({ className = '', onClick, ...otherProps }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={`icon ${className}`} viewBox="0 0 52 52" onClick={onClick} {...otherProps}>
      <path d="M26,2C12.7,2,2,12.7,2,26s10.7,24,24,24s24-10.7,24-24S39.3,2,26,2z M39.4,20L24.1,35.5c-0.6,0.6-1.6,0.6-2.2,0L13.5,27c-0.6-0.6-0.6-1.6,0-2.2l2.2-2.2c0.6-0.6,1.6-0.6,2.2,0l4.4,4.5c0.4,0.4,1.1,0.4,1.5,0L35,15.5c0.6-0.6,1.6-0.6,2.2,0l2.2,2.2C40.1,18.3,40.1,19.3,39.4,20z"/>
    </svg>
  );
}

Success.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default Success;
import PropTypes from 'prop-types';

const Minus = ({ className = '', onClick, ...otherProps }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={`icon ${className}`} viewBox="0 0 24 24" onClick={onClick} {...otherProps}>
      <path d="M19 11H5a1 1 0 0 0 0 2H19a1 1 0 0 0 0-2Z"/>
    </svg>
  );
}

Minus.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default Minus;
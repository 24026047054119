import { useCallback, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ROUTES } from '../../defs/routes';
import StepCommonInfo from './step_common_info';
import BackBar from '../../components/BackBar';
import Button, { BUTTON_POSITION, BUTTON_TYPE } from '../../components/Button';
import Modal from '../../components/Modal';
import { OrderContext, STEPS } from '../../store/OrderProvider';
import StepClientInfo from './step_client_info';
import StepPaymentBooking from './step_paymet_booking';
import ConfirmOrder from './confirm_order';
import Payment from './payment';
import Price, { CURRENCY } from '../../components/Price';
import InfoDescription from '../../components/InfoDescription';
import log from '../../utils/logger';
import StepPrint from './step_print';
import Stepper2 from '../../components/Stepper2';
import PaymentSuccess from './payment_success';
import PaymentFail from './payment_fail';
import OrderSession from '../../models/session/OrderSession';

const OrderForm = () => {
  let navigate = useNavigate();

  const {t} = useTranslation(['common', 'order']);

  const orderInfo = useContext(OrderContext);

  const [commonSchedule] = orderInfo.useSession('schedule');
  const [paymentOrder] = orderInfo.useSession('order');
  const [paymentNumber] = orderInfo.useSession('number');
  const [paymentMethod] = orderInfo.useSession('payment_method');
  
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [orderNumber, setOrderNumber] = useState('');

  const [paymentMessage, setPaymentMessage] = useState('');
  const [isValid, setIsValid] = useState(false);

  const handleNextStep = async () => {
    let errors = await orderInfo.validate(orderInfo.page);
    let isValid = errors.length === 0;
    setIsValid(isValid);

    if (isValid) {
      if (orderInfo.page === STEPS.client_info) {
        handleConfirmOrderShow();
      } else if (orderInfo.page === STEPS.payment_booking) {
        handlePaymentShow();
      } else {
        orderInfo.nextPage();
      }
    }
  };

  const handleConfirmOrder = async () => {
    orderInfo.createOrder().then(() => {
      setShowConfirmOrder(false);
      orderInfo.nextPage();
    }).catch((e) => {
      log.error(e);
      setShowConfirmOrder(false);
    });
  };

  const handleCancel = () => {
    orderInfo.reset();
    navigate(ROUTES.EXCURSIONS, { replace: true });
  };

  const handleSelectStep = useCallback((idx) => {
    orderInfo.setPage(idx);
  }, []);

  const [showConfirmOrder, setShowConfirmOrder] = useState(false);
  const handleConfirmOrderClose = () => setShowConfirmOrder(false);
  const handleConfirmOrderShow = () => setShowConfirmOrder(true);

  const [showPayment, setShowPayment] = useState(false);
  const handlePaymentClose = () => {
    setShowPayment(false);
  }
  const handlePaymentShow = () => setShowPayment(true);

  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const handlePaymentSuccess = (amount) => {
    setPaymentAmount(amount);
    setOrderNumber(paymentNumber);
    setShowPaymentSuccess(true);
  }
  const handlePaymentSuccessClose = () => {
    setShowPaymentSuccess(false);
    orderInfo.reset();
    navigate(ROUTES.EXCURSIONS, { replace: true });
  };

  const [showPaymentFail, setShowPaymentFail] = useState(false);
  const handlePaymentFail = (message) => {
    setPaymentMessage(message);
    setShowPaymentFail(true);
  }
  const handlePaymentFailClose = () => setShowPaymentFail(false);

  const validate = async (page) => {
    let errors = await orderInfo.validate(page);
    let isValid = errors.length === 0;
    setIsValid(isValid);    
  };

  useEffect(() => {
    if (paymentOrder) {
      orderInfo.setPage(STEPS.payment_booking, true);
    }  
  }, [paymentOrder]);  

  useEffect(() => {
    validate(orderInfo.page);
  }, [orderInfo.page])

  useEffect(() => {
    validate(orderInfo.page);
  }, [
    orderInfo.getSession().excursion,
    orderInfo.getSession().subdivision,
    orderInfo.getSession().passport_required,
    orderInfo.getSession().place,
    orderInfo.getSession().address,
    orderInfo.getSession().first_name,
    orderInfo.getSession().last_name,
    orderInfo.getSession().birthday,
    orderInfo.getSession().phone,
    orderInfo.getSession().discount,
    orderInfo.getSession().payment_method
  ])
  

  return (
    <>
      <div className="trip-app">
        <BackBar title={t('order:new_order.title')}>
          <Stepper2 steps={orderInfo.steps} activeStep={orderInfo.page} onSelect={handleSelectStep}></Stepper2>
        </BackBar>
        <div className="trip-app-body">
          <table className="info-table">
            <tbody>
              <tr>
                <td>
                  <InfoDescription description={t('order:new_order.in_check')}>
                    <Price currency={CURRENCY.RUB} value={orderInfo.getPaymentAmount()} />
                  </InfoDescription>
                </td>
                <td>
                  <InfoDescription description={t('order:new_order.total')}>
                    <Price currency={CURRENCY.RUB} value={orderInfo.getTotalAmount()} />
                  </InfoDescription>
                </td>
                <td>
                  <InfoDescription description={t('order:new_order.seats')}>
                    {commonSchedule ? orderInfo.getSeatNumbers().join(', ') : '-'}
                  </InfoDescription>
                </td>
              </tr>
            </tbody>
          </table>
          {orderInfo.page === STEPS.common_info && <StepCommonInfo/>}
          {orderInfo.page === STEPS.client_info && <StepClientInfo/>}
          {orderInfo.page === STEPS.payment_booking && <StepPaymentBooking/>}
          {orderInfo.page === STEPS.print && <StepPrint/>}
        </div>
        <div className="trip-app-footer">
          <Button.Panel>
            <Button position={BUTTON_POSITION.LEFT} variant={BUTTON_TYPE.PRIMARY} onClick={handleNextStep} disabled={!isValid}>{t('common:button.next')}</Button>
            <Button position={BUTTON_POSITION.RIGHT} variant={BUTTON_TYPE.SECONDARY} onClick={handleCancel}>{t('common:button.cancel')}</Button>
          </Button.Panel>
        </div>
      </div>

      <Modal show={showConfirmOrder} onHide={handleConfirmOrderClose}>
        <Modal.Header>{t('order:confirm_order.title')}</Modal.Header>
        <Modal.Body>
          <ConfirmOrder/>
        </Modal.Body>
        <Modal.Footer>
          <Button.Panel>
            <Button position={BUTTON_POSITION.LEFT} variant={BUTTON_TYPE.PRIMARY} onClick={handleConfirmOrder}>{t('common:button.alright')}</Button>
            <Button position={BUTTON_POSITION.RIGHT} variant={BUTTON_TYPE.SECONDARY} onClick={handleConfirmOrderClose}>{t('common:button.cancel')}</Button>
          </Button.Panel>
        </Modal.Footer>
      </Modal>

      <Modal show={showPayment} onHide={handlePaymentClose}>
        <Modal.Header>{t('order:payment.title')}. {t(`order:payment_booking.${paymentMethod}`)}</Modal.Header>
        <Modal.Body>
          <Payment type={paymentMethod} onCancel={handlePaymentClose} onSuccess={handlePaymentSuccess} onFail={handlePaymentFail}/>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button.Panel>
            <Button position={BUTTON_POSITION.LEFT} variant={BUTTON_TYPE.PRIMARY} onClick={handlePayment}>{t('common:button.paid')}</Button>
            <Button position={BUTTON_POSITION.RIGHT} variant={BUTTON_TYPE.SECONDARY} onClick={handlePaymentClose}>{t('common:button.cancel')}</Button>
          </Button.Panel> */}
        </Modal.Footer>
      </Modal>

      <Modal show={showPaymentSuccess} onHide={handlePaymentSuccessClose}>
        <Modal.Header>{t('order:order_info.title', {order: orderNumber})}</Modal.Header>
        <Modal.Body>
          <PaymentSuccess amount={paymentAmount} />
        </Modal.Body>
        <Modal.Footer>
          <Button.Panel className="button-panel-center">
            <Button position={BUTTON_POSITION.CENTER} variant={BUTTON_TYPE.PRIMARY} onClick={handlePaymentSuccessClose}>{t('common:button.ok')}</Button>
          </Button.Panel>
        </Modal.Footer>
      </Modal>

      <Modal show={showPaymentFail} onHide={handlePaymentFailClose}>
        <Modal.Header>{t('order:order_info.title', {order: orderNumber})}</Modal.Header>
        <Modal.Body>
          <PaymentFail message={paymentMessage} />
        </Modal.Body>
        <Modal.Footer>
          <Button.Panel className="button-panel-center">
            <Button position={BUTTON_POSITION.CENTER} variant={BUTTON_TYPE.PRIMARY} onClick={handlePaymentFailClose}>{t('common:button.ok')}</Button>
          </Button.Panel>
        </Modal.Footer>
      </Modal>
    </>
  );
};

OrderForm.propTypes = {
  onNext: PropTypes.func,
  onClose: PropTypes.func,
};

export default OrderForm;
import ClassHelper from '../ClassHelper';
import Excursion from '../excursion/Excursion';
import PlaceDetails from '../place/PlaceDetails';
import Schedule from '../schedule/Schedule';
import Transport from '../transport/Transport';
import Route from './Route';

class RouteDetails extends ClassHelper {
  constructor({ _id = null, route, number, date, excursions = [], transports = [], places = []} = {}) {
    super(...arguments);
    this.route = this.ref(route || _id, Route);
    this.number = number;
    this.date = date;
    this.excursions = this.array(excursions, Excursion);
    this.transports = this.array(transports, Transport);
    this.places = this.array(places, PlaceDetails);
  }
}

export default RouteDetails;
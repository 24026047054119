import { useState, createContext, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from './UserProvider';
import { APICode, DynamicPriceType, ExcursionPickupType, ForGroup, PaymentMethod, PriceType, SellerType } from '../models/types';
import useAPI from '../hooks/useAPI';
import API from '../server/api';
import Logger from '../utils/logger';
import Message from '../models/Message';
import Order from '../models/order/Order';
import { RepositoryContext } from './RepositoryProvider';
import { ALERT_TYPE } from '../components/Alert';
import ReportParams from '../models/report/ReportParams';
import UserAccount from '../models/user/UserAccount';
import Report from '../models/report/Report';
import User from '../models/user/User';
import { Step } from '../components/Stepper2';
import OrderSession from '../models/session/OrderSession';
import { STORAGE } from '../defs/storage';
import { getStorageValue, setStorageValue } from '../hooks/useLocalStorage';

export const OrderContext = createContext();

export const STEPS = {
  common_info: 0,
  client_info: 1,
  payment_booking: 2,
  print: 3
};

const initialState = {
  steps: [],
  order: null,
  session: null,
  schedule: null,
  excursion: null,
  payment: {},
  common_errors: [],
  client_errors: [],
  payment_errors: [],
};

const initSteps = (t) => {
  return Object.keys(STEPS).map((step) => new Step({ title: t(`order:steps.${step}`), id: ~~(Math.random() * 10000) }));
};

export const OrderProvider = (props) => {
  const logger = new Logger({ name: 'OrderProvider', showDate: false, showFile: false });
  const {t} = useTranslation(['common', 'order']);

  const [page, setPage] = useState(0);
  const [orderInfo, setOrderInfo] = useState(initialState);
  const [orderSession, setOrderSession] = useState(getStorageValue(STORAGE.ORDER_SESSION));
  const [prices, setPrices] = useState([]);
  const [steps, setSteps] = useState(initSteps(t));
  // const [updated, setUpdated] = useState(initSteps(t));

  const { callApi } = useAPI();

  const userInfo = useContext(UserContext);
  const repository = useContext(RepositoryContext);

  // const update = () => {
  //   setUpdated(new Date().getTime());
  // };

  const nextPage = () => {
    let newPage = page + 1;

    if (newPage <= steps.length && newPage >= 0) {
      steps[page].complete();

      if (newPage < steps.length) {
        steps[newPage].select();
        setPage(newPage);
      }
    }
  };

  const prevPage = () => {
    let newPage = page - 1;

    if (newPage < steps.length && newPage >= 0) {
      setPage(newPage);
    }
  };

  const selectPage = (idx, allComplete) => {
    if (idx < steps.length && idx >= 0) {
      if (allComplete) {
        for (let p = 0; p <= idx; ++p) {
          steps[p].complete();
        }
      }

      setPage(idx);
    }
  };

  const reset = () => {
    setSteps(initSteps(t));
    setPage(0);
    setOrderSession(new OrderSession());
    // update();
  };

  const _setSession = (field, value) => {
    setOrderSession((prevState) => ({ ...prevState, [field]: value }));

    if (orderInfo.session === null || orderInfo.session[field] !== value) {
      let session = new OrderSession({ ...getStorageValue(STORAGE.ORDER_SESSION), [field]: value });
      setStorageValue(STORAGE.ORDER_SESSION, session);
      _setStore('session', session);
    }
  };

  const _useSession = (...args) => {
    const [field] = args;

    useEffect(() => {
      if (args.length > 1) {
        _setSession(field, args[1]);
      } else {
        _setSession(field, orderSession[field]);
      }
    }, []);

    return [orderSession[field], (value) => _setSession(field, value)];
  };


  const _setStore = (field, value) => {
    setOrderInfo((prevState) => ({ ...prevState, [field]: value }));
  };

  const _useStore = (...args) => {
    const [field] = args;

    useEffect(() => {
      if (args.length > 1) {
        _setStore(field, args[1]);
      } else {
        _setStore(field, orderInfo[field]);
      }
    }, []);

    return [orderInfo[field], (value) => _setStore(field, value)];
  };


  const getOrder = () => {
    return orderInfo.order;
  };

  const getOrderInfo = () => {
    return orderInfo;
  };

  
  const getPaymentAmount = () => {
    let amount = 0;
    if (prices.length > 0) {
      amount = orderSession.passengers.reduce((amount, passenger) => {
        let price = prices.find((price) => price.price.id === passenger.price) || { amount: 0 };
        let expectedAmount = price.amount;

        if (orderSession.discount) {
          let needCount = orderSession.discount_forGroups === ForGroup.ALL;
          needCount = needCount || (orderSession.discount_forGroups === ForGroup.ADULTS && price.price.priceType === PriceType.NORMAL);
          needCount = needCount || (orderSession.discount_forGroups === ForGroup.CHILDREN && price.price.priceType === PriceType.CHILD);

          if (needCount) {
            expectedAmount = (orderSession.discount_type === DynamicPriceType.ABSOLUTE ?
              (price.amount - orderSession.discount_value) :
              (price.amount * (1 - orderSession.discount_value / 100).toFixed(2))
            );
          }
        } else {
          expectedAmount = price.partialValue === 0 ? price.amount : price.partialValue;
        }

        return amount + passenger.count * expectedAmount;
      }, 0);
    }

    return amount;
  };  


  const getSeatNumbers = () => {
    let seats = [];

    if (orderInfo.order) {
      seats = orderInfo.order.seats.map((seat) => seat.seats).reduce((seats, seat) => seats.concat(seat), []);
    } else if (orderInfo.schedule) {
      let placeNumber = orderInfo.schedule.stat.totalSeats + 1;
      seats = orderSession.passengers.reduce((seats, passenger) => {
        let price = prices.find((price) => price.price.id === passenger.price) || { amount: 0 };

        if (!price.noSeat) {
          seats.push(placeNumber++);
        }

        return seats;
      }, []);
    }

    return seats;
  };  


  const getTotalAmount = () => {
    return orderSession.passengers.reduce((amount, passenger) => {
      let price = prices.find((price) => price.price.id === passenger.price) || { amount: 0 };
      return amount + passenger.count * price.amount;
    }, 0);
  };


  const addError = (field, code, title, message) => {
    // setOrderInfo((prevState) => ({ ...prevState, [field]: orderInfo[field].push( new Message({ code, title, message }) ) }));
    // update();
  };

  const clearError = (field, uid) => {
    // if (uid) {
    //   setOrderInfo((prevState) => { let idx = prevState[field].findIndex(error => error.uid === uid); if (idx >= 0) prevState[field].splice(idx, 1); return prevState; });
    // } else {
    //   setOrderInfo((prevState) => { prevState[field] = []; return prevState; });
    // }
    // update();
  };

  const _addError = (type, message) => {
    if (!orderInfo[type].find((e) => e.code === message.code)) {
      orderInfo[type].push(message);
    }
  };
  
  const validate = async (step) => {
    switch(step) {
      case STEPS.common_info: {
        orderInfo.common_errors = [];

        if (!orderSession.excursion) {
          _addError('common_errors', new Message({ code: 'excursion_not_set', title: t('order:common_info.error.excursion_not_set'), type: ALERT_TYPE.ERROR }));
        } else {
          let excursion = await repository.getModel('Excursion', orderSession.excursion);

          if (excursion && excursion.passportRequired &&
            orderSession.passport_required && !(orderSession.passengers.reduce((count, passenger) => count + passenger.passports.reduce((count, passport) => count + (passport && passport.fullName && passport.number ? 1 : 0), 0), 0) === orderSession.common_passengers.reduce((count, passenger) => count + passenger.count, 0))
          ) {
            _addError('common_errors', new Message({ code: 'passports_not_set', title: t('order:common_info.error.passports_not_set'), type: ALERT_TYPE.ERROR }));
          }
        }
        if (!orderSession.passengers.length) {
          _addError('common_errors', new Message({ code: 'passengers_not_set', title: t('order:common_info.error.passengers_not_set'), type: ALERT_TYPE.ERROR }));
        }
        if (!orderSession.date) {
          _addError('common_errors', new Message({ code: 'date_not_set', title: t('order:common_info.error.date_not_set'), type: ALERT_TYPE.ERROR }));
        }
        if (!orderSession.time) {
          _addError('common_errors', new Message({ code: 'time_not_set', title: t('order:common_info.error.time_not_set'), type: ALERT_TYPE.ERROR }));
        }
        if (!orderSession.place && !orderSession.address) {
          _addError('common_errors', new Message({ code: 'place_not_set', title: t('order:common_info.error.place_not_set'), type: ALERT_TYPE.ERROR }));
        }

        _setStore('common_errors', orderInfo.common_errors);
        return orderInfo.common_errors;
      }
      case STEPS.client_info: {
        orderInfo.client_errors = [];

        if (!orderSession.user && !(orderSession.phone && orderSession.birthday && orderSession.first_name && orderSession.last_name)) {
          orderInfo.client_errors.push(new Message({ code: 'client_not_set', title: t('order:client_info.error.client_not_set'), type: ALERT_TYPE.ERROR }));
        }

        _setStore('client_errors', orderInfo.client_errors);
        return orderInfo.client_errors;
      }
      case STEPS.payment_booking: {
        orderInfo.payment_errors = [];
        _setStore('payment_errors', orderInfo.payment_errors);
        return orderInfo.payment_errors;
      }
      default: {}
    }
    return [];
  };


  const createOrder = () => {
    return new Promise(async (resolve, reject) => {
      let subdivision = userInfo.getSession().subdivision;
      let passports = [];

      orderSession.passengers.forEach((passenger) => {
        Object.values(passenger.passports).forEach((passport) => {
          passports.push({ number: passport.number, fullName: passport.fullName, rewardPartner: passenger.rewardPartner || 0 });
        });
      });

      if (subdivision) {
        repository.request(API.order.createOrder, {
          user: orderSession.user,
          userInfo: {
            phone: orderSession.phone,
            name: `${orderSession.first_name} ${orderSession.last_name} ${orderSession.middle_name}`.trim()
          },
          excursion: orderSession.excursion,
          subdivision: orderSession.subdivision,
          schedule: orderSession.schedule,
          passengers: orderSession.passengers.map((passenger) => ({ price: passenger.price, count: passenger.count, ages: Object.values(passenger.ages) || [], rewardPartner: passenger.rewardPartner })),
          sellerType: SellerType.CASHIER,
          pointId: userInfo.getSession().point,
          paymentMethod: orderSession.payment_method || PaymentMethod.CASH,
          contactPhones: [ ...orderSession.contact_phones ],
          wishes: orderSession.wishes,
          place: orderInfo.excursion && orderInfo.excursion.pickupType === ExcursionPickupType.PLACE ? orderSession.place : undefined,
          address: orderInfo.excursion && orderInfo.excursion.pickupType === ExcursionPickupType.HOME ? orderSession.address : undefined,
          passports: passports,
          comment: orderSession.comment,
          discountId: orderSession.discount,
        }, Order).then((order) => {
          _setStore('order', order);
          if (order) {
            _setSession('order', order.id);
            _setSession('number', order.number);
            resolve(order);
          } else {
            reject()
          }
        }).catch((e) => {
          addError('client_errors', 'select_excursion', t('order:common_info.error.select_excursion'), e.message);
          logger.error('New Order error:', e);
          reject(e);
        });
      } else {
        logger.error('New Order subdivision is null');
        reject({ error: 'subdivision_is_null' });
      }
    });
  };

  const isNewPhone = async () => {
    if (orderSession.phone) {
      let response = await callApi(API.auth.checkPhone, { phone: orderSession.phone });
      return response.data === APICode.USER_NOT_FOUND;
    }
    return false;
  };

  const inviteUser = async () => {
    if (orderSession.phone) {
      let response = await callApi(API.order.createClient, {
        phone: orderSession.phone,
        firstName: orderSession.first_name,
        lastName: orderSession.last_name,
        middleName: orderSession.middle_name,
        birthday: orderSession.birthday
      });
      if (response.error) {
        return response;
      } else {
        let user = new User(response.data);
        return user;
      }
    }

    return false;
  };

  const getUserAccount = async () => {
    if (orderSession.phone) {
      let response = await callApi(API.account.users, new ReportParams({ filters: { phone: orderSession.phone } }));
      if (response.error) {
        return response;
      } else {
        let report = new Report(response.data, UserAccount);
        let userAccountList = report.rows;
        let account = null;
        if (userAccountList.length) {
          account = userAccountList[0];
        }
        return account;
      }
    }
    return false;
  };

  const askUser = async (accountId) => {
    if (accountId) {
      let response = await callApi(API.user.user, { id: accountId });
      if (response.error) {
        return response;
      } else {
        let user = new User(response.data);
        return user;
      }
    }
    return false;
  };


  const getSession = () => {
    return orderInfo.session || getStorageValue(STORAGE.ORDER_SESSION, orderSession, OrderSession);
  };

  const clearSession = () => {
    setOrderSession({...(new OrderSession())});
  };


  useEffect(() => {
    const orderSession = getStorageValue(STORAGE.ORDER_SESSION, null, OrderSession);

    for (let field in orderSession) {
      _setSession(field, orderSession[field]);
    }
  }, []);


  return (
    <OrderContext.Provider
      value={{
        page,
        steps,
        nextPage,
        prevPage,
        reset,
        setPage: selectPage,

        useSession: _useSession,
        useState: _useStore,

        getOrder,
        getOrderInfo,

        getSession,
        clearSession,

        addError,
        clearError,

        getPaymentAmount,
        getTotalAmount,
        getSeatNumbers,

        validate,
        setPrices,

        createOrder,
        isNewPhone,
        inviteUser,
        getUserAccount,
        askUser
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};

import { useState, useCallback, useEffect, Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import BackBar from '../../components/BackBar';
import QRCode from '../../components/QRCode';
import Button, { BUTTON_POSITION, BUTTON_TYPE } from '../../components/Button';
import Table, { ALIGN_TABLE } from '../../components/Table';
import InfoDescription from '../../components/InfoDescription';
import Price, { CURRENCY } from '../../components/Price';
import Badge, { BADGE_TYPE } from '../../components/Badge';
import Checkbox from '../../components/Checkbox';
import Modal from '../../components/Modal';
import TicketInput from './ticket_input';
import TicketView from './ticket_view';
import { RepositoryContext } from '../../store/RepositoryProvider';
import API from '../../server/api';
import ReportParams from '../../models/report/ReportParams';
import { PriceType } from '../../models/types';
import Phone from '../../components/Phone';
import log from '../../utils/logger';
import Input from '../../components/Input';
import ErrorHint from '../../components/ErrorHint';

const Place = ({ place, schedule, onNextStop }) => {
  const {t} = useTranslation(['common', 'route']);

  const repository = useContext(RepositoryContext);

  const [showTicketInputModal, setShowTicketInputModal] = useState(false);
  const [showTicketViewModal, setShowTicketViewModal] = useState(false);

  const [tickets, setTickets] = useState([]);
  const [ticket, setTicket] = useState(null);
  const [ticketNumber, setTicketNumber] = useState('');
  const [newSeats, setNewSeats] = useState([]);
  const [error, setError] = useState(null);

  const handleNextStop = () => {
    onNextStop && onNextStop();
  };

  const handleCloseTicketInputModal = useCallback(() => {
    setShowTicketInputModal(false);
  }, []);

  const handleCloseTicketViewModal = useCallback(() => {
    setShowTicketViewModal(false);
  }, []);

  const selectTicket = (ticket) => {
    setTicket(ticket);
    setShowTicketViewModal(true);
  }

  const handleSearchTicket = () => {
    const ticket = tickets.find((order) => order.number === ticketNumber);
    if (ticket) {
      setTicket(ticket);
      setShowTicketInputModal(true);
      setError('');
    } else {
      setError(t('ticket:ticket.ticket_not_found'));
    }
  };

  const handleBoardTicket = () => {
    repository.request(API.guide.seat, {orderId: ticket.id, ...newSeats}).then(() => {
      loadTickets();
    });

    setTicket(null);
    setShowTicketViewModal(false);
  };

  const loadTickets = () => {
    let _tickets = [];

    repository.list(API.guide.schedule, { scheduleId: schedule.id }).then((orders) => {
      orders.forEach((order) => {
        order.count = {
          adult: 0,
          child: 0,
          noseat: 0,
          count: 0,
          actualCount: 0,
        };

        order.prices = [];
        order.leftToPay = 0;

        order.passengers.forEach((price) => {
          order.leftToPay += price.actualPrice.leftToPay * price.count;
          order.prices.push(price);

          if (price.price.priceType === PriceType.NORMAL) {
            order.count.adult += price.count;
            order.count.count += price.count;
            order.count.actualCount += price.actualCount;
          }

          if (price.price.priceType === PriceType.CHILD) {
            if (price.price.noSeat) {
              order.count.noseat += price.count;
            } else {
              order.count.child += price.count;
              order.count.count += price.count;
              order.count.actualCount += price.actualCount;
            }
          }
        });

        _tickets.push(order);
      });
      setTickets(_tickets);
      setTicket(null);
    }).catch(() => {
      setTickets([]);
      setTicket(null);
    });
  };

  useEffect(() => {
    loadTickets();
  }, []);

  return (
    <div>
      <Button variant={BUTTON_TYPE.SECONDARY} position={BUTTON_POSITION.NONE} onClick={handleNextStop}>{t('route:button.next_stop')}</Button>

      <div className="field mt-3">
        <div className="field-label">
          {t('route:place.scan_qr')}
        </div>
        <QRCode.Scanner
          onResult={(result, error, reader) => {
            if (result) {
              const data = JSON.parse(result);
              reader.stop();

              if (data && data.order) {
                const scannedTicket = tickets.find((ticket) => ticket.id === data.order);
                if (scannedTicket) {
                  setTicket(scannedTicket);
                  setTicketNumber(scannedTicket.number);
                } else {
                  repository.request(API.order.order, {id: data.order}).then((order) => {
                    setTicket(order);
                    setTicketNumber(order.number);
                    setError('');
                  }).catch((e) => {
                    setTicket(null);
                    setTicketNumber('');
                    setError(t('route:place.error.invalid_order'));
                  });
                  repository.list(API.guide.schedule, { scheduleId: data.schedule }).catch(console.error);
                }
              } else {
                setTicket(null);
                setTicketNumber('');
                setError(t('route:place.error.invalid_qr_code'));
              }
            }

            if (error) {
              log.error(error);
            }
          }}
        />
      </div>

      <div className="field">
        <div className="input">
          <div className="field-label">
            {t('route:place.input_number')}
          </div>
          <Table cols={2}>
            <Table.Row className="pt-0">
              <Table.Cell className="pe-3">
                <Input type="number" value={ticketNumber} onChange={(value) => {
                  setTicketNumber(parseInt(value));
                }}/>
              </Table.Cell>
              <Table.Cell>
                <Button variant={BUTTON_TYPE.SECONDARY} onClick={handleSearchTicket}>{t('route:button.find')}</Button>
              </Table.Cell>
            </Table.Row>
            <Table.Row colspan={2}>
              <ErrorHint error={error} />
            </Table.Row>
          </Table>
        </div>
      </div>

      <div className="field-label">{t('route:place.tickets')} — {place.title}</div>

      <Table cols={4}>
        {tickets.filter((ticket) => ticket.place.id === place.id).map((ticket) => (
          <Fragment key={ticket.number}>
            <Table.Row onClick={() => selectTicket(ticket)}>
              <Table.Cell colspan={2}>
                <Checkbox.Result checked={ticket.count.count === ticket.count.actualCount} />
                {t('route:place.ticket', { number: ticket.number })}
              </Table.Cell>
              <Table.Cell colspan={2} align={ALIGN_TABLE.RIGHT}>
                <Badge type={BADGE_TYPE.INFO}><Phone value={ticket.user.phone} /></Badge>
              </Table.Cell>
            </Table.Row>
            <Table.Row onClick={() => selectTicket(ticket)}>
              <Table.Cell colspan={2}>{ticket.excursion.title}</Table.Cell>
              <Table.Cell colspan={2} align={ALIGN_TABLE.RIGHT}>
                <Badge type={BADGE_TYPE.INFO}>{ticket.user.profile.fullName}</Badge>
              </Table.Cell>
            </Table.Row>
            <Table.Row onClick={() => selectTicket(ticket)}>
              <Table.Cell>
                <InfoDescription description={t('route:place.seat.adult')}>
                  {ticket.count.adult}
                </InfoDescription>
              </Table.Cell>
              <Table.Cell>
                <InfoDescription description={t('route:place.seat.child')}>
                  {ticket.count.child}
                </InfoDescription>
              </Table.Cell>
              <Table.Cell>
                <InfoDescription description={t('route:place.seat.noseat')}>
                  {ticket.count.noseat}
                </InfoDescription>
              </Table.Cell>
              <Table.Cell>
                <InfoDescription description={t('route:place.seats')}>
                  {ticket.seats.map((transport) => (
                    <div>{transport.seats.sort().toString()}</div>
                  ))}
                </InfoDescription>
              </Table.Cell>
            </Table.Row>
            <Table.Row delim={true} />
          </Fragment>
        ))}
      </Table>

      <Modal show={showTicketInputModal} onHide={handleCloseTicketInputModal}>
        <Modal.Header closeButton>
          <BackBar title={t('route:ticket_input.title', { number: ticket && ticket.number })}/>
        </Modal.Header>
        <Modal.Body>
          {ticket && (
            <TicketInput ticket={ticket}/>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseTicketInputModal}>{t('common:button.close')}</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTicketViewModal} onHide={handleCloseTicketViewModal}>
        <Modal.Header closeButton>
          {ticket && (
            <BackBar title={t('route:ticket_view.title', { number: ticket.number })}/>
          )}
        </Modal.Header>
        <Modal.Body>
          {ticket && (
            <TicketView ticket={ticket} schedule={schedule} place={place} onChange={(transport, seats) => {
              setNewSeats({transport, seats});
            }}/>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleBoardTicket}>{t('route:button.board')}</Button>
          <Button onClick={handleCloseTicketViewModal}>{t('common:button.close')}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

Place.propTypes = {
  onNextStop: PropTypes.func,
  place: PropTypes.any.isRequired,
  schedule: PropTypes.any.isRequired,
};

export default Place;
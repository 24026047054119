class OrderSession {
  constructor({
    schedule = '', excursion = '', subdivision = '',
    passengers = [], passport_required = false, wishes = [], place = '', address = '', comment = '', date = null, time = null,
    name = '', first_name = '', last_name = '', middle_name = '', birthday = '', phone = '', user = '', passports = [], contact_phones = [],
    discount = '', discount_value = 0, discount_type = '', discount_appliesTo = '', discount_forGroups = '',
    payment_method = '', payment_comment = '',
    order = '', number = ''
  } = {}) {
    this.schedule = schedule;
    this.excursion = excursion;
    this.subdivision = subdivision;

    this.passengers = passengers;
    this.passport_required = passport_required;
    this.wishes = wishes;
    this.place = place;
    this.address = address;
    this.comment = comment;
    this.date = date;
    this.time = time;

    this.name = name;
    this.first_name = first_name;
    this.last_name = last_name;
    this.middle_name = middle_name;
    this.birthday = birthday;
    this.phone = phone;
    this.user = user;
    this.passports = passports;
    this.contact_phones = contact_phones;

    this.discount = discount;
    this.discount_value = discount_value;
    this.discount_type = discount_type;
    this.discount_appliesTo = discount_appliesTo;
    this.discount_forGroups = discount_forGroups;

    this.payment_method = payment_method;
    this.payment_comment = payment_comment;

    this.order = order;
    this.number = number;
  }

  getName() {
    return `${this.first_name} ${this.last_name} ${this.middle_name}`.trim();
  }

  isSet() {
    return !!this.schedule && !!this.excursion && !!this.date && !!this.time;
  }
}

export default OrderSession;
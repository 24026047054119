import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Field from '../../components/Field';
import SelectAutocomplete from '../../components/SelectAutocomplete';
import { RepositoryContext } from '../../store/RepositoryProvider';
import ReportParams from '../../models/report/ReportParams';
import API from '../../server/api';
import Input from '../../components/Input';
import Alert, { ALERT_TYPE } from '../../components/Alert';
import Button, { BUTTON_POSITION, BUTTON_SIZE, BUTTON_TYPE } from '../../components/Button';

export const REMOVE_FROM_SCHEDULE_STEP = {
  DATA: 0,
  CONFIRM: 1,
  first: () => {
    return REMOVE_FROM_SCHEDULE_STEP.DATA;
  },
  next: (step) => {
    return step === REMOVE_FROM_SCHEDULE_STEP.last() ? REMOVE_FROM_SCHEDULE_STEP.last() : (step + 1);
  },
  back: (step) => {
    return step === REMOVE_FROM_SCHEDULE_STEP.first() ? REMOVE_FROM_SCHEDULE_STEP.first() : (step - 1);
  },
  last: () => {
    return REMOVE_FROM_SCHEDULE_STEP.CONFIRM;
  }
};

const RemoveFromSchedule = ({order, onNext, onBack, onClose, onDone}) => {
  const {t} = useTranslation(['common', 'order']);

  const [fieldStaff, setFieldStaff] = useState('');
  const [fieldReason, setFieldReason] = useState('');
  const [step, setStep] = useState(REMOVE_FROM_SCHEDULE_STEP.first());
  
  const repository = useContext(RepositoryContext);

  const handleUserList = (query) => {
    return repository.report(API.account.users, new ReportParams({ filters: { 'profile.fullName': query } })).then((report) => {
      return report.rows;
    }).catch(() => {
      return [];
    });
  };

  const handleNext = () => {
    setStep(REMOVE_FROM_SCHEDULE_STEP.next(step));
    (typeof onNext === 'function') && onNext();
  };

  const handleBack = () => {
    setStep(REMOVE_FROM_SCHEDULE_STEP.back(step));
    (typeof onBack === 'function') && onBack();
  };

  const handleClose = () => {
    (typeof onClose === 'function') && onClose();
  };

  const handleDone = () => {
    (typeof onDone === 'function') && onDone();
  };

  useEffect(() => {
    setStep(REMOVE_FROM_SCHEDULE_STEP.first());
  }, []);

  return (
    <div className="trip-app">
      <div className="trip-app-body">
        {(step === REMOVE_FROM_SCHEDULE_STEP.DATA) && (
          <>
            <Field title={t('order:remove_from_schedule.staff')}>
              <SelectAutocomplete
                apiHandler={(query) => {
                  return handleUserList(query);
                }}
                template={(row) => row}
                value={fieldStaff}
                onChange={setFieldStaff}
                getValue={(user) => user.profile.fullName}
              />
            </Field>
            <Field title={t('order:remove_from_schedule.reason')}>
              <Input value={fieldReason} onChange={setFieldReason} />
            </Field>
          </>
        )}
        {(step === REMOVE_FROM_SCHEDULE_STEP.CONFIRM) && (
          <>
            <Alert type={ALERT_TYPE.WARN} title={t('order:remove_from_schedule.confirm')}>{t('order:remove_from_schedule.confirm_desc')}</Alert>
            <Field title={t('order:remove_from_schedule.staff')}>
              {fieldStaff}
            </Field>
            <Field title={t('order:remove_from_schedule.status')}>
              {t(`common:payment_status.${order.status}`)} {'->'} {t('order:remove_from_schedule.calc_state', { payment: t(`common:payment_status.${order.status}`), order: t('order:remove_from_schedule.awaiting_assignment')})}
            </Field>
            <Field title={t('order:remove_from_schedule.reason')}>
              {fieldReason}
            </Field>
          </>
        )}
      </div>
      <div className='trip-app-footer'>
        <Button.Panel>
          <Button size={BUTTON_SIZE.VW30} variant={BUTTON_TYPE.PRIMARY} position={BUTTON_POSITION.FLOAT} onClick={handleNext}>{t(`common:button.${REMOVE_FROM_SCHEDULE_STEP.last() === step ? 'confirm_save' : 'continue'}`)}</Button>
          <Button size={BUTTON_SIZE.AUTO} variant={BUTTON_TYPE.SECONDARY} position={BUTTON_POSITION.FLOAT} onClick={handleBack}>{t('common:button.back')}</Button>
          <Button size={BUTTON_SIZE.AUTO} variant={BUTTON_TYPE.SECONDARY} position={BUTTON_POSITION.FLOAT} onClick={handleClose}>{t('common:button.close')}</Button>
        </Button.Panel>
      </div>
    </div>
  )
}

RemoveFromSchedule.propTypes = {
  order: PropTypes.object.isRequired,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  onDone: PropTypes.func
};

export default RemoveFromSchedule;
import { ClassTimestampsHelper } from '../ClassHelper';
import Account from '../account/Account';
import User from '../user/User';

class Collection extends ClassTimestampsHelper {
  constructor({ account, user, collector, date, amount } = {}) {
    super(...arguments);
    this.account = this.ref(account, Account);
    this.user = this.obj(user, User);
    this.collector = this.obj(collector, User);
    this.date = this.obj(date, Date);
    this.amount = amount;
  }
}

export default Collection;
import { ClassTimestampsHelper } from '../ClassHelper';
import Account from '../account/Account';
import Category from '../category/Category';
import ExcursionFaq from './ExcursionFaq';
import ExcursionSeo from './ExcursionSeo';
import ExcursionSubdivision from './ExcursionSubdivision';
import File from '../file/File';
import FreeService from '../freeService/FreeService';
import Moderation from '../moderation/Moderation';
import ExcursionPrint from './ExcursionPrint';
import Image from '../file/Image';

class Excursion extends ClassTimestampsHelper {
  constructor({ account, type, pickupType, title, description, url, categories, freeServices, canBeCancelledBefore, seo, cover, video, images,
    subdivisions, faq, rating, passportRequired, publishingRequired, usingInsideAccount, status, moderation, publishedAt, print
  } = {}) {
    super(...arguments);
    this.account = this.ref(account, Account);
    this.type = type; // ExcursionType
    this.pickupType = pickupType; // ExcursionPickupType
    this.title = title;
    this.description = description;
    this.url = url;
    this.categories = this.array(categories, Category, true);
    this.freeServices = this.array(freeServices, FreeService, true);
    this.canBeCancelledBefore = canBeCancelledBefore;
    this.seo = this.obj(seo, ExcursionSeo);
    this.cover = this.obj(cover, Image);
    this.video = this.obj(video, File);
    this.images = this.array(images, Image);
    this.subdivisions = this.array(subdivisions, ExcursionSubdivision);
    this.faq = this.obj(faq, ExcursionFaq);
    this.rating = rating;
    this.passportRequired = passportRequired;
    this.publishingRequired = publishingRequired;
    this.usingInsideAccount = usingInsideAccount;
    this.status = status; // Status
    this.print = this.obj(print, ExcursionPrint);
    this.moderation = this.obj(moderation, Moderation);
    this.publishedAt = this.obj(publishedAt, Date);
  }
}

export default Excursion;
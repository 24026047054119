import { useState, createContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Step } from '../components/Stepper2';

export const RefundContext = createContext();

export const STEPS = {
  order_info: 0,
  refund_info: 1,
  confirm_refund: 2
};

const initialState = () => ({
  orderId: null,
  orderNumber: '',
  orderTitle: null,
  orderPaymentMethod: null,
  orderStatus: null,
  orderPassengers: [],
  orderPoint: null,
  orderAmount: 0,
  orderSeller: null,

  refundMethod: '',
  useRule: false,
  refundAmount: '',
  rule: null,
  reason: '',
  files: [],
});

const initSteps = (t) => {
  return Object.keys(STEPS).map((step) => new Step({ title: t(`refund:steps.${step}`), id: ~~(Math.random() * 10000) }));
};

export const RefundProvider = (props) => {
  const {t} = useTranslation(['common', 'refund']);

  const [page, setPage] = useState(0);
  const [refundInfo, setRefundInfo] = useState(initialState());
  const [steps] = useState(initSteps(t));

  const nextPage = () => {
    let newPage = page + 1;
    if (newPage <= steps.length && newPage >= 0) {
      steps[page].complete();
      if (newPage < steps.length) {
        steps[newPage].select();
        setPage(newPage);
      }
    }
  };

  const prevPage = () => {
    let newPage = page - 1;
    if (newPage < steps.length && newPage >= 0) {
      setPage(newPage);
    }
  };

  const setOrder = (order = {}) => {
    setRefundInfo((prevState) => ({ ...prevState,
      orderId: order ? order.id : null,
      orderTitle: order ? order.excursion.title : null,
      orderPaymentMethod: order && order.paymentDetails ? order.paymentDetails.paymentMethod : null,
      orderStatus: order ? order.status : null,
      orderPassengers: order ? order.passengers : [],
      orderPoint: order ? order.point : null,
      orderAmount: order && order.paymentDetails ? order.paymentDetails.paidAmount : 0,
      orderSeller: order && order.seller ? order.seller.user : null,
      orderNumber: order ? order.number : null,
    }));
  };

  const getOrder = () => {
    return refundInfo;
  };

  const _setState = (field, value) => {
    setRefundInfo((prevState) => ({ ...prevState, [field]: value }));
  };

  const _useState = (...args) => {
    const [field] = args;

    useEffect(() => {
      if (args.length > 1) {
        _setState(field, args[1]);
      } else {
        _setState(field, refundInfo[field]);
      }
    }, []);

    return [refundInfo[field], (value) => _setState(field, value)];
  };

  const resetOrder = () => {
    setRefundInfo((prevState) => ({ ...prevState,
      ...refundInfo,
      orderId: null,
      orderTitle: null,
      orderPaymentMethod: null,
      orderStatus: null,
      orderPassengers: [],
      orderPoint: null,
      orderAmount: 0,
      orderSeller: null,
      orderNumber: null,
    }));
  };

  return (
    <RefundContext.Provider
      value={{
        page,
        steps,
        nextPage,
        prevPage,
        setPage,
        setOrder,
        getOrder,
        useState: _useState,
        resetOrder
      }}
    >
      {props.children}
    </RefundContext.Provider>
  );
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, getCountries, isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input/input'
import ErrorHint from './ErrorHint';

export const PHONE_FORMAT_COUNTRY = getCountries().reduce((acc, country) => { acc[country] = country; return acc; }, {});

const Phone = ({ value = 0, className = '', international = true, brackets = true }) => {
  let phone = international ? formatPhoneNumberIntl(value) : formatPhoneNumber(value);
  let reg = /[+]*(\d+)[ (]+(\d+)[ )]+(\d+)[ -](\d+)[ -](\d+)/;

  if (brackets && international) {
    if (reg.test(phone)) {
      const m = phone.match(reg);
      phone = `+${m[1]} (${m[2]}) ${m[3]}-${m[4]}-${m[5]}`;
    }
  } else if (!brackets && !international) {
    if (reg.test(phone)) {
      const m = phone.match(reg);
      phone = `${m[1]} ${m[2]} ${m[3]} ${m[4]} ${m[5]}`;
    }
  }

  return (
    <span className={`phone ${className}`}>{phone}</span>
  );
};

Phone.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  international: PropTypes.bool,
  brackets: PropTypes.bool
};

const Input = ({ className = '', name, label, placeholder, error, value = '', onInput, onChange, onEnter, country = PHONE_FORMAT_COUNTRY.RU, reff }) => {
  const [phone, setPhone] = useState('');

  const parsePhone = (phone) => {
    let parsed = parsePhoneNumber(phone, country);
    return parsed ? parsed.number : phone;
  };

  useEffect(() => {
    const validPhone = value ? (isValidPhoneNumber(value) ? value : parsePhone(value)) : '';
    setPhone(validPhone);
    value && onChange && onChange(validPhone);
  }, []);

  useEffect(() => {
    const validPhone = value ? (isValidPhoneNumber(value) ? value : parsePhone(value)) : '';
    setPhone(validPhone);
  }, [value]);

  return (
    <>
      {label && (
        <label className="field-label">{label}</label>
      )}
      <PhoneInput className={`text ${className}`} value={phone} defaultCountry={country} placeholder={placeholder} name={name}
        onInput={(e) => {
          onInput && onInput(parsePhone(e.target.value));
        }}
        ref={reff}
        onChange={(val) => {
          onChange && onChange(val);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && typeof onEnter === 'function') {
            e && e.preventDefault();
            onEnter && onEnter(e.target.value.trim());
            onChange && onChange(parsePhone(e.target.value));
          }
        }}
      />
      {error && (
        <ErrorHint error={error} />
      )}
    </>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  country: PropTypes.string,
  onInput: PropTypes.func,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  reff: PropTypes.any
};

Phone.Input = Input;

const Text = ({ phone, international = true, brackets = true }) => {
  phone = international ? formatPhoneNumberIntl(phone) : formatPhoneNumber(phone);
  let reg = /[+]*(\d+)[ (]+(\d+)[ )]+(\d+)[ -](\d+)[ -](\d+)/;

  if (brackets && international) {
    if (reg.test(phone)) {
      const m = phone.match(reg);
      phone = `+${m[1]} (${m[2]}) ${m[3]}-${m[4]}-${m[5]}`;
    }
  } else if (!brackets && !international) {
    if (reg.test(phone)) {
      const m = phone.match(reg);
      phone = `${m[1]} ${m[2]} ${m[3]} ${m[4]} ${m[5]}`;
    }
  }

  return formatPhoneNumberIntl(phone);
};

Text.propTypes = {
  phone: PropTypes.string,
  international: PropTypes.bool,
  brackets: PropTypes.bool
};

Phone.Text = Text;

const Call = ({ value = 0, className = '', international = true, brackets = true }) => {
  return (
    <a href={`tel:${value}`} className={`phone-call ${className}`}><Phone value={value} className={className} international={international} brackets={brackets}/></a>
  );
};

Call.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  international: PropTypes.bool,
  brackets: PropTypes.bool
};

Phone.Call = Call;

Phone.validate = (value) => {
  return isValidPhoneNumber(value);
};

export default Phone;
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

export const ALIGN_DROPDOWN = {
  LEFT: 'left',
  RIGHT: 'right'
}

const Dropdown = ({ className = '', title, control, show = false, setShow = () => {}, align = ALIGN_DROPDOWN.LEFT, children, ...otherProps }) => {
  const [showDropdown, setShowDropdown] = useState(show);
  const dropdownButtonRef = useRef(false);

  const ref = useRef('');

  const closeDropdown = (e) => {
    if (!e || (
      e.target.closest('.dropdown-item') ||
      e.target.classList.contains('dropdown-item')
    ) || (
      (
        e.target.classList.contains('dropdown-btn') &&
        dropdownButtonRef.current !== e.target
      ) || (
        e.target.classList.contains('dropdown-icon') &&
        dropdownButtonRef.current !== e.target
      ) || (
        e.target.closest('.dropdown-icon') &&
        dropdownButtonRef.current !== e.target.closest('.dropdown-icon')
      )
    ) || !(
      e.target.closest('.dropdown') ||
      e.target.classList.contains('dropdown') ||
      e.target.closest('.dropdown-icon') ||
      e.target.classList.contains('dropdown-icon') ||
      e.target.closest('.dropdown-btn') ||
      e.target.classList.contains('dropdown-btn')
    )) {
      handleShow(false);
    }
  };

  const handleShow = (show) => {
    setShowDropdown(show);
    setShow(show);
  };

  useEffect(() => {
    document.addEventListener('click', closeDropdown);

    return () => {
      document.removeEventListener('click', closeDropdown);
    };
  }, []);

  useEffect(() => {
    setShowDropdown(show);
  }, [show]);

  return (
    <div ref={ref} className={`dropdown ${className} dropdown-${align}`} {...otherProps}>
      {(typeof title === 'string') && (
        <button ref={dropdownButtonRef} onClick={() => handleShow(!showDropdown)} className='dropdown-btn'>{title}</button>
      )}
      {(!control && typeof title === 'object') && (
        <span ref={dropdownButtonRef} onClick={() => handleShow(!showDropdown)} className='dropdown-icon'>{title}</span>
      )}
      {control}
      <div className={`dropdown-content ${showDropdown ? 'show' : ''}`}>
        {children}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  setShow: PropTypes.func,
  align: PropTypes.oneOf(Object.values(ALIGN_DROPDOWN)),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  control: PropTypes.element
};

const Item = ({ className = '', onClick, children, ...otherProps }) => {
  return (
    <span className={`dropdown-item ${className}`} onClick={onClick} {...otherProps}>
      {children}
    </span>
  );
};

Item.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

Dropdown.Item = Item;

export default Dropdown;
import { useTranslation } from 'react-i18next';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import NavBar from '../../components/NavBar';
import { RepositoryContext } from '../../store/RepositoryProvider';
import DateFormat from '../../components/DateFormat';
import API from '../../server/api';
import Table, { ALIGN_TABLE } from '../../components/Table';
import InfoDescription from '../../components/InfoDescription';
import SelectDate, { DAY_PICKER_MODE } from '../../components/SelectDate';
import log from '../../utils/logger';
import Phone from '../../components/Phone';

const Boarding = () => {
  const {t} = useTranslation(['common', 'boarding']);

  const repository = useContext(RepositoryContext);

  const [filteredPassengers, setFilteredPassengers] = useState([]);
  const [orders, setOrders] = useState({});
  const [date, setDate] = useState(new Date());

  const loadPassengers = useCallback(async () => {
    repository.list(API.cashier.passengers, {
      date: DateFormat.Request({ date })
    }).then((passengers) => {

    // filteredPassengers:
    // [
    //   {
    //     time: string,
    //     excursions: [
    //       {
    //         id: string,
    //         title: string,
    //         places: [
    //           {
    //             id: string,
    //             title: string,
    //             address: string,
    //             total: number,
    //             seats: number,
    //             transports: [
    //               {
    //                 id: string,
    //                 title: string,
    //                 number: string,
    //                 passengers: [
    //                   {
    //                     phone: string,
    //                     name: string,
    //                     order: string,
    //                     seats: {
    //                       count: number,
    //                       noSeats: number,
    //                       seats: [number]
    //                     },
    //                     checked: boolean
    //                   }
    //                 ]
    //               }
    //             ]
    //           }
    //         ]
    //       }
    //     ]
    //   }
    // ]

    // orders:
    // {
    //   [id]: {
    //     transports: [
    //       {
    //         id: string,
    //         title: string,
    //         number: string
    //         seats: number
    //       }
    //     ]
    //   }
    // }

      const _orders = {};
      let boarding = [];
      for (let record of passengers) {
        let timeBoard = boarding.find((t) => t.time === record.time);
        if (!timeBoard) {
          let rg = record.time.match(/(\d{1,2}):(\d{1,2})/);
          timeBoard = {
            time: record.time,
            date: record.date,
            minutes: Number(rg[1]) * 60 + Number(rg[2]),
            excursions: []
          };
          boarding.push(timeBoard);
        }

        let excursion = timeBoard.excursions.find((e) => e.id === record.excursion.id);
        if (!excursion) {
          excursion = {
            id: record.excursion.id,
            title: record.excursion.title,
            places: []
          };
          timeBoard.excursions.push(excursion);
        }

        let placePassengers = passengers.filter((p) => p.place.id === record.place.id);
        let place = excursion.places.find((p) => p.id === record.place.id);

        if (!place) {
          let seatsCount = placePassengers.reduce(({total, seat}, p) => ({total: p.passengers.seats, seat: p.checked ? p.passengers.seats : 0}), {total: 0, seat: 0});
          place = {
            id: record.place.id,
            title: record.place.title,
            address: record.place.address,
            total: seatsCount.total,
            seat: seatsCount.seat,
            transports: []
          };
          excursion.places.push(place);
        }

        for (let seat of record.seats) {
          const transportId = seat.transport ? seat.transport.id : 'none';
          let transport = place.transports.find((t) => t.id === transportId);
          if (!transport) {
            transport = {
              id: transportId,
              title: seat.transport ? seat.transport.title : null,
              number: seat.transport ? seat.transport.number : null,
              passengers: []
            };
            place.transports.push(transport);
          }

          let passenger = transport.passengers.find((p) => p.order === record.user.order);
          if (!passenger) {
            passenger = {
              phone: record.user.phone,
              name: record.user.name,
              order: record.order,
              seat: {
                seats: seat.seats,
                count: record.passengers.seats,
                noSeats: record.passengers.noSeats
              },
              checked: record.checked
            };
            transport.passengers.push(passenger);
          }

          _orders[record.order] = _orders[record.order] || {
            transports: []
          };
          _orders[record.order].transports.push({
            id: seat.transport ? seat.transport.id : 'none',
            title: seat.transport ? seat.transport.title : null,
            number: seat.transport ? seat.transport.number : null,
            seats: seat.seats ? seat.seats.length : 0
          });

        }
      }

      boarding.sort((a, b) => a.minutes - b.minutes);

      setOrders(_orders);
      setFilteredPassengers(boarding);
    }).catch((error) => {
      log.error(error);
      setOrders({});
      setFilteredPassengers([]);
    });
  }, [date]);


  const seatPassengers = useCallback(async (seat, order, place) => {
    repository.action(API.cashier.setPassengers, {
      seat: !!seat,
      order: order,
      place: place
    }).then(() => {
    }).catch((error) => {
      log.error(error);
    });
  }, [filteredPassengers]);

  useEffect(() => {
    loadPassengers();
  }, [date]);

  useEffect(() => {
    let now = new Date();
    setDate(now);
  }, []);


  return (
    <div className="trip-app">
      <NavBar title={t('boarding:boarding.title')} />
      <div className="trip-app-body">
       <div className="field">
         <div className="field-label">{t('boarding:boarding.date')}</div>
         <SelectDate selected={date} mode={DAY_PICKER_MODE.SINGLE} onChange={setDate} />
       </div>
        <Table className="boarding-table" cols={7}>
          {filteredPassengers.map((time, d) => (
            <Fragment key={d}>
              {time.excursions.map((excursion, e) => (
                <Fragment key={e}>
                  {excursion.places.map((place, p) => (
                    <Fragment key={p}>
                      <Table.Row>
                        <Table.Cell className="boarding-table_time align-right">{time.time}</Table.Cell>
                        <Table.Cell colspan={6}>
                          <div className="boarding-table_ex">
                            {excursion.title}
                          </div>
                          <div className="boarding-table_place">
                            {place.title}
                          </div>
                          <div className="badge badge-warning mb-1 mt-1">
                            {t('boarding:boarding.come', { total: place.total, count: place.seat })}
                          </div>
                        </Table.Cell>
                      </Table.Row>
                      {place.transports.map((transport, b) => (
                        <Fragment key={b}>
                          <Table.Row className="boarding-table_transport-wrap">
                            <Table.Cell/>
                            <Table.Cell className="boarding-table_transport" colspan={4}>
                              {transport.number !== 'none' ? t('boarding:boarding.bus', { bus: transport.number }) : t('boarding:boarding.none')}
                            </Table.Cell>
                          </Table.Row>
                          {transport.passengers.map((user, u) => (
                            <Fragment key={u}>
                              <Table.Row className="boarding-table_passenger">
                                <Table.Cell className="align-right">
                                  <div className="checkbox boarding-table_checkbox">
                                    <input type="checkbox" value={user.order} id={user.order} defaultChecked={user.checked} onChange={(e) => seatPassengers(e.target.checked, user.order, place.id)}/>
                                    <label htmlFor={user.order}></label>
                                  </div>
                                </Table.Cell>
                                <Table.Cell colspan={4}>
                                  <InfoDescription className="small" description={<Phone value={user.phone}/>}>
                                    {user.name}
                                  </InfoDescription>
                                </Table.Cell>
                                <Table.Cell colspan={2} align={ALIGN_TABLE.RIGHT}>
                                  <InfoDescription className="small" description=
                                    {orders[user.order].transports.map((transport, i) => (
                                      <div key={i}>{`${orders[user.order].transports.length > 1 ? transport.number + ': ' : ''}${t('boarding:place.place', { count: transport.seats })}`}</div>
                                    ))}
                                  >
                                    {user.seat.noSeats > 0 ? t('boarding:people.noseats_people', { count: user.seat.count, noSeats: user.seat.noSeats }) : t('boarding:people.people', { count: user.seat.count, additional: user.seat.noSeats > 0 ? `+${user.seat.noSeats}` : '' })}
                                  </InfoDescription>
                                </Table.Cell>
                              </Table.Row>
                            </Fragment>
                          ))}
                          <div className="boarding-table_delim"></div>
                        </Fragment>
                      ))}
                    </Fragment>
                  ))}
                </Fragment>
              ))}
            </Fragment>
          ))}
        </Table>
      </div>
    </div>
  )
}

export default Boarding;
import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Info from '../../components/Info';
import DateFormat from '../../components/DateFormat';
import Price, { CURRENCY } from '../../components/Price';
import Phone from '../../components/Phone';
import RepositoryModel from '../../components/RepositoryModel';
import { RepositoryContext } from '../../store/RepositoryProvider';
import Select from '../../components/Select';
import InputAutocomplete from '../../components/InputAutocomplete';
import API from '../../server/api';
import log from '../../utils/logger';
import Button, { BUTTON_POSITION, BUTTON_TYPE } from '../../components/Button';

const OrderInfo = ({order, onClose = () => {}}) => {
  const {t} = useTranslation(['common', 'sale', 'order']);

  const repository = useContext(RepositoryContext);

  const [places, setPlaces] = useState([]);
  const [addresses, setAddresses] = useState([]);

  const [place, setPlace] = useState(order.place ? order.place.id : '');
  const [address, setAddress] = useState(order.address);

  const updateOrder = () => {
    let data = {};

    if (order.place) {
      data.place = place;
    }

    if (order.address) {
      data.address = address;
    }

    repository.request(API.order.update, { id: order.id, place }).then((o) => {
      order.place = o.place;
      order.address = o.address;
      onClose && onClose();
    });
  }

  useEffect(() => {
    if (address) {
      repository.list(API.geo.address, { address }, Object).then((addresses) => {
        setAddresses(addresses ? addresses[1].map((address) => address.name) : []);
        return addresses;
      }).catch((e) => {
        log.error(e);
        setAddresses([]);
        return [];
      });
    } else {
      setAddresses([]);
    }
  }, [address]);

  useEffect(() => {
    repository.request(API.schedule.schedule, { id: order.schedule.id }).then((schedule) => {
      const places = schedule.getPlaces(order.subdivision.id).map((place) => ({ value: place.place.id, text: place.titleTime }));
      setPlaces(places);
      const newPlace = schedule.getPlaces(order.subdivision.id).find((place) => order.place && place.place.id === order.place.id);
      setPlace(newPlace ? newPlace.place.id : (schedule.getPlaces(order.subdivision.id).length > 0 ? schedule.getPlaces(order.subdivision.id)[0].place.id : ''));
    });
  }, []);

  return (
    <div className="trip-app">
      <div className="trip-app-body">
        <Info title={t('order:order_info.excursion')}>
          {order.excursion.title}
        </Info>
        <Info title={t('order:order_info.departure')}>
          <DateFormat.DateTime date={order.schedule.date}/>
        </Info>
        <Info title={t('order:order_info.client')}>
          <div>{order.user.profile.fullName}</div>
          <div><Phone value={order.user.phone} /></div>
        </Info>
        <Info title={t('order:order_info.passengers')}>
          {order.passengers.map((passenger) => (
            <div key={passenger.id}>
              {passenger.count} <RepositoryModel type="Price" uid={passenger.price.id} render={(model) => model.title} />
            </div>
          ))}
        </Info>
        <Info title={t('order:order_info.additional')}>
          {order.wishes.map((wish, i) => (
            <span className="comma" key={i}>{t(`common:wishes.${wish}`)}</span>
          ))}
        </Info>
        <Info title={t('order:order_info.payment')}>
          <Price currency={CURRENCY.RUB} value={order.paymentDetails.amount} /> - {t(`common:payment_method.${order.paymentDetails.method}`)}
        </Info>

        {order.place && (
          <Info title={t('order:order_info.place')}>
            <Select
              value={place}
              onSelect={setPlace}
              empty={{ value: -1, text: t('order:common_info.none')}}
              options={places}
            />
          </Info>
        )}
        {order.address && (
          <Info title={t('order:order_info.address')}>
            <InputAutocomplete
              list={addresses}
              value={address}
              onInput={setAddress}
              asisText={false}
            />
          </Info>
        )}
      </div>
      <div className="trip-app-footer">
        <Button.Panel>
          <Button position={BUTTON_POSITION.LEFT} disabled={(!order.address || address === order.address) && (!order.place || place === order.place.id)} onClick={updateOrder}>{t('common:button.save')}</Button>
          <Button position={BUTTON_POSITION.RIGHT} variant={BUTTON_TYPE.SECONDARY} onClick={onClose}>{t('common:button.cancel')}</Button>
        </Button.Panel>
      </div>
    </div>
  )
}

OrderInfo.propTypes = {
  order: PropTypes.object.isRequired
};

export default OrderInfo;
import PropTypes from 'prop-types';

const DropRight = ({ className = '', onClick, ...otherProps }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={`icon ${className}`} viewBox="-8.5 0 32 32" onClick={onClick} {...otherProps}>
      <path d="M7.75 16.063l-7.688-7.688 3.719-3.594 11.063 11.094-11.344 11.313-3.5-3.469z"/>
    </svg>
  );
}

DropRight.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default DropRight;
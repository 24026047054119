import PropTypes, { object } from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import QR from 'react-qr-code';
import QrScanner from 'qr-scanner';
import log from '../utils/logger';
import { attachAndroidEvent, checkCameraPermission } from '../utils/events';
import Alert, { ALERT_TYPE } from './Alert';
import { useTranslation } from 'react-i18next';

const QRCode = ({ value, className = '' }) => {
  return (
    <div className={`qr-code ${className}`} aria-label="qrcode">
      <QR size={256} style={{ height: "auto", maxWidth: "100%", width: "100%" }} value={value} viewBox={`0 0 256 256`} />
    </div>
  );
};

QRCode.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string
};

const Scanner = ({ className = '', onResult }) => {
  const {t} = useTranslation(['common']);

  const [qrScanner, setQrScanner] = useState(null);
  const [data, setData] = useState(null);
  const [started, setStarted] = useState(false);
  const [error, setError] = useState('');
  const cameraRef = useRef();

  const updateFlashAvailability = () => {
    qrScanner && qrScanner.hasFlash().then(hasFlash => {
      log.debug('FLASH', hasFlash);
    });
  };

  const startQRScanner = (scanner) => {
    if (!started) {
      scanner = scanner || qrScanner;
      scanner && scanner.start().then(() => {
        setStarted(true);
        updateFlashAvailability();
        setError(null);
      }).catch((e) => {
        log.error(e);
        setStarted(false);
        setError(typeof e === 'object' ? e.message : e);
      });
    }
  };

  useEffect(() => {
    if (cameraRef.current) {
      attachAndroidEvent('camera', (e) => {
        if (e.detail) {
          if (cameraRef.current && !qrScanner) {
            const scanner = new QrScanner(
              cameraRef.current,
              (result) => {
                if (result.data && data !== result.data) {
                  setData(result.data);
                  scanner.stop();

                  setStarted(false);

                  onResult && onResult(result.data, null, scanner);
                }
              },
              {
                preferredCamera: QrScanner.DeviceId, //QrScanner.FacingMode | QrScanner.DeviceId,
                onDecodeError: () => {},
                returnDetailedScanResult: true,
              }
            );
      
            setQrScanner(scanner);
            startQRScanner(scanner);
          }
        } else {
          setError(t('common:error.camera_not_found'));
        }
      });

      checkCameraPermission().catch((e) => {
        // console.error(e);
      });
    }
  }, [cameraRef.current]);

  useEffect(() => {
    return () => {
      if (started && qrScanner) {
        qrScanner.stop();
      }
    };
  }, []);

  return (
    <section className={className}>
      <div className="qr-reader">
        {error && (
          <Alert type={ALERT_TYPE.ERROR}>{error}</Alert>
        )}
        <video ref={cameraRef} onClick={() => startQRScanner(qrScanner)}></video>
        <div className="scan-region-highlight">
          <svg className="scan-region-highlight-svg" viewBox="0 0 238 238" preserveAspectRatio="none">
            <path d="M31 2H10a8 8 0 0 0-8 8v21M207 2h21a8 8 0 0 1 8 8v21m0 176v21a8 8 0 0 1-8 8h-21m-176 0H10a8 8 0 0 1-8-8v-21"></path>
          </svg>
          <svg className="code-outline-highlight" preserveAspectRatio="none">
            <polygon></polygon>
          </svg>
        </div>
      </div>
    </section>
  )
};

Scanner.prototype = {
  className: PropTypes.string,
  onResult: PropTypes.func
};

QRCode.Scanner = Scanner;

export default QRCode;

import { useState } from 'react';
import { getJSON } from '../utils/json';
import { STORAGE } from '../defs/storage';
import OrderSession from '../models/session/OrderSession';
import User from '../models/user/User';
import UserSession from '../models/session/UserSession';

export const getStorageValue = (key, defaultValue, clazz = null) => {
  let data = getJSON(localStorage.getItem(key), defaultValue);
  return clazz && typeof clazz.constructor === 'function' ? new clazz(data || {}) : data;
};

export const setStorageValue = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeStorageValue = (key) => {
  localStorage.removeItem(key);
};

export const clearStorage = () => {
  localStorage.clear();
};

export const useLocalStorage = (key, defaultValue, clazz = null) => {
  const _value = getStorageValue(key, defaultValue, clazz);

  const [value, setValue] = useState(_value);

  const _setValue = (value) => {
    setStorageValue(key, {...getStorageValue(key, defaultValue, clazz), ...value});
    setValue(value);
    return value;
  };

  const _removeValue = () => {
    removeStorageValue(key);
    setValue(null);
    return null;
  };

  const _getValue = () => {
    let value = getStorageValue(key, defaultValue, clazz);
    setValue(value);
    return value;
  };

  return [value, _setValue, _removeValue, _getValue];
};

export const useUser = (defaultValue = null) => {
  return useLocalStorage(STORAGE.AUTH, defaultValue, User);
};

export const useUserSession = (defaultValue = null) => {
  return useLocalStorage(STORAGE.SESSION, defaultValue, UserSession);
};

export const useOrderSession = (defaultValue = null) => {
  return useLocalStorage(STORAGE.ORDER_SESSION, defaultValue, OrderSession);
};

import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button, { BUTTON_POSITION, BUTTON_TYPE } from '../../components/Button';
import Price, { CURRENCY } from '../../components/Price';
import Alert, { ALERT_TYPE } from '../../components/Alert';
import { RepositoryContext } from '../../store/RepositoryProvider';
import API from '../../server/api';
import log from '../../utils/logger';

const TakeSalary = ({ onCancel, onApply, amountMax }) => {
  const {t} = useTranslation(['common']);

  const [salary, setSalary] = useState(0);
  const [error, setError] = useState('');

  const repository = useContext(RepositoryContext);

  useEffect(() => {
  }, []);

  useEffect(() => {
    if (salary > 0 && salary > amountMax) {
      setError(t('cashbox:total_salary.over_limit'));
    } else {
      setError('');
    }
  }, [salary]);

  const handleApplyDate = useCallback(async () => {
    if (!error && salary > 0) {
      repository.action(API.cashier.withdrawSalary, { amount: salary }).then((salaryResult) => {
        typeof onApply === 'function' && onApply(salaryResult);
      }).catch((error) => {
        log.error(error);
      });
    }
  }, [error, salary]);

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel();
  }, []);

  return (
    <div className="trip-app">
      <div className="trip-app-body">
        {error && (
          <Alert type={ALERT_TYPE.ERROR} dismissible={true}>{error}</Alert>
        )}
       <div className="field">
         <div className="field-label pt-3">
           <span>{t('cashbox:total_salary.amount')}</span>
           <span className="link-action_dashed"><Price currency={CURRENCY.RUB} value={amountMax} onClick={() => setSalary(amountMax)}/></span>
         </div>
         <Price.Input max={amountMax} min={0} value={salary} onChange={setSalary}/>
       </div>
        <div className="mb-4">{t('cashbox:total_salary.note')}</div>
      </div>
      <div>
        <Button.Panel>
          <Button variant={BUTTON_TYPE.PRIMARY} position={BUTTON_POSITION.LEFT} onClick={handleApplyDate}>{t('common:button.save')}</Button>
          <Button variant={BUTTON_TYPE.SECONDARY} position={BUTTON_POSITION.RIGHT} onClick={handleCancel}>{t('common:button.cancel')}</Button>
        </Button.Panel>
      </div>
    </div>
  );
}

TakeSalary.propTypes = {
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  onApply: PropTypes.func,
  amountMax: PropTypes.number.isRequired
};

export default TakeSalary;
import { useEffect, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Alert from '../../components/Alert';
import { RepositoryContext } from '../../store/RepositoryProvider';
import Field from '../../components/Field';
import InputAutocomplete from '../../components/InputAutocomplete';
import API from '../../server/api';
import ReportParams from '../../models/report/ReportParams';
import Section from '../../components/Section';
import Info from '../../components/Info';
import Price, { CURRENCY } from '../../components/Price';
import { RefundContext } from '../../store/RefundProvider';
import { OrderStatus, PaymentMethod } from '../../models/types';
import DateFormat from '../../components/DateFormat';
import Order from '../../models/order/Order';
import SelectAutocomplete from '../../components/SelectAutocomplete';
import Phone from '../../components/Phone';
import RepositoryModel from '../../components/RepositoryModel';

dayjs.extend(utc);
dayjs.extend(timezone);

const StepOrderInfo = ({ onComplete }) => {
  const {t} = useTranslation(['common', 'refund']);

  const repository = useContext(RepositoryContext);
  const refundInfo = useContext(RefundContext);

  const [orderList, setOrderList] = useState([]);
  const [orderSection, setOrderSection] = useState(true);
  const [order, setOrder] = useState(null);
  
  const [stepOrderId, setStepOrderId] = refundInfo.useState('orderId', '');
  const [stepOrderNumber, setStepOrderNumber] = refundInfo.useState('orderNumber', '');
  const [stepOrderExcursionName, setStepOrderExcursionName] = refundInfo.useState('orderTitle', '');
  const [stepOrderPaymentMethod, setStepOrderPaymentMethod] = refundInfo.useState('orderPaymentMethod', PaymentMethod.CASH);
  const [stepOrderStatus, setStepOrderStatus] = refundInfo.useState('orderStatus', OrderStatus.PAID);
  const [stepOrderPassengers, setStepOrderPassengers] = refundInfo.useState('orderPassengers', []);
  const [stepOrderPoint, setStepOrderPoint] = refundInfo.useState('orderPoint', '');
  const [stepOrderAmount, setStepOrderAmount] = refundInfo.useState('orderAmount', 0);
  const [stepOrderSeller, setStepOrderSeller] = refundInfo.useState('orderSeller', '');

  const [orderNumber, setOrderNumber] = useState('');
  const [errors, setErrors] = useState([]);

  const handleCloseError = () => {
    setErrors([]);
  };

  const handleFilterReport = useCallback(() => {
    const filters = { };
    if (orderNumber.length > 0) {
      filters.number = Number(orderNumber);
    }

    repository.report(API.order.orders, new ReportParams({ filters }), Order).then((report) => {
      setOrderList(report.rows);
      
      if (report.rows.length === 1) {
        repository.request(API.order.order, { id: report.rows[0].id }).then((order) => {
          setOrder(order);

          setOrderSection(true);

          setStepOrderId(order.id);
          setStepOrderExcursionName(order.excursion.title);
          setStepOrderPaymentMethod(order.paymentDetails.method);
          setStepOrderStatus(order.status);
          setStepOrderPassengers(order.passengers);
          setStepOrderPoint(order.point.title);
          setStepOrderAmount(order.paymentDetails.paidAmount);
          repository.request(API.user.user, { id: order.seller.user.id }).then((user) => {
            setStepOrderSeller(`${user.profile.fullName}, ${Phone.Text({ phone: user.phone })}`);
          }).catch((e) => {
            setStepOrderSeller('');
          });
          setStepOrderNumber(order.number);
        }).catch((e) => {
          setOrder(null);
          refundInfo.setOrder(null)
        });
      } else {
        setOrder(null);
        refundInfo.setOrder(null)

        setOrderSection(false);

        refundInfo.resetOrder();
      }
    }).catch((e) => {
      setOrderList([]);
      setOrder(null);
      refundInfo.setOrder(null)

      setOrderSection(false);

      refundInfo.resetOrder();
    });
  }, [orderNumber]);

  useEffect(() => {
    handleFilterReport();
  }, [orderNumber]);

  useEffect(() => {
  }, []);

  return (
    <>
      {errors.map((error, i) => (
        <Alert type={error.type} key={error.uid} title={error.title} dismissible={true} onClose={() => handleCloseError(error.uid)}>{error.message}</Alert>
      ))}

      <Field title={t('refund:step_order_info.select_order')}>
        <InputAutocomplete
          value={orderNumber}
          list={orderList}
          template={(o) => t(`refund:step_order_info.order_by_date`, {number: o.number, date: DateFormat({date: o.date})})}
          placeholder={t('refund:step_order_info.order_number')}
          onInput={setOrderNumber}
          asisText={true}
          getValue={(o) => o ? String(o.number) : ''}
        />
      </Field>

      <Section visible={orderSection} onVisible={setOrderSection}>
        <Field>
          <Info title={t('refund:step_order_info.status_order')}>
            {order && t(`common:payment_status.${stepOrderStatus}`)}
          </Info>
        </Field>

        <Field>
          <Info title={t('refund:step_order_info.schedule')} id={stepOrderId}>
            {stepOrderExcursionName}
          </Info>
        </Field>

        <Field>
          <Info title={t('refund:step_order_info.payment_method')}>
            {order && t(`common:payment_method.${stepOrderPaymentMethod}`)}
          </Info>
        </Field>

        <Field>
          <Info title={t('refund:step_order_info.passengers')}>
            {(order && stepOrderPassengers instanceof Array) && (stepOrderPassengers.map((price) => (
              <span key={price.price.id} className='comma'>
                <RepositoryModel type="Price" uid={price.price.id} render={(p) => t(`order:price_type_count.${p.priceType}`, {count: price.count})} />
              </span>
            )))}
          </Info>
        </Field>

        <Field>
          <Info title={t('refund:step_order_info.point')}>
            {order && (
              <>
                {stepOrderPoint} / {stepOrderSeller}
              </>
            )}
          </Info>
        </Field>

        <Field>
          <Info title={t('refund:step_order_info.amount')}>
            <Price currency={CURRENCY.RUB} value={order ? stepOrderAmount : 0} />
          </Info>
        </Field>
      </Section>
    </>
  )
}

StepOrderInfo.propTypes = {
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  onError: PropTypes.func,
};

export default StepOrderInfo;
import PropTypes from 'prop-types';

const DropUp = ({ className = '', onClick, ...otherProps }) => {
  return (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={`icon ${className}`} xmlns="http://www.w3.org/2000/svg" onClick={onClick} {...otherProps}>
    <path d="M17.0002 14.8352C16.8128 15.0214 16.5594 15.126 16.2952 15.126C16.031 15.126 15.7776 15.0214 15.5902 14.8352L12.0002 11.2952L8.46019 14.8352C8.27283 15.0214 8.01938 15.126 7.75519 15.126C7.49101 15.126 7.23756 15.0214 7.05019 14.8352C6.95646 14.7422 6.88207 14.6316 6.8313 14.5098C6.78053 14.3879 6.75439 14.2572 6.75439 14.1252C6.75439 13.9932 6.78053 13.8625 6.8313 13.7406C6.88207 13.6188 6.95646 13.5082 7.05019 13.4152L11.2902 9.17517C11.3832 9.08147 11.4938 9.00707 11.6156 8.95627C11.7375 8.90557 11.8682 8.87937 12.0002 8.87937C12.1322 8.87937 12.2629 8.90557 12.3848 8.95627C12.5066 9.00707 12.6172 9.08147 12.7102 9.17517L17.0002 13.4152C17.0939 13.5082 17.1683 13.6188 17.2191 13.7406C17.2699 13.8625 17.296 13.9932 17.296 14.1252C17.296 14.2572 17.2699 14.3879 17.2191 14.5098C17.1683 14.6316 17.0939 14.7422 17.0002 14.8352Z" fill="#09101D"/>
  </svg>

);
}

DropUp.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default DropUp;
import ClassHelper from '../ClassHelper';
import User from '../user/User';

class Seller extends ClassHelper {
  constructor({ type, user } = {}) {
    super(...arguments);
    this.type = type; // SellerType
    this.user = this.obj(user, User);
  }
}

export default Seller;
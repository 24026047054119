import PropTypes from 'prop-types';

const ErrorHint = (props) => {
  if (props.error !== false) {
    return (
      <div role="alert" className="error-hint text-danger" aria-label="error-hint">{props.error}</div>
    );
  }

  return null;
};

ErrorHint.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default ErrorHint;

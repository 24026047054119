import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Price, { CURRENCY } from '../../components/Price';
import QRCode from '../../components/QRCode';
import { UserContext } from '../../store/UserProvider';
import Alert, { ALERT_TYPE } from '../../components/Alert';
import { ROUTES } from '../../defs/routes';
import Service from '../../utils/service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import DateFormat from '../../components/DateFormat';
import log from '../../utils/logger';
import { RepositoryContext } from '../../store/RepositoryProvider';
import API from '../../server/api';

dayjs.extend(utc);
dayjs.extend(timezone);

const HandCash = ({ amountMax }) => {
  const {t} = useTranslation(['common']);

  const navigate = useNavigate();

  const userInfo = useContext(UserContext);
  const repository = useContext(RepositoryContext);

  const [cash, setCash] = useState(0);
  const [query, setQuery] = useState('');
  const [error, setError] = useState('');
  const statusService = useRef(null);
  const collections = useRef([]);
  const newCollections = useRef([]);

  useEffect(() => {
    userInfo.getUser().then((u) => {
      setQuery(u.id);
    }).catch(() => {
      navigate(ROUTES.AUTH, { replace: true });
    });
  }, []);

  const getCash = () => {
    let money = parseFloat(cash);
    return cash ? JSON.stringify({ query, cash: money, currency: CURRENCY.RUB }) : '';
  };

  const clearNewCollection = (collection) => {
    let idx = newCollections.current.findIndex(c => c.day === collection.day && c.time === collection.time);
    if (idx >= 0) {
      newCollections.current.splice(idx, 1);
    }
  };

  const updateHistory = () => {
    const range = { fromDate: DateFormat.Request({ date: dayjs().add(-1, 'day').toDate() }), toDate: DateFormat.Request({ date:dayjs().add(1, 'day').toDate() }) };
    repository.action(API.cashier.cashbox, range).then((cashbox) => {
      let history = cashbox.history.filter((h) => !collections.current.find((c) => h.day === c.day && h.time === c.time) || newCollections.current.find((c) => h.day === c.day && h.time === c.time));
      newCollections.current = history;
      collections.current = cashbox.history;
    }).catch((e) => {
      log.error(e);
      collections.current = [];
    });
  };

  useEffect(() => {
    if (cash > 0 && cash > amountMax) {
      setError(t('cashbox:total_cash.over_limit'));
    } else {
      setError('');
    }
  }, [cash]);

  useEffect(() => {
    const range = { fromDate: DateFormat.Request({ date: dayjs().add(-1, 'day').toDate() }), toDate: DateFormat.Request({ date:dayjs().add(1, 'day').toDate() }) };

    repository.action(API.cashier.cashbox, range).then((cashbox) => {
      collections.current = cashbox.history;

      let service = new Service(() => {
        updateHistory();
      }, 2000);

      service.start(2000);

      statusService.current = service;
    }).catch((e) => {
      log.error(e);
      collections.current = [];
    });

    return () => {
      statusService.current && statusService.current.stop();
    };
  }, []);

  return (
    <div className="trip-app">
      <div className="trip-app-body">
        {error && (
          <Alert type={ALERT_TYPE.ERROR} dismissible={true}>{error}</Alert>
        )}

        {newCollections.current.length > 0 && newCollections.current.map((collection, i) => (
          <Alert key={i} code={i} type={ALERT_TYPE.SUCCESS} dismissible={true} onClose={() => clearNewCollection(collection)}>{t('collection:collection.complete', {amount: collection.amount})}</Alert>
        ))}

        <div className="field">
          <div className="field-label mt-3">
            <span>{t('cashbox:total_cash.amount')}</span>
            <span className="link-action_dashed"><Price currency={CURRENCY.RUB} value={amountMax} onClick={() => setCash(amountMax)}/></span>
          </div>
          <Price.Input max={amountMax} min={0} value={cash} onChange={setCash}/>
        </div>
        {cash >= 0 && !error && (
          <QRCode value={getCash()}/>
        )}
      </div>
    </div>
  );
}

HandCash.propTypes = {
  amountMax: PropTypes.number.isRequired
};

export default HandCash;
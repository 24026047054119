import { useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Alert from '../../components/Alert';
import { OrderContext } from '../../store/OrderProvider';
import { PaymentMethod } from '../../models/types';
import Field from '../../components/Field';

const StepPaymentBooking = () => {
  const {t} = useTranslation(['common', 'order']);

  const orderInfo = useContext(OrderContext);

  const [paymentMethod, setPaymentMethod] = orderInfo.useSession('payment_method', PaymentMethod.CASH);
  const [paymentComment, setPaymentComment] = orderInfo.useSession('payment_comment');
  const [paymentErrors] = orderInfo.useState('payment_errors', []);

  const handleCloseError = useCallback((uid) => {
    orderInfo.clearError('payment_booking', uid);
  }, []);

  return (
    <>
      <div>
        {paymentErrors.map((error, i) => (
          <Alert type={error.type} key={error.uid} title={error.title} dismissible={true} onClose={() => handleCloseError(error.uid)}>{error.message}</Alert>
        ))}
        <Field title={t('order:payment_booking.payment_method')}>
          {Object.values(PaymentMethod).map((method) => (
            <div key={method} className={ `payment-method icon-${method}` } onClick={() => setPaymentMethod(method)}>{t(`order:payment_booking.${method}`)}{paymentMethod === method && (<span className="payment-current">{t('order:payment_booking.current')}</span>)}</div>
          ))}
        </Field>
        <Field title={t('order:payment_booking.comment')}>
          <textarea rows="3" className="comment" onChange={(e) => setPaymentComment(e.target.value)} value={paymentComment}/>
        </Field>
      </div>
    </>
  )
}

StepPaymentBooking.propTypes = {
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  onError: PropTypes.func,
};

export default StepPaymentBooking;
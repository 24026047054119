import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { OrderContext } from '../../store/OrderProvider';
import { DynamicPriceType, ExcursionPickupType } from '../../models/types';
import InfoDescription from '../../components/InfoDescription';
import Price, { CURRENCY } from '../../components/Price';
import RepositoryModel from '../../components/RepositoryModel';
import DateFormat from '../../components/DateFormat';
import Field from '../../components/Field';

const ConfirmOrder = ({ onNext, onClose }) => {
  const {t} = useTranslation(['common', 'order']);

  const orderInfo = useContext(OrderContext);

  return (
    <div className="trip-app">
      <div className="trip-app-body">
        <table>
          <tbody>
            <tr>
              <td>
                <InfoDescription description={t('order:new_order.in_check')}>
                  <Price currency={CURRENCY.RUB} value={orderInfo.getPaymentAmount()} />
                </InfoDescription>
              </td>
              <td>
                <InfoDescription description={t('order:new_order.seats')}>
                  {orderInfo.getSession().schedule ? orderInfo.getSeatNumbers().join(', ') : '-'}
                </InfoDescription>
              </td>
            </tr>
          </tbody>
        </table>
        <hr/>
        <Field title={t('order:confirm_order.trip')}>
          <span className='comma'><RepositoryModel type="Subdivision" uid={orderInfo.getSession().subdivision} render={(model) => model.title} /></span>
          <span className='comma'><RepositoryModel type="Excursion" uid={orderInfo.getSession().excursion} render={(model) => model.title} /></span>
        </Field>
        <Field title={t('order:confirm_order.client')}>
          {orderInfo.getSession().phone} - {orderInfo.getSession().name}
        </Field>
        <Field title={t('order:confirm_order.passengers')}>
          {orderInfo.getSession().passengers.map((passenger) => (
            <span key={passenger.price} className='comma'>{passenger.count} <RepositoryModel type="Price" uid={passenger.price} render={(model) => model.title} /></span>
          ))}
        </Field>
        <Field title={t('order:confirm_order.place')}>
          {orderInfo.getOrderInfo().excursion && orderInfo.getOrderInfo().excursion.pickupType === ExcursionPickupType.HOME && (
            <span>{orderInfo.getSession().address}</span>
          )}
          {orderInfo.getOrderInfo().excursion && orderInfo.getOrderInfo().excursion.pickupType === ExcursionPickupType.PLACE && (
            <RepositoryModel type="Place" uid={orderInfo.getSession().place} render={(model) => model.title} />
          )}
        </Field>
        <Field title={t('order:confirm_order.date')}>
          <DateFormat.Day date={orderInfo.getSession().date}/>, {orderInfo.getSession().time}
        </Field>
        <Field title={t('order:confirm_order.comment')}>
          {orderInfo.getSession().comment || '-'}
        </Field>
        <Field title={t('order:confirm_order.additional')}>
          <>
            <div className="toggle-list">
              {orderInfo.getSession().wishes.map((wish, i) => (
                <span key={i} className='comma'>{t(`order:wishes.${wish}`)}</span>
              ))}
            </div>
            {orderInfo.getSession().discount && (
              <div>
                <RepositoryModel type="Discount" uid={orderInfo.getSession().discount} render={(model) => (
                  model.discount ? `${model.title} - ${model.discount.type === DynamicPriceType.PERCENT ? `${model.discount.value}%` : Price.Text({ currency: CURRENCY.RUB, value: model.discount.value })}` : ''
                )} />
              </div>
            )}
          </>
        </Field>
      </div>
    </div>
  );
}

ConfirmOrder.propTypes = {
  onNext: PropTypes.func,
  onClose: PropTypes.func,
};

export default ConfirmOrder;
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DropDownIcon from './icons/DropDown';
import DropUpIcon from './icons/DropUp';
import Dropdown from './Dropdown';

const InputAutocomplete = ({ value = '', placeholder, list, template, asisText = false, getValue, className = '', onSelect, onChange, onInput }) => {
  const [suggestions, setSugesstions] = useState([]);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [text, setText] = useState(value);
  const [seletedRow, setSelectedRow] = useState(-1);

  const handleInput = (e) => {
    let reg = new RegExp(e.target.value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
    let founded = list.filter((item) => reg.test(parseItem(item)));

    setText(e.target.value);
    setSugesstions(founded);
    handleShowSuggestion(true);

    typeof onInput === 'function' && onInput(e.target.value);
  };

  const handleShowSuggestion = (show) => {
    if (showSuggestion !== show) {
      setSelectedRow(-1);
    }

    setShowSuggestion(show);
  };

  const parseItem = (item) => {
    return template ? template(item) : item;
  };

  const handleChange = (value) => {
    let founded = list.filter((item) => {
      let itemValue = parseItem(item);
      return typeof itemValue === 'string' ? (itemValue.toLowerCase() === value.toLowerCase()) : (itemValue === value)
    });
    
    let outputValue = asisText && getValue ? getValue(founded.length > 0 ? founded[0] : null) : value;

    setText(outputValue);
    
    typeof onInput === 'function' && onInput(outputValue);
    typeof onChange === 'function' && onChange(outputValue);

    if (founded.length > 0) {
      typeof onSelect === 'function' && onSelect(outputValue);
    }
  };

  const handleSelect = (item) => {
    handleShowSuggestion(false);

    handleChange(parseItem(item));
  };

  const handleBlur = (e) => {
    setText(e.target.value);

    handleChange(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e && e.preventDefault();

      if (seletedRow !== -1) {
        handleChange(parseItem(suggestions[seletedRow]));
      } else if (suggestions.length > 0 && parseItem(suggestions[0]) === e.target.value) {
        setSelectedRow(0);
        handleChange(parseItem(suggestions[0]));
      } else {
        setText(e.target.value);
      }

      handleShowSuggestion(false);
    } else if (e.key === 'ArrowUp') {
      e && e.preventDefault();
      let newSelectedRow = seletedRow - 1;

      if (newSelectedRow < 0) {
        newSelectedRow = suggestions.length - 1;
      }

      setSelectedRow(newSelectedRow);
    } else if (e.key === 'ArrowDown') {
      e && e.preventDefault();
      let newSelectedRow = seletedRow + 1;

      if (newSelectedRow >= suggestions.length) {
        newSelectedRow = suggestions.length === 0 ? -1 : 0;
      }

      setSelectedRow(newSelectedRow);
    } else {
      const value = e.target.value;
      let founded = list.filter((item) => {
        let itemValue = parseItem(item);
        return typeof itemValue === 'string' ? (itemValue.toLowerCase() === value.toLowerCase()) : (itemValue === value)
      });

      if (founded) {
        setSelectedRow(suggestions.findIndex((s) => parseItem(s) === value));
      }
    }
  };

  const closeSuggestion = (e) => {
    if (showSuggestion && (!e || !(
      e.target.closest('.sugesstion-auto') ||
      e.target.classList.contains('sugesstion-auto') ||
      e.target.closest('.autocomplete-drop-icon') ||
      e.target.classList.contains('autocomplete-drop-icon')
    ))) {
      handleShowSuggestion(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeSuggestion);

    return () => {
      document.removeEventListener('click', closeSuggestion);
    };
  }, []);

  useEffect(() => {
    setSugesstions(list);
  }, [list]);

  useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <div className={`sugesstion-auto ${className}`}>
      <Dropdown show={showSuggestion} setShow={setShowSuggestion} control={
        <div className={`input-group ${className}`}>
          <input className="form-control" placeholder={placeholder} type="search" value={text} onBlur={handleBlur} onInput={handleInput} onKeyDown={handleKeyDown} />
          <span className="input-group-text" onClick={() => {
            handleShowSuggestion(!showSuggestion);
          }} role="group">
            <DropUpIcon role="button" className={`autocomplete-drop-icon ${showSuggestion ? 'show' : 'hide'}`} onClick={() => handleShowSuggestion(false)} />
            <DropDownIcon role="button" className={`autocomplete-drop-icon ${!showSuggestion ? 'show' : 'hide'}`} onClick={() => handleShowSuggestion(true)} />
          </span>
        </div>
      }>
        {suggestions.map((item, idx) => (
          <Dropdown.Item key={idx} role="option" className={`suggestion-item ${seletedRow === idx ? 'selected' : ''}`} onClick={() => handleSelect(item)} dangerouslySetInnerHTML={{__html: parseItem(item)  }}></Dropdown.Item>
        ))}
      </Dropdown>
    </div>
  );
}

InputAutocomplete.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  list: PropTypes.array,
  template: PropTypes.func,
  getValue: PropTypes.func,
  asisText: PropTypes.bool,
  className: PropTypes.string,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  onInput: PropTypes.func
};

export default InputAutocomplete;
import { ClassTimestampsHelper } from '../ClassHelper';
import Account from '../account/Account';
import Limit from './Limit';
import ScheduleExcursion from './ScheduleExcursion';
import ScheduleTransport from './ScheduleTransport';
import Route from '../route/Route';
import OrdersStat from '../stat/OrdersStat';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

class Schedule extends ClassTimestampsHelper {
  constructor({ account, number, route, excursions = [], date, limit, transports = [], status, reason, completedAt, routeWarning, stat } = {}) {
    super(...arguments);
    this.account = this.ref(account, Account);
    this.number = number;
    this.route = this.ref(route, Route);
    this.excursions = this.array(excursions, ScheduleExcursion);
    this.date = this.obj(date, Date);
    this.limit = this.obj(limit, Limit);
    this.transports = this.array(transports, ScheduleTransport);
    this.status = status; // ScheduleStatus
    this.reason = reason;
    this.completedAt = this.obj(completedAt, Date);
    this.routeWarning = routeWarning;
    this.stat = this.obj(stat, OrdersStat);
  }

  getDay() {
    return this.date ? this.date.getDate() : null;
  }

  getTime() {
    return this.date ? dayjs(this.date).format('HH:mm') : '';
  }

  getSeats() {
    return this.limit ? (this.limit.value - this.limit.cntSeats) : 0;
  }

  getExcursion(excursionId) {
    return this.excursions.find((excursion) => excursion.excursion && (excursion.excursion.id === excursionId));
  }

  getSubdivision(excursionId, subdivisionId) {
    const excursion = this.getExcursion(excursionId);
    return excursion.subdivisions.find((subdivision) => subdivision.subdivision.id === subdivisionId);
  }

  getPrices(excursionId, subdivisionId) {
    const subdivision = this.getSubdivision(excursionId, subdivisionId);
    return subdivision ? subdivision.prices : [];
  }

  getPrice(excursionId, subdivisionId, priceId) {
    return this.getPrices(excursionId, subdivisionId).find((price) => price.price && price.price.id === priceId);
  }

  getPlaces(subdivisionId) {
    const route = this.route ? this.route.subdivisions.find((subdivision) => subdivision.subdivision.id === subdivisionId) : null;
    let places = [];
    if (route) {
      places = places.concat(route.places);
    } else if (this.route) {
      this.route.subdivisions.forEach((route) => {
        places = places.concat(route.places);
      });
    }

    return places.map((place) => {
      let scheduleTime = (this.date.getHours() * 60 + this.date.getMinutes());
      return { ...place, titleTime: `${place.place.title} - ${place.getTime(scheduleTime)}` };
    });
  }
}

export default Schedule;
import ClassHelper from '../ClassHelper';
import Transport from '../transport/Transport';

class Seat extends ClassHelper {
  constructor({ seats = [], transport } = {}) {
    super(...arguments);
    this.seats = this.array(seats);
    this.transport = this.ref(transport, Transport);
  }
}

export default Seat;
export const FetchMethod = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

export const APIType = {
  FIXTURE: -1,
  FETCH: 0,
  WEBSOCKET: 1
};
import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAPI from '../hooks/useAPI';
import API from '../server/api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Logger from '../utils/logger';
import Account from '../models/account/Account';
import AccountObject from '../models/accountObject/AccountObject';
import { Area, City, Country, Region } from '../models/geo/GEO';
import Category from '../models/category/Category';
import Excursion from '../models/excursion/Excursion';
import FreeService from '../models/freeService/FreeService';
import ObjectClass from '../models/object/ObjectClass';
import Order from '../models/order/Order';
import Place from '../models/place/Place';
import Point from '../models/point/Point';
import Price from '../models/price/Price';
import Schedule from '../models/schedule/Schedule';
import Subdivision from '../models/subdivision/Subdivision';
import Transport from '../models/transport/Transport';
import User from '../models/user/User';
import Report from '../models/report/Report';
import { REF_STATUS } from '../models/ClassHelper';
import Discount from '../models/discount/Discount';
import { APICode } from '../models/types';
import ReportParams from '../models/report/ReportParams';
import { saveModel } from '../utils/events';
import OrderDetails from '../models/order/OrderDetails';
import Route from '../models/route/Route';

dayjs.extend(utc);
dayjs.extend(timezone);

export const RepositoryContext = createContext();

const initialState = () => {
  return {
    'Account': {},
    'AccountObject': {},
    'Area': {},
    'Category': {},
    'City': {},
    'Country': {},
    'Discount': {},
    'Excursion': {},
    'FreeService': {},
    'ObjectClass': {},
    'Order': {},
    'Place': {},
    'Point': {},
    'Price': {},
    'Region': {},
    'Route': {},
    'Schedule': {},
    'Subdivision': {},
    'Transport': {},
    'User': {},
    'OrderDetails': {}
  };
};

const classes = {
  'Account': Account,
  'AccountObject': AccountObject,
  'Area': Area,
  'Category': Category,
  'City': City,
  'Country': Country,
  'Discount': Discount,
  'Excursion': Excursion,
  'FreeService': FreeService,
  'ObjectClass': ObjectClass,
  'Order': Order,
  'Place': Place,
  'Point': Point,
  'Price': Price,
  'Region': Region,
  'Schedule': Schedule,
  'Subdivision': Subdivision,
  'Transport': Transport,
  'User': User,
  'OrderDetails': OrderDetails
};

class Model {
  constructor(id, clazz, state = REF_STATUS.NONE) {
    this.id = id;
    this.state = state;
    this.clazz = clazz;
    this.errors = [];
    this.value = null;
    this.updatedAt = new Date().getTime();
  }

  addError(error) {
    this.errors.push(error);
    this.updatedAt = new Date().getTime();
    return this;
  }

  getErrors() {
    return this.errors;
  }

  clearErrors() {
    this.errors = [];
    this.updatedAt = new Date().getTime();
    return this;
  }

  pending() {
    return this._updateState(REF_STATUS.PENDING);
  }

  reject() {
    return this._updateState(REF_STATUS.REJECTED);
  }

  fullfill() {
    return this._updateState(REF_STATUS.FULFILLED);
  }

  _updateState(state) {
    this.state = state;
    if (this.value) {
      this.value.state = this.state;
    }
    this.updatedAt = new Date().getTime();
    return this;
  }

  update(value) {
    this.value = value;
    this.updatedAt = new Date().getTime();
    return this;
  }
}

export const RepositoryProvider = (props) => {
  const logger = new Logger({ name: 'RepositoryProvider', showDate: false, showFile: false });
  const {t} = useTranslation(['common']);

  const [repository, setRepository] = useState(initialState());

  const [serverUnavailableIssue, setServerUnavailableIssue] = useState(false);

  const { callApi } = useAPI();

  const isServerUnavailableIssue = () => {
    return serverUnavailableIssue;
  };

  const clearCache = () => {
    setRepository(initialState());
  };

  const action = (api, params, clazz, doPreload = true) => {
    clazz = clazz || api.clazz || Object;

    return new Promise((resolve, reject) => {
      callApi(api, params).then((response) => {
        if (response.error) {
          setServerUnavailableIssue(response.error === APICode.ERR_NETWORK);
          reject(response);
        } else {
          setServerUnavailableIssue(false);
          let obj = response.data;

          if (clazz) {
            obj = new clazz(response.data);
            if (doPreload) {
              preload(obj);
            }
          }

          logger.debug("Repository::action", obj);
          resolve(obj);
        }
      }).catch((e) => {
        setServerUnavailableIssue(false);
        reject({ error: e });
      });
    });
  };

  const requestAPI = (api, params, clazz) => {
    clazz = clazz || api.clazz || Object;
    return new Promise((resolve, reject) => {
      if (params && (typeof params === 'object')) {
        callApi(api, params).then((response) => {
          if (response.error) {
            reject(response);
          } else {
            resolve(new clazz(response.data));
          }
        }).catch((e) => {
          reject({ error: e });
        });
      } else {
        reject({ error: APICode.BAD_REQUEST });
      }
    });
  };

  const request = async (api, params, clazz, doPreload = true) => {
    return requestAPI(api, params, clazz).then((obj) => {
      clazz = clazz || api.clazz || Object;
      let className = clazz.name;

      setServerUnavailableIssue(false);
      logger.debug("Repository::request", clazz.name, obj);

      if (doPreload) {
        preload(obj);
      }

      setRepository({ ...repository, [className]: { ...repository[className], [params.id]: new Model(params.id, clazz).update(obj) } });
      saveModel(params.id, className, obj);

      return obj;
    }).catch((e) => {
      setServerUnavailableIssue(e === APICode.ERR_NETWORK);
      return Promise.reject(e);
    });
  };

  const listAPI = (api, params, clazz) => {
    clazz = clazz || api.clazz || Object;
    return new Promise((resolve, reject) => {
      callApi(api, params).then((response) => {
        if (response.error) {
          reject(response);
        } else {
          resolve(response.data instanceof Array ? response.data.map((schedule) => new clazz(schedule)) : []);
        }
      }).catch((e) => {
        reject({ error: e });
      });
    });
  };

  const list = async (api, params, clazz, doPreload = true) => {
    clazz = clazz || api.clazz || Object;
    return listAPI(api, params, clazz).then((objList) => {
      setServerUnavailableIssue(false);
      logger.debug("Repository::list", clazz.name, objList);

      if (doPreload) {
        objList.forEach((obj) => { preload(obj); });
      }

      return objList;
    }).catch((e) => {
      setServerUnavailableIssue(e === APICode.ERR_NETWORK);
      return Promise.reject(e);
    });
  };

  const reportAPI = (api, params, clazz) => {
    clazz = clazz || api.clazz || Object;
    return new Promise(async (resolve, reject) => {
      let reportParams = new ReportParams(params);
      callApi(api, { ...params, ...reportParams } ).then((response) => {
        if (response.error) {
          reject(response);
        } else {
          resolve(new Report(response.data, clazz));
        }
      }).catch((e) => {
        reject({ error: e });
      });
    });
  };

  const report = async (api, params, clazz, doPreload = true) => {
    clazz = clazz || api.clazz || Object;
    return reportAPI(api, params, clazz).then((report) => {
      setServerUnavailableIssue(false);
      logger.debug("Repository::report", clazz.name, report);

      if (doPreload) {
        report.rows.forEach((obj) => { preload(obj); });
      }

      return report;
    }).catch((e) => {
      setServerUnavailableIssue(e === APICode.ERR_NETWORK);
      return Promise.reject(e);
    });
  };

  const getModel = async (clazz, id, useNull = false, useLoad = false) => {
    let className = typeof clazz === 'string' ? clazz : clazz.name;
    let obj = typeof id === 'object' ? id : { id };
    id = (id !== null && typeof id === 'object') ? id.id : id;

    if (className) {
      let classRepo = repository[className];
      if (id !== undefined) {
        if (classRepo && id && classRepo[id]) {
          return classRepo[id];// || (useNull ? null : { id });
        // } else if (classRepo && id && (obj = await getModel(id, className))) {
        //   classRepo[id] = new Model(id, classes[className]);
        //   classRepo[id].update(obj);
        //   setRepository({ ...repository, [className]: { ...repository[className], [id]: obj } });
        //   return obj;
        } else if (id && load[className]) {
          try {
            classRepo[id] = new Model(id, classes[className]);
            obj = await load[className]({ id });
            classRepo[id].update(obj);
            setRepository({ ...repository, [className]: { ...repository[className], [id]: classRepo[id] } });
            return classRepo[id];
          } catch (e) {
            return (useNull ? null : new Model(id, classes[className]));
          }
        } else {
          return (useNull ? null : new Model(id, classes[className]));
        }
      } else {
        return Object.values(classRepo);
      }
    } else {
      return null;
    }
  };

  const at = (clazz, id) => {
    return (repository[clazz] && repository[clazz][id]) || null;
  };

  const load = {
    'Account': ({ id, accountId }) => {},
    'AccountObject': ({ id, accountId }) => request(API.object.accountObjects, { id, accountId }, AccountObject),
    'Area': ({ id, accountId }) => {},
    'Category': ({ id, accountId }) => {},
    'City': ({ id, accountId }) => {},
    'Country': ({ id, accountId }) => {},
    'Discount': ({ id, accountId }) => request(API.discount.discount, { id, accountId }, Discount),
    'Excursion': ({ id, accountId }) => request(API.excursion.excursion, { id, accountId }, Excursion),
    'FreeService': ({ id, accountId }) => {},
    'ObjectClass': ({ id, accountId }) => request(API.object.accountObjects, { id, accountId }, ObjectClass),
    'Order': ({ id, accountId }) => {},
    'Place': ({ id, accountId }) => request(API.place.place, { id, accountId }, Place),
    'Point': ({ id, accountId }) => request(API.point.point, { id, accountId }, Point),
    'Price': ({ id, accountId }) => list(API.price.prices, { tab: 'excursion', accountId }, Price).then((prices) => {
      prices.forEach((price) => {
        setRepository({ ...repository, [Price.name]: { ...repository[Price.name], [price.id]: new Model(price.id, Price).update(price) } });
        saveModel(price.id, Price.name, price);
      });
      return prices.find((price) => price.id === id);
    }).catch(() => null),
    'Region': ({ id, accountId }) => {},
    'Route': ({ id, accountId }) => request(API.route.route, { id, accountId }, Route),
    'Schedule': ({ id, accountId }) => {},
    'Subdivision': ({ id, accountId }) => request(API.subdivision.subdivision, { id, accountId }, Subdivision),
    'Transport': ({ id, accountId }) => {},
    'User': ({ id, accountId }) => request(API.user.user, { id, accountId }, User),
    'OrderDetails': ({ id, accountId }) => {},
  }

  const preload = (obj) => {
    let model = null;
    if (obj && typeof obj === 'object') {
      const id = obj.id;
      const className = obj.constructor.name;
      const clazz = classes[className];
      if (id && clazz) {
        if (obj.isRef() && repository[className] && (!repository[className][id] || repository[className][id].state === REF_STATUS.NONE || repository[className][id].state === REF_STATUS.REJECTED)) {
          if (!repository[className][id]) {
            model = new Model(id, clazz, REF_STATUS.PENDING);
            repository[className][id] = model;
          }
          repository[className][id].update(obj);
          setRepository({ ...repository, [className]: { ...repository[className], [id]: repository[className][id] } });
          let promise = load[className] && load[className](obj);
          if (promise) {
            promise.then((o) => {
              repository[className][id].fullfill().update(o);
              setRepository({ ...repository, [className]: { ...repository[className], [id]: repository[className][id] } });
            }).catch((e) => {
              repository[className][id].reject();
              logger.error(`Preload ERROR [${className}:${id}]`, e);
            });
          }
        }
      }
      for (let name in obj) {
        let field = obj[name];
        if (field && typeof field === 'object') {
          preload(field);
        }
      }
    }
    return model;
  };

  // const getModel = async (className, id) => {
  //   if (repository[className] && !repository[className][id]) {
  //     return load[className]({id});
  //   } else {
  //     return new Promise((resolve, reject) => {
  //       if (repository[className][id]) {
  //         resolve(repository[className][id].value);
  //       } else {
  //         reject();
  //       }
  //     });
  //   }
  // };

  const getValue = async (className, id) => {
    let value = null;
    if (repository[className] && !repository[className][id] && load[className]) {
      value = await load[className]({ id });
    } else if (repository[className] && repository[className][id]) {
      value = repository[className][id];
    }

    return value;
  };

  useEffect(() => {
    if (props.store !== undefined) {
      for (let clazz in props.store) {
        if (repository[clazz]) {
          repository[clazz] = props.store[clazz];
        }
      }
    }
  }, []);

  return (
    <RepositoryContext.Provider
      value={{
        action,
        requestAPI,
        request,
        listAPI,
        list,
        reportAPI,
        report,
        preload,
        getModel,
        getValue,
        isServerUnavailableIssue,
        at,
        clearCache,
        load: { ...load }
      }}
    >
      {serverUnavailableIssue && (
        <div className="top-error-message">
          {t('common:error.err_network')}
        </div>
      )}
      {props.children}
    </RepositoryContext.Provider>
  );
};

export default RepositoryProvider;
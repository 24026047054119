import { Role } from '../types';

class UserSession {
  constructor({ account = '', role = '', point = '', subdivision = '', user, logged_in = false } = {}) {
    this.account = account;
    this.role = role;
    this.user = user;
    this.point = point;
    this.subdivision = subdivision;
    this.logged_in = logged_in;
  }

  isOwner() {
    return this.role && (this.role === Role.OWNER);
  }

  isAdmin() {
    return this.role && (this.role === Role.ADMIN || this.role === Role.OWNER);
  }

  isManager() {
    return this.role && (this.role === Role.MANAGER || this.role === Role.ADMIN || this.role === Role.OWNER);
  }

  isAccountant() {
    return this.role && (this.role === Role.ACCOUNTANT || this.role === Role.ADMIN || this.role === Role.OWNER);
  }

  isGuide() {
    return this.role && (this.role === Role.GUIDE || this.role === Role.ADMIN || this.role === Role.OWNER);
  }

  isDriver() {
    return this.role && (this.role === Role.DRIVER || this.role === Role.ADMIN || this.role === Role.OWNER);
  }

  isCashier() {
    return this.role && (this.role === Role.CASHIER || this.role === Role.ADMIN || this.role === Role.OWNER);
  }

  isDispatcher() {
    return this.role && (this.role === Role.DISPATCHER || this.role === Role.ADMIN || this.role === Role.OWNER);
  }

  isCollector() {
    return this.role && (this.role === Role.COLLECTOR || this.role === Role.ADMIN || this.role === Role.OWNER);
  }

  hasRole(roles) {
    return this.role && (roles instanceof Array ? (roles.indexOf(this.role) >= 0) : (this.role === roles));
  }

  isUser() {
    return !this.role;
  }
}

export default UserSession;
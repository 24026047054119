const STATUS = { START: "start", STOP: "stop" };

class Service
{
	#serviceInterval = null;
	#delayTimeout;
	#timestep = 0;
	#action = () => {};
	#status = STATUS.STOP;

  constructor(callback, time) {
    this.#timestep = (time && time > 0) ? time : 0;
    this.#action = typeof callback === 'function' ? callback : () => {};
  }

	start(delay) {
    clearInterval(this.#serviceInterval);
    clearInterval(this.#delayTimeout);

    // if timestep == 0 then run action once. else run as repeated service
    if (this.#timestep > 0) {
      this.#status = STATUS.START;

      if (delay && typeof delay === 'number') {
        this.#delayTimeout = setTimeout(() => {
          this.#action();

          this.#serviceInterval = setInterval(() => {
            if (this.#status === STATUS.START) {
              this.#action();
            } else {
              clearInterval(this.#serviceInterval);
              clearInterval(this.#delayTimeout);
            }
          }, this.#timestep);
        }, delay);
      } else {
        this.#action();
        this.#serviceInterval = setInterval(() => {
          if (this.#status === STATUS.START) {
            this.#action();
          } else {
            clearInterval(this.#serviceInterval);
            clearInterval(this.#delayTimeout);
          }
        }, this.#timestep);
      }
    }

    return this;
  }

  execute() {
    this.#action();
    return this;
  }

  stop() {
    this.#status = STATUS.STOP;
    clearInterval(this.#serviceInterval);
    clearInterval(this.#delayTimeout);
    return this;
  }

  setTimeInterval(time) {
    this.#timestep = (time && time > 0) ? time : 0;
    return this;
  }

  getStatus() {
    return this.#status;
  }

  isRun() {
    return this.#status === STATUS.START;
  }
}


export default Service;
import ClassHelper from '../ClassHelper';
import Place from '../place/Place';

class RoutePlace extends ClassHelper {
  constructor({ place, time, timeFromStart } = {}) {
    super(...arguments);
    this.place = this.ref(place, Place);
    this.time = time;
    this.timeFromStart = timeFromStart;
  }

  getTime(fromTime = 0) {
    let timeObj = {
      days: 0,
      hours: 0,
      minutes: 0
    };

    let d = (typeof this.time === 'number' ? this.time : 0) + fromTime;

    timeObj.days = Math.trunc(d / 1440);
    d %= 24 * 60;
    timeObj.hours = Math.trunc(d / 60);
    d %= 60;
    timeObj.minutes = Math.trunc(d);

    return `${timeObj.hours.toString().padStart(2, '0')}:${timeObj.minutes.toString().padStart(2, '0')}`;
  }
}

export default RoutePlace;
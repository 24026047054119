import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import QRCode from '../../components/QRCode';
import DateFormat from '../../components/DateFormat';
import Badge, { BADGE_TYPE } from '../../components/Badge';
import Price, { CURRENCY } from '../../components/Price';
import Table, { ALIGN_TABLE } from '../../components/Table';
import Info from '../../components/Info';
import Button from '../../components/Button';

const PAYMENT_STATUS = {
  paid: BADGE_TYPE.SUCCESS,
  awaiting_payment: BADGE_TYPE.WARN,
  cancelled: BADGE_TYPE.ERROR,
  completed: BADGE_TYPE.INFO
};

const Ticket = ({ order, number, excursion, date, schedule, seats = [], transports = [], paidAmount, paymentMethod, status }) => {
  const {t} = useTranslation(['common']);

  const [ticket, setTicket] = useState('');
  const [showTicket, setShowTicket] = useState(false);

  const openTicket = useCallback(() => {
    setShowTicket(!showTicket);
  }, [showTicket]);

  useEffect(() => {
    setTicket(JSON.stringify({
      order,
      schedule: schedule.id,
      excursion: excursion.id
    }));
  }, [order, schedule.id, excursion.id]);

  return (
    <div className="trip-app">
      <div className="trip-app-body">
        <Table cols={3}>
          <Table.Row>
            <Table.Cell colspan={2}>
              <Info>
                {excursion.title}
              </Info>
            </Table.Cell>
            <Table.Cell align={ALIGN_TABLE.RIGHT}>
              <Badge type={PAYMENT_STATUS[status]}>{t(`common:payment_status.${status}`)}</Badge>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="small">
              {t('ticket:ticket.order_date')}
            </Table.Cell>
            <Table.Cell colspan={2} className="small" align={ALIGN_TABLE.RIGHT}>
              <Info><DateFormat date={date} /></Info>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="small">
              {t('ticket:ticket.number')}
            </Table.Cell>
            <Table.Cell colspan={2} className="small" align={ALIGN_TABLE.RIGHT}>
              <Info>{number}</Info>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="small">
              {t('ticket:ticket.excursion')}
            </Table.Cell>
            <Table.Cell colspan={2} className="small" align={ALIGN_TABLE.RIGHT}>
              <Info>{excursion.title}</Info>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="small">
              {t('ticket:ticket.schedule_date')}
            </Table.Cell>
            <Table.Cell colspan={2} className="small" align={ALIGN_TABLE.RIGHT}>
              <Info><DateFormat.DateTime date={schedule.date}/></Info>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="small">
              {t('ticket:ticket.seats')}
            </Table.Cell>
            <Table.Cell colspan={2} className="small" align={ALIGN_TABLE.RIGHT}>
              <Info>{seats.map((seat) => (seat.toString() + ' '))}</Info>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="small">
              {t('ticket:ticket.transport')}
            </Table.Cell>
            <Table.Cell colspan={2} className="small" align={ALIGN_TABLE.RIGHT}>
              <Info>
                {transports.map((transport) => (
                  <div>
                    {transport.tilte} {transport.number} {transport.config.type}
                  </div>
                ))}
              </Info>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="small">
              {t('ticket:ticket.amount')}
            </Table.Cell>
            <Table.Cell colspan={2} className="small" align={ALIGN_TABLE.RIGHT}>
              <Info><Price currency={CURRENCY.RUB} value={paidAmount} /></Info>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="small">
              {t('ticket:ticket.payment_method')}
            </Table.Cell>
            <Table.Cell colspan={2} className="small" align={ALIGN_TABLE.RIGHT}>
              <Info>{t(`common:payment_method.${paymentMethod}`)}</Info>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colspan={3}>
              <Button onClick={openTicket}>{t(`ticket:button.${showTicket ? 'close_ticket' : 'open_ticket'}`)}</Button>
            </Table.Cell>
          </Table.Row>
        </Table>
        {showTicket && ticket && (
          <QRCode value={ticket}/>
        )}
      </div>
    </div>
  );
}

Ticket.propTypes = {
  order: PropTypes.string,
  excursion: PropTypes.object,
  date: PropTypes.any,
  schedule: PropTypes.object,
  seats: PropTypes.array,
  transports: PropTypes.array,
  paidAmount: PropTypes.number,
  paymentMethod: PropTypes.string,
  status: PropTypes.string
};

export default Ticket;
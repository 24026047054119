import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button, { BUTTON_POSITION, BUTTON_TYPE } from '../../components/Button';
import { UserContext } from '../../store/UserProvider';
import { RepositoryContext } from '../../store/RepositoryProvider';
import API from '../../server/api';
import Point from '../../models/point/Point';
import Field from '../../components/Field';
import { ROUTES } from '../../defs/routes';
import Select from '../../components/Select';
import SelectAutocomplete from '../../components/SelectAutocomplete';
import Subdivision from '../../models/subdivision/Subdivision';
import { AutocompleteItemTemplate } from '../../models/AutocompleteItem';

const Accounts = ({ onSave, onCancel }) => {
  const {t} = useTranslation(['common', 'account']);

  const navigate = useNavigate();

  const repository = useContext(RepositoryContext);
  const userInfo = useContext(UserContext);

  const [roles, setRoles] = useState([]);

  const [sessionAccount, setSessionAccount] = userInfo.useSession('account');
  const [sessionRole, setSessionRole] = userInfo.useSession('role');
  const [, setSessionSubdivision] = userInfo.useSession('subdivision');
  const [sessionPoint, setSessionPoint] = userInfo.useSession('point');

  const [user, setUser] = useState(null);
  const [points, setPoints] = useState([]);
  const [account, setAccount] = useState('');
  const [role, setRole] = useState('');
  const [point, setPoint] = useState(null);

  const getUser = () => {
    return userInfo.getUser().then((user) => {
      setUser(user);
      return user;
    }).catch((e) => {
      navigate(ROUTES.AUTH, { replace: true });
      return Promise.reject(e);
    });
  };

  const getPoints = () => {
    repository.list(API.subdivision.subdivisions, { accountId: account }, Subdivision, false).then((subdivisions) => {
      return repository.list(API.point.autocompleteList, { accountId: account }, AutocompleteItemTemplate(Point), false).then((points) => {
        points.forEach((point) => {
          point.text = `${point.label} (${subdivisions.find((s) => s.id === point.meta.subdivision.id) ? subdivisions.find((s) => s.id === point.meta.subdivision.id).title : ''})`;
        });
        setPoints(points);
        setPoint(points.find((point) => point.value === sessionPoint) || points[0]);
        return points;
      }).catch((e) => {
        setPoints([]);
        return e;
      });
    }).catch((e) => {
      setPoints([]);
      setPoint(null);
      return e;
    });
  };
;

  useEffect(() => {
    getUser();
    setAccount(sessionAccount);
    setRole(sessionRole);
  }, []);

  useEffect(() => {
    if (account && user) {
      if (userInfo.getSession().isCashier()) {
        getPoints();
      }

      let acc = user.getAccount(account);

      if (acc) {
        setRoles(acc.roles);
      } else {
        setRoles([]);
      }
    } else {
      setRoles([]);
    }
  }, [account]);

  const handleSave = () => {
    let needReload = !point || sessionRole !== role || sessionPoint !== point.value || sessionAccount !== account;

    setSessionAccount(account);
    setSessionRole(role);
    setSessionPoint(userInfo.getSession().isCashier() && point ? point.value : null);
    setSessionSubdivision(userInfo.getSession().isCashier() && point ? point.meta.subdivision.id : null);

    onSave && onSave(needReload);
  };

  const handleCancel = useCallback(() => {
    onCancel && onCancel();
  }, [onCancel]);

  return (
    <div className="trip-app">
      <header className={`trip-app-header`}>
        <h1>{t('account:accounts.title')}</h1>
      </header>
      <div className="trip-app-body">
        <Field title={t('account:accounts.company')}>
          <Select
            value={account}
            options={user && user.accounts ? user.accounts.map((acc) => (
              { value: acc.account.id, text: acc.accountTitle }
            )) : []}
            onSelect={setAccount}
          />
        </Field>
        <Field title={t('account:accounts.role')}>
          <Select
            value={role}
            options={roles.map((role) => (
              { value: role, text: t(`role.${role}`) }
            ))}
            onSelect={setRole}
          />
        </Field>
        {userInfo.getSession().isCashier() && (
          <Field title={t('account:accounts.point')}>
            <SelectAutocomplete
              list={points}
              template={(p) => p ? p.text : ''}
              value={point}
              onChange={setPoint}
              // getValue={(row) => row ? row.label : ''}
            />
          </Field>
        )}
      </div>
      <footer className="trip-app-footer">
        <Button.Panel>
          <Button position={BUTTON_POSITION.LEFT} variant={BUTTON_TYPE.PRIMARY} onClick={handleSave}>{t('common:button.apply')}</Button>
          <Button position={BUTTON_POSITION.RIGHT} variant={BUTTON_TYPE.SECONDARY} onClick={handleCancel}>{t('common:button.cancel')}</Button>
        </Button.Panel>
      </footer>
    </div>
  )
}

Accounts.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default Accounts;
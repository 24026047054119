import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Alert from '../../components/Alert';
import Button, { BUTTON_TYPE } from '../../components/Button';
import Modal from '../../components/Modal';
import SelectPassengers from './select_passengers';
import SelectDate from './select_date';
import { OrderContext, STEPS } from '../../store/OrderProvider';
import { RepositoryContext } from '../../store/RepositoryProvider';
import Checkbox from '../../components/Checkbox';
import { ExcursionPickupType, Wishes } from '../../models/types';
import API from '../../server/api';
import DateFormat from '../../components/DateFormat';
import RepositoryModel from '../../components/RepositoryModel';
import log from '../../utils/logger';
import Select from '../../components/Select';
import Field from '../../components/Field';
import InputAutocomplete from '../../components/InputAutocomplete';

dayjs.extend(utc);
dayjs.extend(timezone);

const StepCommonInfo = () => {
  const {t} = useTranslation(['common', 'order']);

  const orderInfo = useContext(OrderContext);
  const repository = useContext(RepositoryContext);

  const [excursions, setExcursions] = useState([]);
  const [subdivisions, setSubdivisions] = useState([]);
  const [prices, setPrices] = useState([]);
  const [places, setPlaces] = useState([]);
  const [addresses, setAddresses] = useState([]);

  const [schedule, setSchedule] = orderInfo.useState('schedule');
  const [excursion, setExcursion] = orderInfo.useState('excursion');
  const [errors, setErrors] = orderInfo.useState('common_errors', []);
  
  const [commonSchedule] = orderInfo.useSession('schedule');
  const [commonExcursion, setCommonExcursion] = orderInfo.useSession('excursion');
  const [commonSubdivision, setCommonSubdivision] = orderInfo.useSession('subdivision');
  const [commonDate, setCommonDate] = orderInfo.useSession('date');
  const [commonTime, setCommonTime] = orderInfo.useSession('time');
  const [commonPassengers, setCommonPassengers] = orderInfo.useSession('passengers');
  const [commonWishes, setCommonWishes] = orderInfo.useSession('wishes');
  const [commonPlace, setCommonPlace] = orderInfo.useSession('place');
  const [commonPassportRequired, setCommonPassportRequired] = orderInfo.useSession('passport_required');
  const [commonComment, setCommonComment] = orderInfo.useSession('comment');
  const [commonAddress, setCommonAddress] = orderInfo.useSession('address');
  const [paymentOrder] = orderInfo.useSession('order');

  const [showSelectPassengers, setShowSelectPassengers] = useState(false);
  const handleSelectPassengersClose = () => setShowSelectPassengers(false);
  const handleSelectPassengersShow = () => setShowSelectPassengers(true);

  const [showSelectDate, setShowSelectDate] = useState(false);
  const handleSelectDateClose = () => setShowSelectDate(false);
  const handleSelectDateShow = () => {
    setShowSelectDate(true);
  };


  const handleApplyDate = (day, time) => {
    setShowSelectDate(false);
    let date = DateFormat.Request({ date: day });
    setCommonDate(date);
    setCommonTime(time);
    fillSchedule({ date, time });
  };


  const handleChangeExcursion = (excursionId) => {
    fillSchedule({ excursionId });
  };


  const handleChangeSubdivision = (subdivisionId) => {
    setCommonSubdivision(subdivisionId);

    // updateRoute(subdivisionId);
  };

  const updateRoute = (subdivisionId) => {
    const places = schedule ? schedule.getPlaces(subdivisionId).map((place) => ({ value: place.place.id, text: place.titleTime })) : [];
    setPlaces(places);
    const place = schedule ? schedule.getPlaces(subdivisionId).find((place) => place.place.id === commonPlace) : '';
    setCommonPlace(place ? place.place.id : (schedule && schedule.getPlaces(subdivisionId).length > 0 ? schedule.getPlaces(subdivisionId)[0].place.id : ''));
  };


  const handleCloseError = (uid) => {
    let _errors = errors;
    orderInfo.clearError('common_info', uid);

    if (uid) {
      let idx = _errors.findIndex(error => error.uid === uid);
      if (idx >= 0) {
        _errors.splice(idx, 1);
      }
    } else {
      _errors = [];
    }

    setErrors(_errors);
  };


  const fillSchedule = async ({ date = commonDate, time = commonTime, excursionId = commonExcursion, placeId = commonPlace, subdivisionId = commonSubdivision }) => {
    date = date || commonDate;
    time = time || commonTime;
    excursionId = excursionId || commonExcursion;
    placeId = placeId || commonPlace;
    subdivisionId = subdivisionId || commonSubdivision;
    const scheduleId = commonSchedule;

    let schedule = null;
    let excursion = null;
    let excursions = [];
    let subdivision = null;
    let subdivisions = [];
    let prices = [];
    let places = [];
    let place = null;

    try {
      schedule = await repository.request(API.schedule.schedule, { id: scheduleId });

      if (schedule) {
        excursions = schedule.excursions.map((excursion) => ({ value: excursion.excursion.id, text: excursion.excursion.title }));

        excursion = await repository.request(API.excursion.excursion, { id: excursionId });

        if (excursion) {
          subdivision = excursion.subdivisions.find((subdivision) => subdivision.subdivision.id === subdivisionId);
          subdivisions = excursion.subdivisions.map((subdivision) => ({ value: subdivision.subdivision.id, text: subdivision.subdivision.title }));

          if (subdivision) {
            prices = [...subdivision.prices];
            places = schedule.getPlaces().map((place) => ({ value: place.place.id, text: place.titleTime }));
            place = schedule.getPlaces().find((place) => place.place.id === commonPlace);
          }
        };
      }
    } catch(e) {
      log.error(e);
      orderInfo.addError('common_info', 'select_excursion', t('order:common_info.error.select_excursion'), e.message);
    }

    setSchedule(schedule);
    setExcursion(excursion);
    setCommonExcursion(excursion && excursion.id ? excursion.id : '');
    setExcursions(excursions);
    setSubdivisions(subdivisions);
    setCommonSubdivision(subdivision && subdivision.subdivision.id ? subdivision.subdivision.id : '');
    setPrices(prices);
    orderInfo.setPrices(prices);
    setCommonPlace('');
    setPlaces(places);
    setCommonPlace(place ? place.place.id : ((schedule && schedule.getPlaces().length > 0) ? schedule.getPlaces()[0].place.id : ''));
  };


  const handleSelectPassengers = ({ passengers, wishes }) => {
    handleSelectPassengersClose();
    setCommonPassengers(passengers);
    setCommonWishes(wishes);
  };


  const handleWishes = (wish, checked) => {
    let orderWishes = commonWishes;
    let wishIdx = orderWishes.indexOf(wish);

    if (!checked) {
      if (wishIdx >= 0) {
        orderWishes.splice(wishIdx, 1);
      }
    } else {
      if (wishIdx === -1) {
        orderWishes.push(wish);
      }
    }

    setCommonWishes(orderWishes);
  };


  const validate = async () => {
    let errors = await orderInfo.validate(STEPS.common_info);
    setErrors(errors);
  };


  useEffect(() => {
    fillSchedule({
      date: commonDate,
      time: commonTime,
      scheduleId: commonSchedule,
      excursionId: commonExcursion,
      placeId: commonPlace,
      subdivisionId: commonSubdivision
    });
  }, []);


  useEffect(() => {
    if (commonAddress) {
      repository.list(API.geo.address, { address: commonAddress }, Object).then((addresses) => {
        setAddresses(addresses ? addresses[1].map((address) => address.name) : []);
        return addresses;
      }).catch((e) => {
        log.error(e);
        setAddresses([]);
        return [];
      });
    } else {
      setAddresses([]);
    }
  }, [commonAddress]);


  useEffect(() => {
    if (commonExcursion) {
      validate();
    }
  }, [
    commonExcursion,
    commonSubdivision,
    commonDate,
    commonTime,
    commonPassengers,
    commonPassportRequired,
    commonWishes,
    commonPlace,
    commonAddress,
    commonComment
  ]);


  return (
    <>
      <div>
        {errors.map((error, i) => (
          <Alert type={error.type} key={error.uid} title={error.title} dismissible={true} onClose={() => handleCloseError(error.uid)}>{error.message}</Alert>
        ))}

        <Field title={t('order:common_info.excursion')}>
          <Select
            value={commonExcursion}
            onSelect={handleChangeExcursion}
            options={excursions}
            disabled={!!paymentOrder}
          />
        </Field>

        {subdivisions.length <= 0 && (
          <Field title={t('order:common_info.subdivision')}>
            <Select
              value={commonSubdivision}
              onSelect={handleChangeSubdivision}
              options={subdivisions}
              disabled={!!paymentOrder}
            />
          </Field>
        )}

        {excursion && excursion.passportRequired && (
          <Field title={t('order:common_info.passport_data')}>
            <Checkbox.Slide title={t('order:common_info.put_client')} checked={!commonPassportRequired} onChange={(value, checked) => setCommonPassportRequired(!checked)} disabled={!!paymentOrder}/>
          </Field>
        )}

        <Field title={t('order:common_info.date_departure')}>
          <Button variant={BUTTON_TYPE.SELECT} onClick={handleSelectDateShow} disabled={!!paymentOrder}>
            <DateFormat.Day date={commonDate} />, {commonTime}
          </Button>
        </Field>

        <Field title={t('order:common_info.passengers')}>
          {excursion && excursion.passportRequired && commonPassportRequired && (
            <>
              {commonPassengers.reduce((count, passenger) => count + passenger.passports.reduce((count, passport) => count + (passport && passport.fullName && passport.number ? 1 : 0), 0), 0) === commonPassengers.reduce((count, passenger) => count + passenger.count, 0) ? (
                <span className="ready-note">{t('order:common_info.ready_passport')}</span>
              ) : (
                <span className="alert-note">{t('order:common_info.passport_required')}</span>
              )}
            </>
          )}
          <Button variant={BUTTON_TYPE.SELECT} onClick={handleSelectPassengersShow} disabled={!!paymentOrder}>
            {commonPassengers.map((passenger) => (
              <div className="passenger-price" key={passenger.excursion_price}>{passenger.count} <RepositoryModel type="Price" uid={passenger.price} render={(model) => model.title} /></div>
            ))}
          </Button>
          <Checkbox.Group>
            {Object.values(Wishes).map((wish) => (
              <Checkbox.Button key={wish} title={t(`order:wishes.${wish}`)} checked={commonWishes.indexOf(wish) >= 0} value={wish} onChange={handleWishes} disabled={!!paymentOrder}/>
            ))}
          </Checkbox.Group>
        </Field>

        {excursion && excursion.pickupType === ExcursionPickupType.PLACE && (
          <Field title={t('order:common_info.place_time')}>
            <Select
              value={commonPlace}
              onSelect={setCommonPlace}
              empty={{ value: -1, text: t('order:common_info.none')}}
              options={places}
              disabled={!!paymentOrder}
            />
          </Field>
        )}

        {excursion && excursion.pickupType === ExcursionPickupType.HOME && (
          <Field title={t('order:common_info.place')}>
            <InputAutocomplete
              list={addresses}
              value={commonAddress}
              onInput={setCommonAddress}
              asisText={false}
            />
          </Field>
        )}

        <Field title={t('order:common_info.comment')}>
          <textarea rows="3" onChange={(e) => setCommonComment(e.target.value)} value={commonComment}/>
        </Field>  
      </div>


      <Modal show={showSelectPassengers} onHide={handleSelectPassengersClose}>
        <Modal.Header>{t('order:select_passengers.title')}</Modal.Header>
        <Modal.Body>
          {showSelectPassengers && (
            <SelectPassengers
              prices={prices}
              limits={{seats: (schedule ? schedule.limit.value - schedule.limit.cntSeats : 0)}}
              passportRequired={commonPassportRequired}
              passengers={commonPassengers}
              wishes={commonWishes}
              onApply={handleSelectPassengers}
              onCancel={handleSelectPassengersClose}
            />
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showSelectDate} onHide={handleSelectDateClose}>
        <Modal.Header>{t('order:select_date.title')}</Modal.Header>
        <Modal.Body>
          {showSelectDate && (
            <SelectDate onApply={handleApplyDate} onCancel={handleSelectDateClose}/>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

StepCommonInfo.propTypes = {
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  onError: PropTypes.func,
};

export default StepCommonInfo;
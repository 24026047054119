import PropTypes from 'prop-types';

const Profile = ({ className = '', onClick, ...otherProps }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={`icon ${className}`} viewBox="-40 -40 375.239 375.239" onClick={onClick} {...otherProps}>
      <g>
        <g>
          <polygon style={{ fill: '#F9BA48' }} points="2.382,28.571 50.001,9.524 50.001,47.619"/>
          <path style={{ fill: '#333333' }} d="M277.658,23.81l-23.814,9.524h-27.653c-7.876,0-14.286,6.41-14.286,14.286v19.048
            c0,1.748,0.362,3.4,0.938,4.952c-3.352-5.867-8.676-10.529-15.224-12.843v-6.395C197.62,23.5,174.12,0,145.239,0
            S92.858,23.5,92.858,52.381v6.39C81.62,62.748,73.811,73.519,73.811,85.714c0,7.552,2.938,14.605,8.181,19.919
            c-2.248,4.143-3.419,8.71-3.419,13.414c0,6.648,2.262,12.952,6.576,18.186l-7.205,3.276l-2.914-32.124
            c-0.805-8.8-7.19-15.705-15.505-17.452V9.524h-9.524v81.805c-8.219,2.367-14.286,9.871-14.286,18.848v60.148
            c0,13.738,11.181,24.914,24.914,24.914c2.995,0,5.938-0.533,8.752-1.586l28.238-10.59v112.176h9.524v-42.857h76.19v42.857h9.524
            V183.062l28.243,10.59c2.81,1.052,5.752,1.586,8.748,1.586c13.733,0,24.914-11.176,24.914-24.914v-60.152
            c0-4.733-1.729-9.138-4.762-12.695V80.952h3.843l23.814,9.524h15.2V23.81H277.658z M221.43,47.619
            c0-2.624,2.133-4.762,4.762-4.762h23.809v28.571h-23.81c-2.629,0-4.762-2.138-4.762-4.762L221.43,47.619L221.43,47.619z
            M216.668,85.714c0-3.948-0.89-7.705-2.376-11.171c1.695,2.552,4.205,4.486,7.138,5.533V96.09
            c-3.367,3.257-5.548,7.562-5.981,12.3l-2.914,32.119l-7.186-3.267c4.243-5.11,6.557-11.476,6.557-18.195
            c0-4.705-1.171-9.271-3.419-13.414C213.73,100.319,216.668,93.267,216.668,85.714z M93.653,132.49
            c-3.586-3.595-5.557-8.371-5.557-13.443c0-4.086,1.352-8.038,3.914-11.424l2.886-3.824l-3.843-2.867
            c-4.9-3.662-7.719-9.21-7.719-15.219c0-9.024,6.41-16.871,15.233-18.662l3.814-0.776V52.381c0-23.629,19.224-42.857,42.857-42.857
            s42.857,19.229,42.857,42.857v13.895l3.814,0.776c8.824,1.79,15.233,9.638,15.233,18.662c0,6.01-2.819,11.557-7.724,15.219
            l-3.843,2.867l2.886,3.824c2.567,3.386,3.919,7.338,3.919,11.424c0,5.071-1.971,9.848-5.314,13.214l-0.919,0.8l-27.1-12.319v-10.1
            c8.695-6.99,14.286-17.69,14.286-29.69V52.381h-9.524c-7.876,0-14.286-6.41-14.286-14.286h-9.522
            c0,7.876-6.41,14.286-14.286,14.286h-28.571v28.571c0,12,5.59,22.705,14.286,29.69v10.1l-27.11,12.324L93.653,132.49z
            M145.239,109.524c-15.757,0-28.571-12.814-28.571-28.571V61.905h19.048c7.781,0,14.7-3.752,19.048-9.538
            c4.348,5.786,11.267,9.538,19.048,9.538v19.048C173.811,96.71,160.996,109.524,145.239,109.524z M130.953,116.243
            c4.414,1.795,9.233,2.805,14.286,2.805s9.871-1.01,14.286-2.805v7.567v3.067v1.695c0,7.876-6.41,14.286-14.286,14.286
            c-7.876,0-14.286-6.41-14.286-14.286v-1.695v-3.067V116.243z M121.682,131.09c1.281,11.933,11.29,21.29,23.557,21.29
            s22.276-9.357,23.557-21.29l4.605,2.09l-26.448,57.295h-3.429l-26.443-57.295L121.682,131.09z M229.849,185.714
            c-1.852,0-3.671-0.329-5.405-0.981l-41.11-15.414v73.538h-76.19v-73.538l-41.105,15.414c-1.738,0.652-3.557,0.981-5.41,0.981
            c-8.486,0-15.39-6.905-15.39-15.39v-60.152c0-3.614,1.91-6.776,4.762-8.581v7.933h9.524v-8.638
            c3.286,1.448,5.671,4.552,6.019,8.362l4.133,45.481l38.733-17.605L137.43,200h15.614l29.024-62.876l38.733,17.605l3.743-41.171
            c2.619,3.319,6.629,5.49,11.171,5.49c3.671,0,6.99-1.433,9.524-3.714v54.99C245.239,178.81,238.334,185.714,229.849,185.714z
            M240.477,104.762c0,2.624-2.133,4.762-4.762,4.762s-4.762-2.138-4.762-4.762v-23.81h9.524L240.477,104.762L240.477,104.762z
            M283.334,80.952h-3.848l-19.962-7.986V41.319l19.962-7.986h3.848V80.952z"/>
          <rect x="140.477" y="276.19" style={{ fill: '#333333' }} width="9.524" height="19.048"/>
          <path style={{ fill: '#F9BA48' }} d="M164.287,233.333h-38.096c-5.252,0-9.524-4.271-9.524-9.524V200c0-5.252,4.271-9.524,9.524-9.524
            h38.095c5.252,0,9.524,4.271,9.524,9.524v23.81C173.811,229.062,169.539,233.333,164.287,233.333z"/>
        </g>
      </g>
    </svg>
  );
}

Profile.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default Profile;
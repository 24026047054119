import React, { Children, Fragment } from 'react';
import PropTypes from 'prop-types';
import BootstrapButton from 'react-bootstrap/Button';
import BootstrapButtonGroup from 'react-bootstrap/ButtonGroup'
import BootstrapToggleButton from 'react-bootstrap/ToggleButton';
import BootstrapToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

export const BUTTON_TYPE = {
  PRIMARY: 'dark',
  SECONDARY: 'light',
  SELECTED: 'light',
  SELECT: 'select',
  ADD: 'add',
};

export const BUTTON_POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
  FLOAT: 'float',
  CENTER: 'center',
  NONE: 'none'
};

export const BUTTON_SIZE = {
  AUTO: 'auto',
  FULL: 'full',
  VW10: '10vw',
  VW20: '20vw',
  VW30: '30vw',
  VW40: '40vw',
  VW50: '50vw',
  VW60: '60vw',
  VW70: '70vw',
  VW80: '80vw',
  VW90: '90vw',
  VW100: '100vw'
};

export const TOGGLE_TYPE = {
  RADIO: 'radio',
  CHECKBOX: 'checkbox'
};

const Button = ({ variant = BUTTON_TYPE.PRIMARY, position = BUTTON_POSITION.NONE, id, className = '', size = BUTTON_SIZE.FULL, onClick, disabled, children }) => {
  return (
    <>
      {(position === BUTTON_POSITION.CENTER) && (
        <div className='bp-col'>
          <BootstrapButton id={id} variant={variant} className={`${className}`} onClick={onClick} disabled={disabled}>{children}</BootstrapButton>
        </div>
      )}
      {(position === BUTTON_POSITION.LEFT || position === BUTTON_POSITION.RIGHT || position === BUTTON_POSITION.FLOAT) && (
        <div className='bp-col'>
          <BootstrapButton id={id} variant={variant} className={`btn-${size} ${className}`} onClick={onClick} disabled={disabled}>{children}</BootstrapButton>
        </div>
      )}
      {(position !== BUTTON_POSITION.LEFT && position !== BUTTON_POSITION.RIGHT && position !== BUTTON_POSITION.FLOAT && position !== BUTTON_POSITION.CENTER) && (
        <BootstrapButton variant={variant} className={`btn-${size} ${className}`} onClick={onClick} disabled={disabled}>{children}</BootstrapButton>
      )}
    </>
  );
};

Button.propTypes = {
  id: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(BUTTON_TYPE)),
  position: PropTypes.oneOf(Object.values(BUTTON_POSITION)),
  size: PropTypes.oneOf(Object.values(BUTTON_SIZE)),
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

const Group = ({ children, className = '' }) => {
  return (
    <BootstrapButtonGroup className={className}>
      {children}
    </BootstrapButtonGroup>
  );
};

Button.Group = Group;

const Panel = ({ children, className = '' }) => {
  const childList = Children.toArray(children);
  let leftButtons = [];
  let floatButtons = [];
  let rightButtons = [];
  let idx = 0;

  if (childList) {
    Children.map(childList, (child) => {
      if (child.props.position === BUTTON_POSITION.LEFT) {
        leftButtons.push(<Fragment key={idx++}>{child}</Fragment>);
      } else if (child.props.position === BUTTON_POSITION.RIGHT) {
        rightButtons.push(<Fragment key={idx++}>{child}</Fragment>);
      } else {
        floatButtons.push(<Fragment key={idx++}>{child}</Fragment>);
      }
    });
  }

  if (leftButtons.length === 1 && rightButtons.length <= 0 && floatButtons.length <= 0) {
    floatButtons.push(<div className='bp-col' key={idx++}></div>);
  }

  if (leftButtons.length <= 0 && rightButtons.length === 1 && floatButtons.length <= 0) {
    floatButtons.unshift(<div className='bp-col' key={idx++}></div>);
  }

  return (
    <div className={`button-panel ${className}`}>
      <div className='bp-row'>
        {leftButtons}
        {floatButtons}
        {rightButtons}
      </div>
    </div>
  );
};

Button.Panel = Panel;


const Toggle = ({ id, name, value = [], checked, type = TOGGLE_TYPE.RADIO, className = '', onChange, children }) => {

  return (
    <BootstrapToggleButton id={id} type={type} className={className} variant={checked ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.SECONDARY} name={name} value={value} checked={checked} onChange={(e) => onChange(e.currentTarget.value)}>
      {children}
    </BootstrapToggleButton>
  );
};

Toggle.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChangeButton: PropTypes.func,
  id: PropTypes.string.isRequired
};

Button.Toggle = Toggle;

class ToggleGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }

  toggleChange = (value) => {
    this.setState(() => ({ value: this.props.type === TOGGLE_TYPE.CHECKBOX ? [ ...value ] : value }));
    this.props.onChange && this.props.onChange(value);
  }

  render() {
    return (
      <BootstrapToggleButtonGroup className={this.props.className} type={this.props.type} name={this.props.name} value={this.state.value} onChange={this.toggleChange}>
        {this.props.children}
      </BootstrapToggleButtonGroup>
    );
  }

};

ToggleGroup.propTypes = {
  name: PropTypes.string,
  type: PropTypes.oneOf(Object.values(TOGGLE_TYPE)),
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func
};

Button.ToggleGroup = ToggleGroup;

export default Button;
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Field from '../../components/Field';
import SelectDate, { DAY_PICKER_MODE } from '../../components/SelectDate';
import NavBar from '../../components/NavBar';
import InfoDescription from '../../components/InfoDescription';
import Price, { CURRENCY } from '../../components/Price';
import Table from '../../components/Table';
import DateFormat from '../../components/DateFormat';
import { RepositoryContext } from '../../store/RepositoryProvider';
import API from '../../server/api';
import log from '../../utils/logger';
import NewCollection from './new_collection';
import Modal from '../../components/Modal';
import Badge, {BADGE_TYPE} from "../../components/Badge";

const Collections = () => {
  const {t} = useTranslation(['common', 'collection']);

  const repository = useContext(RepositoryContext);

  const [range, setRange] = useState({ from: new Date(), to: new Date() });
  const [totalAmount, setTotalAmount] = useState(0);
  const [collections, setCollections] = useState([]);

  const [showNewCollectionModal, setShowNewCollectionModal] = useState(false);

  const handleCloseNewCollectionModal = useCallback(() => {
    loadCollections();
    setShowNewCollectionModal(false);
  }, []);

  const loadCollections = useCallback(async () => {
    repository.list(API.collectors.collections, { fromDate: DateFormat.Request({ date: range ? range.from : null }), toDate: DateFormat.Request({ date: range ? range.to : null }) }).then((rows) => {
      setCollections(rows);
      setTotalAmount(rows.reduce((amount, collection) => amount + collection.amount, 0));
    }).catch((e) => {
      log.error(e);
      setCollections([]);
      setTotalAmount(0);
    });
  }, [range]);

  useEffect(() => {
    loadCollections();
  }, [range]);

  return (
    <div className="trip-app">
      <NavBar title={(
        <>
          {t('collection:collections.title')}
          <span className={'title-disabled'} style={{paddingLeft: '20px'}} onClick={() => setShowNewCollectionModal(true)}>{t('collection:collections.new')}</span>
        </>
      )} />
      <div className="trip-app-body">
        <Field title={t('collection:collections.dates')}>
          <SelectDate mode={DAY_PICKER_MODE.RANGE} selected={range} onChange={setRange} />
        </Field>

        <table className="info-table mb-0">
          <tbody>
          <tr>
            <td>
              <InfoDescription description={t('collection:collections.total')}>
                <Price currency={CURRENCY.RUB} value={totalAmount} />
              </InfoDescription>
            </td>
          </tr>
          </tbody>
        </table>

        <Table cols={3}>
          {collections.map((collection, i) => (
            <Table.Row key={i} delim={true} className="pb-2">
              <Table.Cell colspan={2}>
                <InfoDescription description={<>{t('collection:collections.cashier')} {collection.user.profile.fullName}</>} >
                  <DateFormat.DateTime date={collection.date} />
                </InfoDescription>
              </Table.Cell>
              <Table.Cell className="align-right">
                <Badge type={BADGE_TYPE.INFO}>
                  <Price currency={CURRENCY.RUB} value={collection.amount}/>
                </Badge>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table>

        <Modal show={showNewCollectionModal} onHide={handleCloseNewCollectionModal}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            {showNewCollectionModal && <NewCollection onCancel={handleCloseNewCollectionModal} />}
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default Collections;
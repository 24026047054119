import ClassHelper from '../ClassHelper';

class PaymentsSettings extends ClassHelper {
  constructor({ tBankToken, yookassaShopId, yookassaToken } = {}) {
    super(...arguments);
    this.tBankToken = tBankToken;
    this.yookassaShopId = yookassaShopId;
    this.yookassaToken = yookassaToken;
  }
}

export default PaymentsSettings;
import { ClassTimestampsHelper } from '../ClassHelper';
import AccountSettings from './AccountSettings';
import Contact from './Contact';
import Requisite from './Requisite';
import User from '../user/User';
import PaymentsSettings from './PaymentsSettings';

class Account extends ClassTimestampsHelper {
  constructor({ title, url, owner, type, status, requisite, percent, balance, siteUrl, description, logo, background, privateContact, about, publicContact, settings, paymentsSettings } = {}) {
    super(...arguments);
    this.title = title;
    this.description = description;
    this.url = url;
    this.siteUrl = siteUrl;
    this.logo = logo;
    this.background = background;
    this.owner = this.obj(owner, User);
    this.type = type; // AccountType
    this.status = status; // AccountStatus
    this.requisite = this.obj(requisite, Requisite);
    this.privateContact = this.obj(privateContact, Contact);
    this.publicContact = this.obj(publicContact, Contact);
    this.about = about;
    this.percent = percent;
    this.balance = balance;
    this.settings = this.obj(settings, AccountSettings);
    this.paymentsSettings = this.obj(paymentsSettings, PaymentsSettings);
  }
}

export default Account;
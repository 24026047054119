import { useState, useEffect, useContext, useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button, { BUTTON_POSITION, BUTTON_TYPE } from '../../components/Button';
import Alert, { ALERT_TYPE } from '../../components/Alert';
import { PriceType, Wishes } from '../../models/types';
import { RepositoryContext } from '../../store/RepositoryProvider';
import Checkbox from '../../components/Checkbox';
import Table from '../../components/Table';
import { _times } from '../../utils/util';
import Select from '../../components/Select';
import Section from '../../components/Section';
import MinusIcon from '../../components/icons/Minus';
import AddIcon from '../../components/icons/Add';
import Field from '../../components/Field';
import Input from '../../components/Input';

const SelectPassengers = ({ prices = [], limits = {}, wishes = [], passengers = [], onApply, onCancel, passportRequired = false }) => {
  const {t} = useTranslation(['common', 'order']);

  const repository = useContext(RepositoryContext);

  const [newPassengers, setNewPassengers] = useState(passengers);
  const [newWishes, setNewWishes] = useState(wishes);
  const [newPassportsName, setNewPassportsName] = useState({});
  const [newPassportsNumber, setNewPassportsNumber] = useState({});
  const [newAges, setNewAges] = useState({});
  const [errors, setErrors] = useState([]);
  const [priceRows, setPriceRows] = useState([]);
  const [passportRows, setPassportRows] = useState([]);


  const handleIncrement = useCallback((excursionPrice, priceId) => {
    handlePassengers(excursionPrice, priceId, (newPassengers.find(passenger => passenger.excursion_price === excursionPrice.id) || {count: 0}).count + 1);
  }, [newPassengers]);


  const handleDecrement = useCallback((excursionPrice, priceId) => {
    handlePassengers(excursionPrice, priceId, (newPassengers.find(passenger => passenger.excursion_price === excursionPrice.id) || {count: 0}).count - 1);
  }, [newPassengers]);


  const handleWishes = useCallback((wish, checked) => {
    let orderWishes = [...newWishes];
    let wishIdx = orderWishes.indexOf(wish);
    if (!checked) {
      if (wishIdx >= 0) {
        orderWishes.splice(wishIdx, 1);
      }
    } else {
      if (wishIdx === -1) {
        orderWishes.push(wish);
      }
    }

    setNewWishes([...orderWishes]);
  }, [newWishes]);


  const handlePassengers = useCallback((excursionPrice, priceId, count) => {
    let orderPassengers = [...newPassengers];
    let passengerIdx = orderPassengers.findIndex(passenger => passenger.excursion_price === excursionPrice.id);
    if (count <= 0) {
      if (passengerIdx >= 0) {
        orderPassengers.splice(passengerIdx, 1);
      }
    } else {
      if (passengerIdx === -1) {
        orderPassengers.push({ excursion_price: excursionPrice.id, price: priceId, count, passports: [], ages: [], rewardPartner: excursionPrice.rewardPartner });
      } else {
        orderPassengers[passengerIdx].count = Math.max(count, 0);
      }
    }

    setNewPassengers([...orderPassengers]);
  }, [newPassengers]);


  const buildPriceRows = useCallback(async () => {
    const rows = [];
    let idt = {};
    const idtInc = (type, count = 1) => {
      idt[type] = idt[type] || 0;
      idt[type] += count;
      return idt[type];
    };

    for (let i = 0; i < prices.length; ++i) {
      const price = prices[i];
      let excPrice = prices.find((excPrice) => excPrice.id === price.id);
      let priceModel = await repository.getModel('Price', price.price.id, true, true);

      if (priceModel && priceModel.value) {
        let info = priceModel.value.priceType === PriceType.CHILD ? (priceModel.value.noSeat ? t('order:price_type.no_seat') : t('order:price_type.with_seat')) : '';
        let title = priceModel.value.title;

        if (excPrice.minAge >= 0 || excPrice.maxAge >= 0) {
          if (excPrice.minAge >= 0 && excPrice.maxAge >= 0) {
            title += ` ${t('order:select_passengers.age.fromto', { from: excPrice.minAge, to: excPrice.maxAge })}`;
          } else if (excPrice.minAge >= 0) {
            title += ` ${t('order:select_passengers.age.from', { age: excPrice.minAge })}`;
          } else if (excPrice.maxAge >= 0) {
            title += ` ${t('order:select_passengers.age.to', { age: excPrice.maxAge })}`;
          }
        }

        let passenger = newPassengers.find((passenger) => passenger.excursion_price === price.id);

        rows.push(
          <Fragment key={`price_${i}`}>
            <div className="price">
              <div className="price-title" title={info}>{title}</div>
              <span className="price-count flex-shrink-1">
                <Button className="price-button" variant={BUTTON_TYPE.SECONDARY} onClick={() => handleDecrement(price, price.price.id)}><MinusIcon /></Button>
              </span>
              <span className="price-count_content">{(passenger || {count: 0}).count}</span>
              <span className="price-count flex-shrink-1">
                <Button className="price-button" variant={BUTTON_TYPE.SECONDARY} onClick={() => handleIncrement(price, price.price.id)}><AddIcon /></Button>
              </span>
            </div>
          </Fragment>
        );

        if (excPrice.minAge || excPrice.maxAge) {
          let passports = [...Array(passenger ? passenger.count : 0)];

          rows.push(
            <div key={`age_${i}`} className="age-section">
              {passports.map((e, j) => (
                <div key={`age_${i}_${i}`} className={`age-title ${j % 2 ? 'last-child' : ''}`}>
                  <div>
                    {t('order:select_passengers.child_age', { number: idtInc(priceModel.value.priceType) })}
                  </div>
                  <Select
                    className="age-select"
                    value={newAges[`${excPrice.id}-${j}`] || -1}
                    empty={{ value: -1, text: t('order:select_passengers.select_age')}}
                    options={_times((excPrice.maxAge || 14) - (excPrice.minAge || 0) + 1, (l, o) => (
                      {
                        value: (excPrice.minAge || 0) + o,
                        text: t('order:select_passengers.age.age', { count: (excPrice.minAge || 0) + o })
                      }
                    ))}
                    onSelect={value => setNewAges((prevState) => ({ ...prevState, [`${excPrice.id}-${j}`]: value }))}
                  />
                </div>
              ))}
            </div>
          );
        } else {
          idtInc(priceModel.value.priceType, passenger ? passenger.count : 0);
        }
      }
    }

    setPriceRows(rows);

    validate();
  }, [newPassengers, newAges, newPassportsName, newPassportsNumber]);


  const buildPassportRows = useCallback(async () => {
    const rows = [];

    let idt = {};
    const idtInc = (type, count = 1) => {
      idt[type] = idt[type] || 0;
      idt[type] += count;
      return idt[type];
    };

    for (let i = 0; i < prices.length; ++i) {
      const price = prices[i];
      let excPrice = prices.find((excPrice) => excPrice.id === price.id);
      let priceModel = await repository.getModel('Price', price.price.id, true, true);

      if (priceModel && priceModel.value) {
        let passenger = newPassengers.find((passenger) => passenger.excursion_price === price.id);
        let passports = [...Array(passenger ? passenger.count : 0)];

        passports = passports.map((e, j) => (
          <Fragment key={`passport_${i}_${j}`}>
            <Field title={passportRequired ? (
                <>
                  {t(`order:select_passengers.passenger_${price.price.priceType}`, { number: idtInc(price.price.priceType) })}
                  <span className='field-note'>{price.price.priceType === PriceType.CHILD ? t('order:select_passengers.is_child') : t('order:select_passengers.is_adult')}</span>
                </>
              ) : ''}
            >
              <Table cols={1}>
                {passportRequired && (
                  <Table.Row>
                    <Table.Cell>
                      <Input
                        value={newPassportsName[`${excPrice.id}-${j}`]}
                        placeholder={t('order:select_passengers.passport.full_name')}
                        onChange={(value) => setNewPassportsName((prevState) => ({ ...prevState, [`${excPrice.id}-${j}`]: value }))}
                      />
                    </Table.Cell>
                  </Table.Row>
                )}

                {(passportRequired || excPrice.minAge || excPrice.maxAge) && (
                  <Table.Row className="gap-2">
                    {passportRequired && (
                      <Table.Cell colspan={(excPrice.minAge || excPrice.maxAge) ? 1 : 2}>
                        <Input
                          value={priceModel.value.priceType === PriceType.CHILD ? (newPassportsNumber[`${excPrice.id}-${j}`] ? newPassportsNumber[`${excPrice.id}-${j}`].padStart(11, '_') : '') : newPassportsNumber[`${excPrice.id}-${j}`]}
                          mask={priceModel.value.priceType === PriceType.CHILD ? [/[IVX ]/i, /[IVX ]/i, /[IVX ]/i, '-', /[А-Яа-я]/, /[А-Яа-я]/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/] : '99 99 999999'}
                          placeholder={t(`order:select_passengers.passport.${priceModel.value.priceType === PriceType.CHILD ? 'birth_cert' : 'passport'}`)}
                          onChange={(value) => setNewPassportsNumber((prevState) => ({ ...prevState, [`${excPrice.id}-${j}`]: value.replace(/[_ -]/g,'') }))}
                        />
                      </Table.Cell>
                    )}
                  </Table.Row>
                )}
              </Table>
            </Field>
          </Fragment>
        ));

        rows.push(
          <Fragment key={`passport_${i}`}>
            {passports}
          </Fragment>
        );
      }
    }
    setPassportRows(rows);

    validate();
  }, [newPassengers, newAges, newPassportsName, newPassportsNumber]);


  const validate = async () => {
    let newErrors = [...errors];

    let passengerCount = 0;
    for (let i = 0; i < newPassengers.length; ++i) {
      const price = await repository.getModel('Price', newPassengers[i].price, true, true);

      if (price && !price.noSeat) {
        passengerCount += newPassengers[i].count;
      }
    }

    let errorIdx = {
      zero_seat: newErrors.findIndex(error => error.code === 'zero_seat'),
      not_enough_seat: newErrors.findIndex(error => error.code === 'not_enough_seat')
    };

    if (passengerCount === 0) {
      if (errorIdx.zero_seat === -1) {
        newErrors.push({ code: 'zero_seat', type: ALERT_TYPE.WARN, title: t('order:error.zero_seat') });
      }
    } else {
      if (errorIdx.zero_seat >= 0) {
        newErrors.splice(errorIdx.zero_seat, 1);
      }

      if (passengerCount > limits.seats) {
        if (errorIdx.not_enough_seat === -1) {
          newErrors.push({ code: 'not_enough_seat', type: ALERT_TYPE.ERROR, title: t('order:error.not_enough_seat'), message: t('order:error.not_enough_seat_description') });
        }
      } else {
        if (errorIdx.not_enough_seat >= 0) {
          newErrors.splice(errorIdx.not_enough_seat, 1);
        }
      }
    }

    setErrors(newErrors);

    return newErrors.length === 0;
  };


  const handleReady = useCallback(async () => {
    let isValid = await validate();

    if (isValid) {
      Object.keys(newPassportsName).forEach((id) => {
        let [priceId, idx] = id.split('-');
        let passenger = newPassengers.find((passenger) => passenger.excursion_price === priceId);
        if (passenger) {
          passenger.passports = passenger.passports || [];
          passenger.passports[idx] = passenger.passports[idx] || {
            fullName: null,
            number: null
          };
          passenger.passports[idx].fullName = newPassportsName[id];
        }
      });
      Object.keys(newPassportsNumber).forEach((id) => {
        let [priceId, idx] = id.split('-');
        let passenger = newPassengers.find((passenger) => passenger.excursion_price === priceId);
        if (passenger) {
          passenger.passports = passenger.passports || [];
          passenger.passports[idx] = passenger.passports[idx] || {
            fullName: null,
            number: null
          };
          passenger.passports[idx].number = newPassportsNumber[id];
        }
      });
      Object.keys(newAges).forEach((id) => {
        let [priceId, idx] = id.split('-');
        let passenger = newPassengers.find((passenger) => passenger.excursion_price === priceId);
        if (passenger) {
          passenger.ages = passenger.ages || [];
          passenger.ages[idx] = newAges[id];
        }
      });

      onApply({
        passengers: newPassengers,
        wishes: newWishes
      });
    }
  }, [newPassengers, newWishes, newPassportsName, newPassportsNumber, newAges]);


  useEffect(() => {
    buildPriceRows();
    buildPassportRows();
  }, [newPassengers]);


  useEffect(() => {
    let newPassportsName = {};
    let newPassportsNumber = {};
    let newAges = {};
    passengers.forEach((passenger) => {
      passenger.passports.forEach((passport, p) => {
        newPassportsName[`${passenger.excursion_price}-${p}`] = passport ? passport.fullName : '';
        newPassportsNumber[`${passenger.excursion_price}-${p}`] = passport ? passport.number : '';
      });
      passenger.ages.forEach((age, a) => {
        newAges[`${passenger.excursion_price}-${a}`] = age;
      });
    });

    setNewPassportsName(newPassportsName);
    setNewPassportsNumber(newPassportsNumber);
    setNewAges(newAges);
    setNewPassengers([ ...passengers ]);
  }, []);

  return (
    <div className="trip-app">
      <div className="trip-app-body">
        {errors.map((error, i) => (
          <Alert key={`error_${i}`} type={error.type || ALERT_TYPE.ERROR} title={error.title}>{error.message}</Alert>
        ))}

        <Section className="price-list static">
          {priceRows}
        </Section>

        <Section className="toggle-list static" title={t('order:select_passengers.wishes_for_seating')}>
          <Checkbox.Group>
            {Object.values(Wishes).map((wish) => (
              <Checkbox.Button key={wish} title={t(`order:wishes.${wish}`)} checked={newWishes.indexOf(wish) >= 0} value={wish} onChange={handleWishes} />
            ))}
          </Checkbox.Group>
        </Section>

        {passportRequired && (
          <Section className="passport-list static" title={t('order:select_passengers.passports')}>
            {passportRows}
          </Section>
        )}
      </div>
      <div className="trip-app-footer">
        <Button.Panel>
          <Button position={BUTTON_POSITION.LEFT} disabled={errors.length > 0} onClick={handleReady}>{t('common:button.next')}</Button>
          <Button position={BUTTON_POSITION.RIGHT} variant={BUTTON_TYPE.SECONDARY} onClick={onCancel}>{t('common:button.cancel')}</Button>
        </Button.Panel>
      </div>
    </div>
  );
}

SelectPassengers.propTypes = {
  prices: PropTypes.array,
  limits: PropTypes.object,
  passportRequired: PropTypes.bool,
  passengers: PropTypes.array,
  wishes: PropTypes.array,
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
};

export default SelectPassengers;